import { Link } from "react-router-dom";

export const ContactDetailBlock = () => {
  return (
    <>
      <div className="contact-details">
        <div className="container">
          <div className="row">
            <div className="col">
              <i className="fa-solid fa-envelope fa-4x"></i>
              <h4>EMAIL</h4>
              <p>
                <a href="mailto:info@columbiacosmetics.com">info@columbiacosmetics.com</a>
              </p>
            </div>
            <div className="col">
              <i className="fa-solid fa-phone-volume fa-4x"></i>
              <h4>CALL</h4>
              <p>
                <a href="tel:(510) 562-5900">(510) 562-5900</a>
              </p>
              <p>Monday - Friday</p>
              <p>8 am - 5 pm PST</p>
            </div>
            <div className="col">
              <i className="fa-solid fa-comment fa-4x"></i>
              <h4>CHAT</h4>
              <p>Monday - Friday:</p>
              <p>8 am - 5 pm PST</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
