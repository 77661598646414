import React from "react";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
export const How = () => {

  AOS.init({ duration: 2000 });
  useEffect(() => {
  }, []);
  return (
    <>
      {/* <!-- BEGIN How --> */}

      <div className="how-works-sec">
        <div className="wrapper">
          <div className="how-head">
            <div className="row">
              <div className="col">
                <h4 className="saint-text">Start Your Beauty Journey</h4>
                <h2>Product Development Process</h2>
                <p>
                  Columbia Cosmetics specializes in Research and Development and
                  Custom Formulation. <br /> We can provide guidance and
                  expertise in order to get the formulation that fits your
                  business needs.
                </p>
              </div>
            </div>
          </div>

          <div className="how-cont" data-aos="flip-up">
            <div className="row">
              <div className="col">
                {/* <h6>Lorem Ipsum</h6> */}
                <h3>1. Consult with a Formulation Development Expert</h3>
                <p>
                  Fully understand the intended characteristics and
                  specifications of the product. This allows you and our
                  formulation scientists to define the project’s potential
                  challenges, time-frame to completion, and costs associated.
                </p>
              </div>
              <div className="col">
                <img src="/assets/img/consult.jpg" alt="" />
              </div>
            </div>
          </div>

          <div className="how-cont" data-aos="flip-down">
            <div className="row">
              <div className="col">
                <img src="/assets/img/formulation.jpg" alt="" />
              </div>
              <div className="col">
                {/* <h6>Lorem Ipsum</h6> */}
                <h3>2. Formulation Development</h3>
                <p>
                  Our team of skilled formulation chemists will go to work
                  developing a unique and innovative formulation based on your
                  exact technical specifications.
                </p>
              </div>
            </div>
          </div>

          <div className="how-cont" data-aos="flip-up">
            <div className="row">
              <div className="col">
                {/* <h6>Lorem Ipsum</h6> */}
                <h3>3. Review & Comment on Prototypes</h3>
                <p>
                  Sample batches will be sent for your review. Product’s
                  formulation will then be finalized based on your input.
                </p>
              </div>
              <div className="col">
                <img src="/assets/img/review.jpg" alt="" />
              </div>
            </div>
          </div>

          <div className="how-cont" data-aos="flip-down">
            <div className="row">
              <div className="col">
                <img src="/assets/img/support.jpg" alt="" />
              </div>
              <div className="col">
                {/* <h6>Lorem Ipsum</h6> */}
                <h3>4. Additional Support</h3>
                <p>
                  Shelf life analysis, packaging selection and compatibility,
                  regulatory assistance, marketing claims validation, etc.
                </p>
              </div>
            </div>
          </div>

          <div className="how-cont" data-aos="flip-up">
            <div className="row">
              <div className="col">
                {/* <h6>Lorem Ipsum</h6> */}
                <h3>
                  5. Technology Transfer & Scale-
                  <br />
                  up
                </h3>
                <p>
                  During the new formulation development process our laboratory
                  will produce trial batches for you to sample. When your new
                  product is finalized, we will transfer the complete
                  formulation to our manufacturing department.
                </p>
              </div>
              <div className="col">
                <img src="/assets/img/transfer.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- DISCOUNT How   --> */}
    </>
  );
};
