import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function SmoothScroll({ children }) {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const targetElement = document.querySelector(location.hash);
            if (targetElement) {
                targetElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }, [location]);

    return <>{children}</>;
}
