import React, { useEffect, useState } from "react";
import AuthUser from "../../components/utils/AuthUser";

const Catalogs = () => {
  const { http } = AuthUser();
  const [catalogs, setCatalogs] = useState([]);

  useEffect(() => {
    const fetchCatalogs = async () => {
      try {
        const response = await http.post("/catalogs");
        setCatalogs(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCatalogs();
  }, []);

  return (
    <div className="catalogs-sec">
      <div className="container">
        <div className="row">
          {Array.isArray(catalogs) &&
            catalogs.map((catalog, index) => (
              <div className="col" key={index}>
                <img
                  src={`https://admin.columbiacosmetics.com/storage/app/public/catalog-images/${catalog?.image}`}
                  alt=""
                />
                <h5>{catalog?.name}</h5>
                <div className="catalog-overlay">
                  <a
                    href={`/catalogs/${catalog?.slug}`}
                    className="btn"
                  >
                    View Catalog
                  </a>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Catalogs;
