import React from "react";

export const Video = () => {
  return (
    <>
      {/* <!-- BEGIN MAIN BLOCK --> */}

      <div className="custom-video-sec">
        <video loop controls className="asd">
          <source src="assets/img/video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* <!-- MAIN BLOCK EOF --> */}
    </>
  );
};
