import React from "react";

function Overview() {
  return (
    <div className="prods-overview">
      <div className="container">
        <p>
          Columbia Cosmetics develops industry leading products that shape and
          set trends within the beauty industry by searching for novel
          ingredients and leveraging technology to exceed your expectations
        </p>
        <p>
          Simply tell us your vision and we’ll work with you to make it happen.
        </p>
      </div>
    </div>
  );
}

export default Overview;
