export const Map = () => {
  return (
    <div className='contacts-map'>
      <iframe
        src='https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1661%20Timothy%20Dr,%20San%20Leandro,%20CA%2094577,%20USA+(Your%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
        width='100%'
        height='600'
        style={{ border: 0 }}
        loading='lazy'
      ></iframe>
    </div>
  );
};


