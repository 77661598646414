import React from 'react'
import { Layout } from '../layout/Layout';
import RegisterElem from '../components/Register/Register';
import { Seo } from '../components/shared/Seo';

export default function register() {   
  return (

    <Layout>
      <Seo />
      <RegisterElem />
    </Layout>
  )
}