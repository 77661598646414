import React from "react";
import { Link } from "react-router-dom";

export const Development = () => {
  return (
    <>
      {/* <!-- BEGIN MAIN BLOCK --> */}

      <div className="commitment">
        <div className="container">
          <div className="row">
            <div className="col1">
              <span className="saint-text">
                Our Commitment to the Environment
              </span>
              <h2>Clean Beauty & Sustainable Packaging</h2>
              <p className="cont">
                Beyond the shimmer and the sparkles in our products is a
                commitment that we make towards building a foundation that
                defines the way we do our business. As we strive to find
                innovative solutions and work with our partners towards
                minimizing waste to protect our natural resources, we offer
                sustainable packaging alternatives and clean beauty formulations
                to meet your sustainability goals. Together we can make a
                difference.
              </p>
              <p className="cont2">
                Learn more about our sustainability efforts
              </p>

              <p>
                <Link to="/about#corporate-section" className="btn">
                  Corporate Social Responsibility
                </Link>
              </p>

              <p>
                <Link to="/about#responsible-section" className="btn">
                  Responsible Manufacturing
                </Link>
              </p>

              <p>
                <Link to="/about#sustain-section" className="btn">
                  Sustainable Sourcing
                </Link>
              </p>
            </div>
            <div className="col2">{/* <p>dfdf</p> */}</div>
          </div>
        </div>
      </div>

      {/* <!-- MAIN BLOCK EOF --> */}
    </>
  );
};
