import React from 'react';

export const Bread = ({ breadcrumb, title, seo_title, description }) => {
  return (
    <>
      {/* <!-- BEGIN DETAIL MAIN BLOCK --> */}
      <div className='detail-block detail-block_margin ab-bg' style={bg}>
        <div className='wrapper'>
          <div className='detail-block__content'>
            <h1>About Us</h1>

            {breadcrumb && (
              <ul className='bread-crumbs'>
                {breadcrumb?.map(({ path, label }, i) => {
                  return (
                    <React.Fragment key={i}>
                      {path === window.location.pathname ? (
                        <li className='bread-crumbs__item'>{label}</li>
                      ) : (
                        <li className='bread-crumbs__item'>
                          <a href={path} className='bread-crumbs__link'>{label}</a>
                        </li>
                      )}
                    </React.Fragment>
                  );
                })}
              </ul>
            )}

            {/* IF NEED DESCRIPTION */}
            {description && <span className='error-descr'>{description}</span>}
          </div>
        </div>
      </div>
      {/* <!-- DETAIL MAIN BLOCK EOF   --> */}
    </>
  );
};

const bg = {
  backgroundImage: "none",
  backgroundColor: "#ffffff",
  backgroundPositionY: "-490px"
};
