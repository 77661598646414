import { useState } from "react";
import PromoVideo from "../../../components/shared/PromoVideo/PromoVideo"

const Corporate = () => {
  return (
    <>
      <div className="corporate-sec" id="corporate-section">
        <div className="container">
          <div className="row">
            <div className="col1">
              <div className="corporate-cont">
                <h2>CORPORATE SOCIAL RESPONSIBILTY</h2>
                <p>Columbia Cosmetics is committed to improving our policies to
                  enrich our employees’ lives and create a happier and healthier
                  world. Just some of these measures include:</p>
                <div className="points">
                  <p>Reduce carbon footprints to mitigate climate change</p>
                  <p>Improve labor policies and embrace fair trade</p>
                  <p>Engage in charitable giving and volunteer efforts within your community</p>
                  <p>Change corporate policies to benefit the environment</p>
                  <p>Make socially and environmentally conscious investments</p>
                </div>
              </div>
            </div>
            <div className="col2">
              <p></p>
            </div>
          </div>
        </div>
      </div>

      <div className="responsible-sec" id="responsible-section">
        <div className="container">
          <div className="row">
            <div className="col1">
              <p></p>
            </div>
            <div className="col2">
              <div className="responsible-cont">
                <h2>RESPONSIBLE MANUFACTURING</h2>
                <p>Doing what’s right to protect resources and the planet for
                  future generations. We continually implement new ways to lower
                  energy usage, cut waste, conserve resources, and decrease
                  emissions to keep the air and environment clean.</p>
                <div className="points">
                  <p>
                    We have Installed 146kW solar energy system providing all our energy needs
                  </p>
                  <p>
                    We have installed LED lighting throughout the office and manufacturing areas.
                  </p>
                  <p>
                    We have implemented recycling programs for office papers, magazines and newsprint,
                    drink containers, plastic film and wrap, and we’re adding additional waste streams
                    programs when possible.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sustain-sec" id="sustain-section">
        <div className="container">
          <div className="row">
            <div className="col1">
              <div className="sustain-cont">
                <h2>SUSTAINABLE SOURCING</h2>
                <p>To create the highest quality beauty products and experiences
                  for our customers, we source the best ingredients and packaging
                  materials. Our values guide us to operate with the highest level
                  of integrity and ethics, while caring for people and the
                  environment.</p>
                <p>Many of our customers use resources from all
                  around the world. We strive to source our ingredients
                  responsibly and sustainably with respect for local communities
                  and the environment. We expect our suppliers to comply with
                  local regulations and national laws governing minimum wages,
                  work hours, overtime compensation, hiring, occupational safety,
                  forced and/or child labor and biodiversity.</p>
                <p>Our
                  purchasing team meets regularly to review our sourcing
                  standards, analyze and benchmark best practices, engage key
                  suppliers and develop and implement practices to advance
                  sustainable sourcing within the Company</p>
              </div>
            </div>
            <div className="col2">
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Corporate;
