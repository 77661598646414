import { Registered } from '../components/Thankyou/registered';
import { Layout } from '../layout/Layout';
import React from 'react';
import ReactDOM from 'react-dom';

const breadcrumbsData = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Shop',
    path: '/shop',
  },
];

export default function Privatelabel() {
  return (
    <Layout breadcrumb={breadcrumbsData} breadcrumbTitle='Shop'>
      <Registered />
    </Layout>
  );
}



