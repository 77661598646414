import React, { Component } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

class CategorySlider extends Component {
  render() {
    return (
      <>
        <div className="custom-slide">
          <div className="container-shop">
            <div className="row-head">
              <div className="column">
                <span className="saint-text">START BUILDING YOUR BRAND</span>
                <h2>SHOP BY CATEGORY</h2>
              </div>
            </div>
            <Carousel
              responsive={responsive}
              showDots={true}
              draggable={true}
              autoPlay={true}
              autoPlaySpeed={1200}
              itemClass="carousel-item-padding-40-px"
            >
              <div>
                <Link to="/skincare">
                  <div className="category-1">
                    <h5>SKINCARE</h5>
                  </div>
                </Link>
              </div>
              <div>
                <Link to="/make-up">
                  <div className="category-2">
                    <h5>MAKEUP</h5>
                  </div>
                </Link>
              </div>
              <div>
                <Link to="/essentials">
                  <div className="category-3">
                    <h5>ESSENTIALS</h5>
                  </div>
                </Link>
              </div>
              <div>
                <Link to="/accessories">
                  <div className="category-4">
                    <h5>ACCESSORIES</h5>
                  </div>
                </Link>
              </div>
              <div>
                <Link to="/skincare">
                  <div className="category-1">
                    <h5>SKINCARE</h5>
                  </div>
                </Link>
              </div>
            </Carousel>
          </div>
          <div className="butt-shop">
            <Link className="btn" to="/shop">
              SHOP ALL PRODUCTS
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default CategorySlider;
