import React from 'react';

export const Liquid = () => {
  return (
    <>
      <div className="liquid" id="liqu">
        <div className="container">
          <div className="liq-cont">
            <span className="saint-text">Compounding Capabilities</span>
            <h2>LIQUID</h2>
            <p>
              We can produce test batches at 2 kg and full-scale batches up to
              2000 kg, which allows us to produce a run of any volume to meet
              your market needs.
            </p>

            <p id="liq-head">
              We have the flexibility to work with clients to produce:
            </p>
            <ul className="points">
              <li>Lab samples</li>
              <li>Market or PR samples</li>
              <li>Pilot batches to test scalability</li>
              <li>Dull scale production</li>
            </ul>

            <p id="liq-head">COMPOUNDING EQUIPMENTS:</p>
            <ul className="points">
              <li>Steam Jacketed Kettles</li>
              <li>Vacuum Kettles</li>
              <li>Prop Mixers</li>
              <li>Side Sweeps</li>
              <li>Counter-rotating Mixers</li>
              <li>Drop in Homogenizers</li>
              <li>In-line Homogenizers</li>
            </ul>
          </div>
          <img id="liq-img" src="/assets/img/liq.png" alt="" />
        </div>
      </div>
    </>
  );
};
