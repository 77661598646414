import { Layout } from '../layout/Layout';
import { How } from '../components/Productdevelopment/How/How';
import { Info } from '../components/Productdevelopment/Info/info';
import { Started } from '../components/Productdevelopment/Started/Started';
import { Support } from '../components/Productdevelopment/Support/Support';
import { Bread } from '../components/Productdevelopment/Bread/Bread';
import {Faq} from '../components/Productdevelopment/Faq/Faq';
import {Contact} from '../components/Productdevelopment/Contact/Contact';
import React from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from "react-scroll-to-top";
import { Seo } from '../components/shared/Seo';

export default function Productdevelopment() {
  return (
    <Layout>
      <Seo/>
      <Bread/>
      <Info />
      <Support />
      <How />
      <Started />
      <Faq />
      <Contact />
      <ScrollToTop smooth />
      {/* <Subscribe /> */}
    </Layout>
  );
}
