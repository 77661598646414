import { Link, useParams } from "react-router-dom";
import AuthUser from "../../components/utils/AuthUser";
import { useState, useEffect } from 'react';
import { $ } from "jquery";
import { useNavigate } from "react-router-dom";

export default function Reset() {
  const [token, setRememberToken] = useState('');
  const { http, setToken } = AuthUser();
  const [confirmPass, setConfirmPass] = useState('');
  const [newPass, setNewPass] = useState('');

  const {resetToken} = useParams();

  const navigate = useNavigate();
  

  function resetPassword(event){
    event.preventDefault();

    if (newPass !== confirmPass) {
        $('.error').html('password does not match');
        return;
    }
    const data = {
        newPass,
        resetToken,
    };

    http.post('/resetuser', {
        password: newPass,
        token:resetToken
    }).then(response => {
        const data = response.data;
        if(data?.message){
            navigate('/login');
        }else{
            $('.error').html(data?.error);
        }
    }, function (error) {
        $('.error').html(error);
      
    });
   
  }
    return (
     <div className='login registration reset'>
            <div className="wrapper">
                <div className="login-form js-img" style={{ background: '#fcf2e8', }}>
                    {/* <i className="fa-solid fa-xmark"></i> */}

                    <div className="col-sm-6">
                        <h3 className="text-center mb-3">Reset Password</h3>
                        <p className="register-text">Reset your password to login again.</p>

                        <form action="#">
                            <div className='box-field__row'>
                                <span>New Password</span>
                                <div className='box-field box-field2'>
                                    <input
                                        type='password'
                                        className='form-control'
                                        placeholder='New Password*'
                                        required
                                        onChange={e => setNewPass(e.target.value)}
                                        id="company_name"

                                    />
                                </div>
                            </div>
                            <div className='box-field__row'>
                                <span>Confirm New Password</span>
                                <div className='box-field box-field2'>
                                    <input
                                        type='password'
                                        className='form-control'
                                        placeholder='Confirm New Password*'
                                        required
                                        onChange={e => setConfirmPass(e.target.value)}
                                        id='ein'

                                    />
                                </div>
                            </div>

                            <div className="error" style={{'color':'red'}}></div>
                            <div className="submit-btn-div">
                                <button onClick={resetPassword}  className="btn btn-primary mt-4 register-btn" id='submit'>Reset Password</button>
                            </div>
                        </form>


                    </div>
                </div>
            </div>

        </div>

    )
}
