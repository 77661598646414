import React, { useEffect } from 'react'
import axios from 'axios';
import { useState } from "react";
import $ from "jquery";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AuthUser from "../../components/utils/AuthUser";
export default function RegisterElem() {
    const { http, setToken } = AuthUser();
    const navigate = useNavigate();
    const [username, setName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [companyName, setcompanyName] = useState();
    const [ein, setEin] = useState();
    const [website, setWebsite] = useState();
    const [number, setNumber] = useState();
    const [addressOne, setAddressOne] = useState();
    const [addressTwo, setAddressTwo] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [postalCode, setPostalCode] = useState();
    const [country, setCountry] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [position, setPosition] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [preference, setPreference] = useState();
    const [hear, setHear] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const [isChecked, setIsChecked] = useState('checked');
    const [loading, setLoading] = useState(false);
    const loader = (
        <div
            style={{
                'position': "fixed",
                'display': "flex",
                'height': "-webkit-fill-available",
                'alignItems': "center",
                'justifyContent': "center",
                'width': "-webkit-fill-available",
                'z-index': '999',
                'top': '0',
                'background-color': '#0000009c'
            }}
            className="loading ovelay"
        >
            <img style={{ width: "100px" }} src="/assets/img/loading.gif" alt="Loading" />
        </div>
    );

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    useEffect(() => {
        $('#companyNameError').html('');
        $('#einError').html('');
        $('#numberError').html('');
        $('#addressOneError').html('');
        $('#firstNameError').html('');
        $('#lastNameError').html('');
        $('#usernameError').html('');
        $('#stateError').html('');
        $('#passwordError').html('');
        $('#confirmPasswordError').html('');
        $('#emailError').html('');
    }, [companyName, ein, number, state, addressOne, firstName, lastName, username, password, confirmPassword, email]);

    const validatePhoneNumber = (phoneNumber) => {
        const regex = /^\+?1?[-.\s]?(\(\d{3}\)|\d{3})[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
        return regex.test(phoneNumber);
    };

    function optionFunction(event) {

        event.preventDefault();

        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!companyName) {
            $('#companyNameError').html('Please enter your company name');
            return false;
        }

        if (!ein) {
            $('#einError').html('Please enter your ein number');
            return false;
        }
        
        if (!number) {
            $('#numberError').html('Please enter your number');
            return false;
        }
        if (!validatePhoneNumber(number)) {
            $('#numberError').html('Please enter a valid phone number');
            return false;
        }
        if (!addressOne) {
            $('#addressOneError').html('Please enter your address');
        }

        if (!state) {
            $('#stateError').html('Please enter a state');
            return false;
        }
        
        if (state.length > 14) {
            $('#stateError').html(`State cannot exceed 14 characters`);
            return false;
        }
        
        if (state.length < 2) { // Assuming a minimum length of 2 characters
            $('#stateError').html('State must have at least 2 characters');
            return false;
        }
        if (!firstName) {
            $('#firstNameError').html('Please enter your first name');
            return false;
        }
        
        if (firstName.length > 50) { // Assuming a maximum length of 50 characters
            $('#firstNameError').html('First name cannot exceed 50 characters');
            return false;
        }
        
        if (firstName.length < 2) { // Assuming a minimum length of 2 characters
            $('#firstNameError').html('First name must have at least 2 characters');
            return false;
        }
        
        if (!lastName) {
            $('#lastNameError').html('Please enter your last name');
            return false;
        }
        
        if (lastName.length > 50) { // Assuming a maximum length of 50 characters
            $('#lastNameError').html('Last name cannot exceed 50 characters');
            return false;
        }
        
        if (lastName.length < 2) { // Assuming a minimum length of 2 characters
            $('#lastNameError').html('Last name must have at least 2 characters');
            return false;
        }
        
        if (!username) {
            $('#usernameError').html('Please enter your username');
            return false;
        }

        if (!password) {
            $('#passwordError').html('Please enter your password');
            return false;
        }

        if (password.length < 8) {
            $('#passwordError').html('Password must be at least 8 characters long');
            return false;
        }

        if (!/[A-Z]/.test(password) || !/[a-z]/.test(password) || !/[0-9]/.test(password)) {
            $('#passwordError').html('Password must contain at least one uppercase letter, one lowercase letter, and one digit');
            return false;
        }

        if (!confirmPassword) {
            $('#confirmPasswordError').html('Please re-enter your password to confrim');
            return false;
        }

        if (password !== confirmPassword) {
            $('#confirmPasswordError').html('Password and Confirm Password do not match');
            return false;
        }
        if (pattern.test(email) == false || !email) {
            $('#emailError').html('Please enter a valid email');
            return false;
        }

        if (!isChecked) {
            setErrorMessage('You must select the updates checkbox');
            return false;
        }
       

        setLoading(true);
        http.post('/register', {
            headers: {
                "X-Requested-with": "XMLHttpRequest",
            },
            withCredentials: true,
            username: username,
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            position: position,
            company_name: companyName,
            ein: ein,
            number: number,
            website: website,
            address_one: addressOne,
            address_two: addressTwo,
            city: city,
            state: state,
            postal_code: postalCode,
            country: country,
            preference: preference,
            hear: hear,
            website: website
        })
            .then(response => {
                const data = response.data;
                setLoading(false);
                if (data.error) {
                    setErrorMessage(data.error);
                } else {
                   navigate('/registered');
                }
            })
            .catch(error => {
                setLoading(false);
                if (error.response) {
                    const status = error.response.status;
                    let errorMessage = 'An error occurred';
                    if (status === 400) {
                        errorMessage = 'Invalid request. Please check your inputs.';
                    } else if (status === 401) {
                        errorMessage = 'Invaild Username or Password, please try again';
                    } else if (status === 404) {
                        errorMessage = 'The requested resource was not found.';
                    } else if (status === 500) {
                        errorMessage = 'Internal server error. Please try again later.';
                    }
                    setErrorMessage(errorMessage);
                } else if (error.request) {
                    setErrorMessage('No response received from the server. Please try again later.');
                } else {
                    setErrorMessage(`Error occurred while making the request: ${error.message}`);
                }
            });




    }






    return (

        <div>
            {loading ?
                <div style={{ "position": "absolute", "display": "flex", "height": "-webkit-fill-available", "align-items": "center", "justify-content": "center", "width": "-webkit-fill-available" }} className="loading ovelay">
                    <img style={{ "width": "100px", }} src="/assets/img/loading.gif" />
                </div> :
                <div className='login registration'>
                    <div className="wrapper">
                        <div className="login-form js-img" style={{ background: '#fcf2e8', }}>
                            {/* <i className="fa-solid fa-xmark"></i> */}

                            <div className="col-sm-6">
                                <h3 className="text-center mb-3">Register</h3>
                                <p className="register-text">Start or Expand Your Brand. Your journey begins here.</p>

                                <div className='box-field__row'>
                                    <span>Business Information</span>
                                    <div className='box-field'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Company Name*'
                                            required
                                            value={companyName}
                                            onChange={e => setcompanyName(e.target.value)}
                                            id="company_name"

                                        />
                                        <p id="companyNameError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>
                                    <div className='box-field'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Tax ID/ EIN (USA)/ ABN (AU)/ VAT (EUR)*'
                                            required
                                            value={ein}
                                            onChange={e => setEin(e.target.value)}
                                            id='ein'

                                        /><p id="einError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>
                                </div>

                                <div className='box-field__row'>
                                    <div className='box-field'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Company Website/ URL'
                                            value={website}
                                            onChange={e => setWebsite(e.target.value)}
                                            id="website"
                                        />
                                        <p id="websiteError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>
                                    <div className='box-field'>
                                        <input
                                            type='  '
                                            className='form-control'
                                            placeholder='Phone / Mobile*'
                                            onChange={e => setNumber(e.target.value)}
                                            value={number}
                                            id='number'
                                            required
                                        />
                                        <p id="numberError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>

                                </div>

                                <div className='box-field__row'>
                                    <div className='box-field box-field2'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Address Line 1*'
                                            value={addressOne}
                                            onChange={e => setAddressOne(e.target.value)}
                                            id="addres_one"
                                            required
                                        />
                                        <p id="addressOneError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>
                                </div>



                                <div className='box-field__row'>
                                    <div className='box-field box-field2'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Address Line 2'
                                            value={addressTwo}
                                            onChange={e => setAddressTwo(e.target.value)}
                                            id="addres_two"
                                        />
                                        <p id="addressTwoError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>
                                </div>

                                <div className='box-field__row'>
                                    <div className='box-field'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='City*'
                                            value={city}
                                            onChange={e => setCity(e.target.value)}
                                            id='city'
                                        />
                                        <p id="cityError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>
                                    <div className='box-field'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='State / Province / Region*'
                                            value={state}
                                            onChange={e => setState(e.target.value)}
                                        />
                                        <p id="stateError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>
                                </div>

                                <div className='box-field__row'>
                                    <div className='box-field'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Zip Code*'
                                            value={postalCode}
                                            onChange={e => setPostalCode(e.target.value)}
                                        />
                                        <p id="postalCodeError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>
                                    <div className='box-field'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Country*'
                                            value={country}
                                            onChange={e => setCountry(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className='box-field__row'>
                                    <span>Main Contact Information</span>
                                    <div className='box-field'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='First Name*'
                                            onChange={e => setFirstName(e.target.value)}
                                            value={firstName}
                                            required
                                        />
                                        <p id="firstNameError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>
                                    <div className='box-field'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Last Name*'
                                            onChange={e => setLastName(e.target.value)}
                                            value={lastName}
                                            required
                                        />
                                        <p id="lastNameError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>
                                </div>

                                <div className='box-field__row'>
                                    <div className='box-field'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Title / Position*'
                                            onChange={e => setPosition(e.target.value)}
                                            value={position}
                                            required
                                        />
                                        <p id="positionError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>

                                    <div className='box-field'>
                                        <select name="prefrence" id="prefrence" className='form-control' onChange={e => setPreference(e.target.value)}>
                                            <option value="Contact Preference" selected disabled>Contact Prefrence</option>
                                            <option value="phone">Phone</option>
                                            <option value="email">Email</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="box-field__row">
                                    <span>Account Information</span>
                                    <div className='box-field'>
                                        <input type="text" className="form-control" placeholder="Username*"
                                            onChange={e => setName(e.target.value)}
                                            value={username}
                                            id="usename" required />
                                        <p id="usernameError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>
                                    <div className="box-field">
                                        <input type="email" className="form-control" placeholder="Email*"
                                            onChange={e => setEmail(e.target.value)}
                                            id="email" value={email} required />
                                        <p id="emailError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>
                                </div>

                                <div className='box-field__row'>
                                    <div className='box-field'>
                                        <input type="password" className="form-control" placeholder="Password*"
                                            onChange={e => setPassword(e.target.value)}
                                            id="pwd" required />
                                        <p id="passwordError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>
                                    <div className='box-field'>
                                        <input
                                            type='password'
                                            className='form-control'
                                            placeholder='Confirm password*'
                                           
                                            onChange={e => setConfirmPassword(e.target.value)}
                                            required
                                        />
                                        <p id="confirmPasswordError" className="register-error" style={{ color: 'red' }}></p>
                                    </div>
                                </div>

                                <div className='box-field__row'>
                                  

                                    <div className='box-field box-field2'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='How did you hear about us?*'
                                            value={hear}
                                            onChange={e => setHear(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="box-field__row" id='register-detail-row'>
                                    <div className="register-checkboxes login-form__bottom">
                                        <div className="check">
                                            <input
                                                className="styled-checkbox"
                                                id="updates"
                                                type="checkbox"
                                                value="updates"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label for="updates">Email me updates from Columbia Cosmetics. Be the first to hear about product <br />
                                                launches, exclusive sales, and more. You can unsubscribe at any time.</label>
                                            <p className="register-error" style={{ color: 'red' }}></p>
                                        </div>
                                    </div>
                                    <p className='login-form__bottom'>By creating an account, you agree to Columbia Cosmetics’ <Link href='terms' as='/terms.html'>Terms and Conditions</Link> and <Link href='privacy' as='/privacy.html'>Privacy Policy</Link>.</p>
                                </div>

                                <small style={{ 'color': 'red' }}>{errorMessage}</small>
                                <div className="submit-btn-div">
                                    <button type="submit" onClick={optionFunction} className="btn btn-primary mt-4 register-btn" id='submit'>Create an Account</button>
                                </div>



                                {/* <div className='login-form__bottom'>
                          <span>
                              Already have an account?{' '}
                              <Link href="/login" as='/login.html'>
                                  <a>Log in</a>
                              </Link>

                          </span>
                      </div> */}
                            </div>
                        </div>
                    </div>

                </div>}

        </div>
    )
}
