import { useState } from 'react';

export const Terms = () => {
  return (
    <>
      {/* <!-- BEGIN DISCOUNT --> */}
      
      <div className="terms">
        <div className="container">
            <h3>Terms and Conditions</h3>
            <p>Welcome to Columbia Cosmetics Manufacturing Inc (CCMI). By using any CCMI e-commerce website, you agree to be bound by these terms and conditions (“Terms”). You also accept the Terms when you create an account, make a purchase as a guest, or log in as return CCMI customer. We may make changes to any CCMI products and the Terms. It is your responsibility to review the Terms for updates or changes. If you do not agree with the Terms, you should not use the CCMI website for product purchase and service.</p>
            <p>These terms include a binding arbitration agreement, class action waiver, and jury trial waiver that affect your rights. in arbitration, there is no judge or jury and less discovery and appellate review than in court.</p>

            <h4>User Generated Content: Reviews, Comments, Communications, and Other Content</h4>
            <p>You may interact with the CCMI website in numerous ways, including Reviews and Ratings, videos, Questions and Answers, Community Forums, testimonials, and email communication. You hereby grant CCMI a perpetual, irrevocable, royalty-free, transferable right and license to use, modify, reproduce, transmit, publish, display, delete, and distribute any information (except order information sent via email or phone) or materials you share with us throughout the world in any media, including when you allow CCMI to feature text, images and videos shared through social media (e.g., Facebook™, Twitter™, Instagram™, Pinterest™) or submitted through our Reviews and Ratings. You also grant us the right to use the name and social media handle that you use when you share content with us in connection with that content. When you share content to us, you will disclose any affiliation you have and you will not share anything that contains harmful computer code, references other websites, or is false, misleading, illegal, defamatory, libelous, hateful, racist, biased, threatening, or harassing.</p>
            
            <h4>Order Confirmation</h4>
            <p>Our order confirmation to you does not signify our acceptance of your order, nor does it constitute confirmation of our offer to sell. At any time after receipt of your order, we may accept, decline, or place quantity or other limits on your order for any reason. We may impose these limits on a per-person, per-household, per-order, or any other basis. If we reject, limit, or otherwise modify your order, we will attempt to notify you using the email address you provide to us. If we cancel an order or part of an order that we’ve already charged you for, we’ll refund you the full amount of the canceled portion of the order.</p>

            <h4>Disclaimers and Limitation of Liability</h4>
            <p>Columbia Cosmetics provides the products and all information, content, and other material made available through our production / inventory on an “as is” and “as available” basis. We make no representation or warranty that product will maintain specification if product is not used as intended / formulated or stored / transported in temperature, condition or humidity that is out of its recommendation or compromises product integrity.</p>
            <p>Product sold through CCMI website are identified as cosmetics, personal care and OTC disposable product which does not carry warranty, extended warranty, or trial warranty once item has been used by the consumer. To the fullest extent permitted by applicable law, Columbia Cosmetics will not be liable for any indirect, incidental, or consequential product damages attributable during shipping damage or storage by delivery/ freight services used to deliver product. Customer concern may be reported and correspondence will be issued after review for follow up action ( as applies ).</p>
            <p>The terms set forth in this section will limit liability for personal injury or property damage caused by products you purchase through CCMI website. All matters of liability will be process through internal company customer concern case review and arbitration (as applicable 3rd party mediator) and not through the court of law or customer pursuant of a lawsuit.</p>

            <h4>Termination of Use</h4>
            <p>We may, in our sole discretion, terminate your account or your use of the CCMI website at any time. We will provide follow up correspondence to justify termination. You are personally liable for any orders that you place or charges that you incur prior to termination. We may change, suspend or discontinue all or any aspects of any CCMI products and services at any time without prior notice. Information will be provided as applicable.</p>
        </div>
      </div>

      {/* <!-- DISCOUNT EOF   --> */}
    </>
  );
};

