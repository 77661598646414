import React from "react";
import { Link } from "react-router-dom";

const Private = () => {
  return (
    <>
      <div className="shop-private">
        <div className="container">
          <div className="row">
            <div className="col" id="shop-private-1">
              <span className="saint-text">LEARN MORE ABOUT</span>
              <h2>PRIVATE LABEL</h2>

              <div className="private-button">
                <p className="button-1">
                  <Link to={'/services/privatelabel#private-hiw'} className="btn">
                    HOW IT WORKS
                  </Link>
                </p>

                <p className="button-2">
                  <Link to={'/services/privatelabel#guideorder'} className="btn">
                    GUIDE TO ORDER
                  </Link>
                </p>

                <p className="button-3">
                  <Link to={'/services/privatelabel#custom-branding-id'} className="btn">
                    CUSTOM BRANDING
                  </Link>
                </p>
              </div>
            </div>
            <div className="col">
              <img src="assets/img/private-bg.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Private;
