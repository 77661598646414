import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const Makeup = () => {
  return (
    <>
      <div className="all-category" id="all-category-1">
        <div className="container-shop">
          <div className="row-head">
            <div className="column">
              <span className="saint-text">SHOP</span>
              <h2>MAKEUP</h2>
            </div>
          </div>
          <Carousel
            responsive={responsive}
            showDots={true}
            draggable={true}
            autoPlay={true}
            autoPlaySpeed={2800}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            itemClass="carousel-item-padding-40-px"
            infinite={true}
          >
            <div>
              <Link to={"/blush"}>
                  <div className="makeup-slide">
                    <img src="assets/img/makeup-1.png" alt="" />
                    <h5>BLUSH</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/eyeshadows"}>
                  <div className="makeup-slide">
                    <img src="assets/img/eyeshadow-1.png" alt="" />

                    <h5>EYESHADOWS</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/foundations"}>
                  <div className="makeup-slide">
                    <img src="assets/img/foundations-1.png" alt="" />

                    <h5>FOUNDATIONS</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/lipsticks"}>
                  <div className="makeup-slide">
                    <img src="assets/img/lipsticks-cat-1.png" alt="" />

                    <h5>LIPSTICKS</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/bronzers"}>
                  <div className="makeup-slide">
                    <img src="assets/img/bronzers-1.png" alt="" />

                    <h5>Bronzers</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/concealers-correctors"}>
                  <div className="makeup-slide">
                    <img src="assets/img/conclealers-cat-main.png" alt="" />

                    <h5>Concealers & Correctors</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/eyeliners"}>
                  <div className="makeup-slide">
                    <img src="assets/img/eyeliner-cat-main.png" alt="" />

                    <h5>Eyeliners</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/face-powders"}>
                  <div className="makeup-slide">
                    <img src="assets/img/facepowder-cat-main.png" alt="" />

                    <h5>Face Powders</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/highlighters"}>
                  <div className="makeup-slide">
                    <img src="assets/img/highlighter-cat-main.png" alt="" />

                    <h5>Highlighter</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/lip-gloss"}>
                  <div className="makeup-slide">
                    <img src="assets/img/lipgloss-cat-main.png" alt="" />

                    <h5>Lip Gloss</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/lip-pencils"}>
                  <div className="makeup-slide">
                    <img src="assets/img/lippencil-cat-main.png" alt="" />

                    <h5>Lip Pencils</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/mascaras"}>
                  <div className="makeup-slide">
                    <img src="assets/img/mascaras-cat-main.png" alt="" />

                    <h5>Mascaras</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/perfectors-correctors"}>
                  <div className="makeup-slide">
                    <img src="assets/img/perfectors-cat-main.png" alt="" />

                    <h5>Perfectors & Correctors</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/primers-setting-spray"}>
                  <div className="makeup-slide">
                    <img src="assets/img/primes-cat-main.png" alt="" />

                    <h5>Primers/ Setting Spray</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/eyebrows"}>
                  <div className="makeup-slide">
                    <img src="assets/img/eyebrow-cat-main.png" alt="" />

                    <h5>Eyebrows</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/kits"}>
                  <div className="makeup-slide">
                    <img src="assets/img/kits-cat-main.png" alt="" />

                    <h5>Kits</h5>
                  </div>
              </Link>
            </div>
          </Carousel>
        </div>
      </div>

      <div className="all-category" id="all-category-2">
        <div className="container-shop">
          <div className="row-head">
            <div className="column">
              <span className="saint-text">SHOP</span>
              <h2>SKINCARE</h2>
            </div>
          </div>
          <Carousel
            responsive={responsive}
            showDots={true}
            draggable={true}
            autoPlay={true}
            autoPlaySpeed={2800}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            itemClass="carousel-item-padding-40-px"
            infinite={true}
          >
            <div>
              <Link to={"/cleansers"}>
                  <div className="makeup-slide">
                    <img src="assets/img/cleansers-1-cat.png" alt="" />

                    <h5>CLEANSERS</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/toners"}>
                  <div className="makeup-slide">
                    <img src="assets/img/toners-cat.png" alt="" />
                    <h5>TONERS</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/lipcare"}>
                  <div className="makeup-slide">
                    <img src="assets/img/lipcare-cat.png" alt="" />
                    <h5>LIP CARE</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/professional-sizes"}>
                  <div className="makeup-slide">
                    <img src="assets/img/sizes-cat.png" alt="" />
                    <h5>PROFESSIONAL SIZES</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/face-mist"}>
                  <div className="makeup-slide">
                    <img src="assets/img/facemist-cat-main.png" alt="" />
                    <h5>FACE MIST</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/make-up-removers"}>
                  <div className="makeup-slide">
                    <img src="assets/img/makeupremovers-cat-main.png" alt="" />
                    <h5>MAKEUP REMOVERS</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/masques"}>
                  <div className="makeup-slide">
                    <img src="assets/img/masques-cat-main.png" alt="" />
                    <h5>MASQUES</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/moisturizers"}>
                  <div className="makeup-slide">
                    <img src="assets/img/moisturizers-cat-main.png" alt="" />
                    <h5>MOISTURIZERS</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/night-creams"}>
                  <div className="makeup-slide">
                    <img src="assets/img/nightcreme-cat-main.png" alt="" />
                    <h5>NIGHT CREAMS</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/scrub"}>
                  <div className="makeup-slide">
                    <img src="assets/img/scrub-cat-main.png" alt="" />
                    <h5>SCRUB</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/serums-treatments"}>
                  <div className="makeup-slide">
                    <img src="assets/img/serums-cat-main.png" alt="" />
                    <h5>SERUMS & TREATMENTS</h5>
                  </div>
              </Link>
            </div>

            <div>
              <Link to={"/undereye-care"}>
                  <div className="makeup-slide">
                    <img src="assets/img/undereye-cat-main.png" alt="" />
                    <h5>UNDEREYE CARE</h5>
                  </div>
              </Link>
            </div>

          </Carousel>
        </div>
        <div className="butt-shop">
          <Link to="/shop" className="btn">
              SHOP OTHER CATEGORIES
          </Link>
        </div>
      </div>
    </>
  );
};