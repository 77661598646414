import React from "react";
import AuthUser from "../../../../components/utils/AuthUser";
import $ from "jquery";
import { useDynamicNav } from "../../../../Contexts/DynamicNav";

const SideNav = () => {
  const { dynamicnav } = useDynamicNav();
  const { http } = AuthUser();

  const dynamicnavOpen = (index) => {
    const targetElement = $(`.hidden-menu${index}`);
    targetElement.slideToggle("slow");
    targetElement.toggleClass("open");
    targetElement.toggleClass("hidenav");
  };

  return (
    <div className="shop-aside__item">
      <span className="shop-aside__item-title">CATEGORIES</span>
      <ul className="category-list">
        {dynamicnav.map((category, index) => (
          <li key={index} onClick={() => dynamicnavOpen(index)}>
            <span className="link-cat">
              {category?.main_category.category_name}
            </span>
            {category?.child_categories && (
              <ul
                className={`hidden-menu${index} hidenav`}
                data-index={index}
              >
                {category.child_categories.map((subcategory) => (
                  <li key={subcategory.id}>
                    <a href={`${subcategory.category_slug}`}>
                      {subcategory.sub_category_name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideNav;
