import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router for routing
import AuthUser from "../../../utils/AuthUser";
import { useDynamicNav } from "../../../../Contexts/DynamicNav";
const Nav = ({ navItem }) => {
  const { dynamicnav } = useDynamicNav();
  const { http } = AuthUser();

  return (
    <ul className="header-nav">
      <li>
        <Link to={"/"}>Home</Link>
      </li>
      <li id="service-nav">
        <Link to="/services">Our Services</Link>
        <div className="sub-menu" id="service-sub-menu">
          <ul>
            <li>
              <Link to="/services/productdevelopment">Product Development</Link>
            </li>
            <li>
              <Link to="/services/whitelabel">Contract Manufacturing</Link>
            </li>
            <li>
              <Link to="/services/privatelabel">Private Label</Link>
            </li>
            <li>
              <Link to="https://www.mocraregistrar.com/">MoCRA Compliance</Link>
            </li>
          </ul>
        </div>
      </li>

      <div className="mob-sub-1">
        <ul>
          <li>
            <Link to="/services/privatelabel">Private Label</Link>
          </li>
          <li>
            <Link to="/services/whitelabel">Contract Manufacturing</Link>
          </li>
          <li>
            <Link to="/services/productdevelopment">Product Development</Link>
          </li>
        </ul>
      </div>

      <li>
        <Link to="/ourproducts">Our Products</Link>
      </li>
      <li>
        <Link to="/ourfacility">Our Facility</Link>
      </li>

      <li className="dropdown">
        <Link to="/shopmain">Shop</Link>

        <div className="dropdown-content shop-menu">
          <ul>
            <div className="col">
              {dynamicnav.map((category, index) =>
                index === 0 ? (
                  <div
                    className="custom-column"
                    key={category?.main_category.id}
                  >
                    <Link to={`/${category?.main_category?.link}`}>
                      <h6
                        className="saint-text"
                        style={{ textTransform: "uppercase" }}
                      >
                        {category?.main_category.category_name}
                      </h6>
                    </Link>
                    {category?.child_categories &&
                      category.child_categories.map((subcategory) => (
                        <div
                          className="subsub"
                          style={{ marginBottom: "0px" }}
                          key={subcategory.id}
                        >
                          <Link to={`/${subcategory.category_slug}`}>
                            {subcategory.sub_category_name}
                          </Link>
                        </div>
                      ))}

                    {category?.main_category?.link ? (
                      <Link
                        className="last-item"
                        to={`/${category?.main_category?.link}`}
                      >
                        SHOP ALL {category?.main_category.category_name}
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                ) : null
              )}
            </div>
            <div className="col">
              {dynamicnav.map((category, index) =>
                index === 1 || index === 2 ? (
                  <div
                    className="custom-column"
                    key={category?.main_category.id}
                  >
                    <Link to={`/${category?.main_category?.link}`}>
                      <h6
                        className="saint-text"
                        style={{ textTransform: "uppercase" }}
                      >
                        {category?.main_category.category_name}
                      </h6>
                    </Link>
                    {category?.child_categories &&
                      category.child_categories.map((subcategory) => (
                        <div
                          className="subsub"
                          style={{ marginBottom: "0px" }}
                          key={subcategory.id}
                        >
                          <Link to={`/${subcategory.category_slug}`}>
                            {subcategory.sub_category_name}
                          </Link>
                        </div>
                      ))}

                    {category?.main_category?.link ? (
                      <Link className="last-item" to={`/${category?.main_category?.link}`}>
                          SHOP ALL {category?.main_category.category_name}
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                ) : null
              )}
            </div>
            <div className="col">
              {dynamicnav.map((category, index) =>
                index !== 0 && index !== 1 && index !== 2 ? (
                  <div
                    className="custom-column"
                    key={category?.main_category.id}
                  >
                    <Link to={`/${category?.main_category.link}`}>
                      <h6
                        className="saint-text"
                        style={{ textTransform: "uppercase" }}
                      >
                        {category?.main_category.category_name}
                      </h6>
                    </Link>
                    {category?.child_categories &&
                      category.child_categories.map((subcategory) => (
                        <div
                          className="subsub"
                          style={{ marginBottom: "0px" }}
                          key={subcategory.id}
                        >
                          <Link to={`/${subcategory.category_slug}`}>
                            {subcategory.sub_category_name}
                          </Link>
                        </div>
                      ))}
                  </div>
                ) : null
              )}
            </div>

            {/* One column reserved for a specific section */}
            <div className="col promotion-col">
              <Link to="/shopmain" className="btn">
                SHOP NOW
              </Link>
            </div>
          </ul>
        </div>
      </li>

      <li>
        <Link to="/catalogs">Catalogs</Link>
      </li>

      <li>
        <Link to="/about">About Us</Link>
      </li>
      <li>
        <Link to="/contact">Contact</Link>
      </li>
    </ul>
  );
};

export default Nav;
