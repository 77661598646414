import React, { useState } from "react";
import faqData from "../../../data/faq/productdevfaq";

export const Faq = () => {
  const faqs = [...faqData];
  const [active, setActive] = useState(-1);

  const handleCollapse = (item) => {
    if (item === active) {
      setActive(-1);
    } else {
      setActive(item);
    }
  };

  return (
    <>
      {/* <!-- BEGIN faq --> */}

      <div className="custom-faq private-faq">
        <div className="container">
          <div className="row">
            <div className="faq-head col">
              <span className="saint-text">Product Development</span>
              <h2>FAQs</h2>
            </div>
          </div>
          <div className="wrapper">
            <div className="faq-items" data-aos="fade-up">
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className={`faq-item ${active === index && "active"}`}
                >
                  <div
                    onClick={() => handleCollapse(index)}
                    className="faq-item__head"
                  >
                    <span className="faq-item__head-num">{index + 1}</span>
                    {faq.title}
                    <span className="faq-item__head-btn"></span>
                  </div>
                  <div
                    style={{ display: active === index ? "block" : "none" }}
                    className="faq-item__content"
                  >
                    <p>{faq.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- faq EOF   --> */}
    </>
  );
};
