import axios from 'axios';
import { useEffect, useState } from 'react';
import AuthUser from '../components/utils/AuthUser';
import { Layout } from '../layout/Layout';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import $ from 'jquery';

export default function Dashboard() {
    const [activeTab, setActiveTab] = useState("myInfo");
    const { http } = AuthUser();
    const [userdetail, setUserdetail] = useState('');
    const [orders, setOrder] = useState('');
    const { token, logout } = AuthUser();

    //form 
    const [username, setUserName] = useState();
    const [email, setEmail] = useState();
    const [companyName, setcompanyName] = useState();
    const [number, setNumber] = useState();
    const [addressOne, setAddressOne] = useState();
    const [addressTwo, setAddressTwo] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [postalCode, setPostalCode] = useState();
    const [country, setCountry] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [website, setWebsite] = useState();
    const [ein, setEin] = useState();
    const [position, setPosition] = useState();
    const [trackingResults, setTrackingResults] = useState([]);
    const [password, setPassword] = useState();
    const [newpassword, setNewPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [loading, setLoading] = useState(false);
    const loader = (
        <div
          style={{
            'position': "fixed",
            'display': "flex",
            'height': "-webkit-fill-available",
            'alignItems': "center",
            'justifyContent': "center",
            'width': "-webkit-fill-available",
            'z-index': '999',
            'top' : '0',
            'background-color': '#0000009c'
          }}
          className="loading ovelay"
        >
          <img style={{ width: "100px" }} src="/assets/img/loading.gif" alt="Loading" />
        </div>
      );
    const logoutUser = () => {
        if (token != undefined) {
            logout();
        }
    }
    useEffect(() => {
        fetchUserDetail();
    }, []);

   

    const validatePhoneNumber = (phoneNumber) => {
        const regex = /^\+?1?[-.\s]?(\(\d{3}\)|\d{3})[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
        return regex.test(phoneNumber);
    };

    useEffect(() => {
        $('#companyNameError').html('');
        $('#einError').html('');
        $('#numberError').html('');
        $('#addressOneError').html('');
        $('#firstNameError').html('');
        $('#lastNameError').html('');
        $('#usernameError').html('');
        $('#stateError').html('');
        $('#passwordError').html('');
        $('#confirmPasswordError').html('');
        $('#emailError').html('');
    }, [companyName, ein, number, state, addressOne, firstName, lastName, username, password, confirmPassword, email]);


    const fetchUserDetail = () => {
        http.post('/me').then((res) => {
            setLoading(true);
            setUserdetail(res.data);
            http.post('/getorders', {
                headers: {
                    "X-Requested-with": "XMLHttpRequest",
                },
                withCredentials: true,
                client_id: res.data.id,
                password: password,
                newpassword: newpassword,
                confirmPassword: confirmPassword

            }).then((response) => {
                setLoading(false);
                const data = response.data;
                console.log(data);
                setOrder(data);

            });


        }, function (error) {
            setLoading(false);
            if (401 === error.response.status) {
                window.location.href = "/login.html";
            } else {
                return Promise.reject(error);
            }
        });
    }

    const handleTrackShipment = (order) => {
        http.post('/shipment-tracker', {
            headers: {
                'X-Requested-with': 'XMLHttpRequest',
            },
            withCredentials: true,
            trackerId: order.tracking_no,
        })
            .then((response) => {
                const { data } = response;
                // setTrackingResults((prevResults) => ({
                //     ...prevResults,
                //     [order.tracking_no]: data,
                // }));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        const validateCompnay = companyName ?? userdetail?.company_name;
        const validateNumber = number ?? userdetail?.number;
        const validatEin = ein ?? userdetail?.ein;
        const validatAddressOne = addressOne ?? userdetail?.address_one;
        const validatState = state ?? userdetail?.state;
        const validatFirstName = firstName ?? userdetail?.first_name;
        const validatLastName = lastName ?? userdetail?.last_name;
        const validatUserName = username ?? userdetail?.username;
        const validateEmail = email ?? userdetail?.email;

        
       

        try {

            if (!validatePhoneNumber(validateNumber)) {
                $('#numberError').html('Please enter a valid phone number');
                return false;
            }
           
            if (!validateCompnay) {
                $('#companyNameError').html(`Please enter your company name`);
                return false;
            }
    
            if (!validatEin) {
                $('#einError').html('Please enter your ein number');
                return false;
            }
           
            if (!validatAddressOne) {
                $('#addressOneError').html('Please enter your address');
                return false;
            }
    
            if (!validatState) {
                $('#stateError').html('Please enter a state');
                return false;
            }
            
            if (validatState.length > 14) {
                $('#stateError').html(`State cannot exceed 14 characters`);
                return false;
            }
            
            if (validatState.length < 2) { 
                $('#stateError').html('State must have at least 2 characters');
                return false;
            }
            if (!validatFirstName) {
                $('#firstNameError').html('Please enter your first name');
                return false;
            }
            
            if (validatFirstName.length > 50) { // Assuming a maximum length of 50 characters
                $('#firstNameError').html(`First name cannot exceed 50 characters`);
                return false;
            }
            
            if (validatFirstName.length < 2) { // Assuming a minimum length of 2 characters
                $('#firstNameError').html(`First name must have at least 2 characters`);
                return false;
            }
            
            if (!validatLastName) {
                $('#lastNameError').html('Please enter your last name');
                return false;
            }
            
            if (validatLastName.length > 50) { // Assuming a maximum length of 50 characters
                $('#lastNameError').html('Last name cannot exceed 50 characters');
                return false;
            }
            
            if (validatLastName.length < 2) { // Assuming a minimum length of 2 characters
                $('#lastNameError').html('Last name must have at least 2 characters');
                return false;
            }
            
            if (!validatUserName) {
                $('#usernameError').html('Please enter your username');
                return false;
            }
    
            setLoading(true);
            http.post('/update_user', {
                headers: {
                    "X-Requested-with": "XMLHttpRequest",
                },
                withCredentials: true,
                id: userdetail?.id,
                username: username ?? userdetail?.username,
                first_name: firstName ?? userdetail?.first_name,
                last_name: lastName ?? userdetail?.last_name,
                email: email ?? userdetail?.email,
                position: position ?? userdetail?.position,
                company_name: companyName ?? userdetail?.company_name,
                ein: ein ?? userdetail?.ein,
                number: number ?? userdetail?.number,
                website: website ?? userdetail?.website,
                address_one: addressOne ?? userdetail?.address_one,
                address_two: addressTwo ?? userdetail?.address_two,
                city: city ?? userdetail?.city,
                state: state ?? userdetail?.state,
                postal_code: postalCode ?? userdetail?.postal_code,
                country: country ?? userdetail?.country
            }).then(response => {
                setLoading(false);
                const data = response.data;
                
             
                $('.response').html(data.message);

            });
          

        } catch (error) {
            setLoading(false);
            console.log("Error", error)
        }

    }

    const changepassword = async (e) => {
        if (password == "") {
            $('.error').html('pass feild is empty');
        }
        e.preventDefault()

        try {
            setLoading(true);
            http.post('/change_password', {
                headers: {
                    "X-Requested-with": "XMLHttpRequest",
                },
                withCredentials: true,
                id: userdetail?.id,
                password: password,
                newpassword: newpassword,
                confirmPassword: confirmPassword

            }).then(response => {
                setLoading(false);
                const data = response.data;
                if (data.response) {
                    logoutUser();
                } else {
                    $('.error').html(data.error);

                }

            });


        } catch (error) {
            setLoading(false);
            console.log("Error", error)
        }

    }

    function renderElement() {
        
            return <>{loading ? loader : ''}
            
            <div className="profile">  
                <div className="wrapper">
                    <div className="profile-content">
                        <div className="profile-main">
                            <div className="tab-wrap">
                                <ul className="nav-tab-list tabs">
                                    <li onClick={() => setActiveTab("myInfo")} className={activeTab === "myInfo" ? "active" : ""} >My info</li>
                                    <li onClick={() => setActiveTab("orders")} className={activeTab === "orders" ? "active" : ""} >My orders</li>
                                    <li onClick={() => setActiveTab("forgot")} className={activeTab === "forgot" ? "active" : ""} >Change Password</li>
                                </ul>

                                <div className="box-tab-cont" >
                                        


                                    {activeTab === "myInfo" && (
                                           
                                            <div className="tab-cont" style={{'position':'relative'}} id="profile-tab_1">
                                             
                                                <div className="login registration custom-margin" id="update-information">
                                                    <div className="wrapper">
                                                        <div className="login-form js-img" style={{ backgroundImage: `url('/assets/img/registration-form__bg.png')`, }}>
                                                            <form onSubmit={handleSubmit}>
                                                                <h3>About User</h3>
                                                                <div className="box-field__row">
                                                                    <div className="box-field">
                                                                        <label>Company Name</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Company Name"
                                                                            onChange={e => setcompanyName(e.target.value)}
                                                                            value={companyName ?? userdetail?.company_name}
                                                                        />
                                                                         <p id="companyNameError" className="register-error" style={{ color: 'red' }}></p>
                                                                    </div>
                                                                    <div className="box-field">
                                                                    <label>EIN Number</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="EIN/ Tax ID # *"
                                                                            value={ein ?? userdetail?.ein}
                                                                            onChange={e => setEin(e.target.value)}
    
                                                                        />
                                                                        <p id="einError" className="register-error" style={{ color: 'red' }}></p>
                                                                    </div>
                                                                </div>
                                                                <div className="box-field__row">
                                                                    <div className="box-field">
                                                                    <label>Website URL</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Company Website / URL"
                                                                            value={website ?? userdetail?.website}
                                                                            onChange={e => setWebsite(e.target.value)}
                                                                        />
                                                                         <p id="websiteError" className="register-error" style={{ color: 'red' }}></p>
                                                                    </div>
                                                                    <div className="box-field">
                                                                    <label>Phone/Mobile Number</label>
                                                                        <input
                                                                            type="tel"
                                                                            className="form-control"
                                                                            placeholder="Phone / Mobile *"
                                                                            value={number ?? userdetail?.number}
                                                                            onChange={e => setNumber(e.target.value)}
                                                                        />
                                                                            <p id="numberError" className="register-error" style={{ color: 'red' }}></p>
                                                                    </div>
                                                                </div>
                                                                <div className="box-field__row">
                                                                    <span>Other Info</span>
                                                                    <div className="box-field box-field2">
                                                                    <label>Address Line One</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Address Line 1*"
                                                                            value={addressOne ?? userdetail?.address_one}
                                                                            onChange={e => setAddressOne(e.target.value)}
                                                                        />
                                                                       <p id="addressOneError" className="register-error" style={{ color: 'red' }}></p>
                                                                    </div>
                                                                </div>
                                                                <div className="box-field__row">
                                                                    <div className="box-field box-field2">
                                                                    <label>Address Line Two</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Address Line 2"
                                                                            value={addressTwo ?? userdetail?.address_two}
                                                                            onChange={e => setAddressTwo(e.target.value)}
                                                                        />
                                                                           <p id="addressTwoError" className="register-error" style={{ color: 'red' }}></p>
                                                                    </div>
                                                                </div>
                                                                <div className="box-field__row">
                                                                    <div className="box-field">
                                                                    <label>City</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="City*"
                                                                            value={city ?? userdetail?.city}
                                                                            onChange={e => setCity(e.target.value)}
                                                                        />
                                                                        <p id="cityError" className="register-error" style={{ color: 'red' }}></p>
                                                                    </div>
                                                                    <div className="box-field">
                                                                    <label>State</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="State / Province / Region*"
                                                                            value={state ?? userdetail?.state}
                                                                            onChange={e => setState(e.target.value)}
                                                                        />
                                                                          <p id="stateError" className="register-error" style={{ color: 'red' }}></p>
                                                                    </div>
                                                                </div>
                                                                <div className="box-field__row">
                                                                    <div className="box-field">
                                                                    <label >Postal Code</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Postal Code*"
                                                                            value={postalCode ?? userdetail?.postal_code}
                                                                            onChange={e => setPostalCode(e.target.value)}
                                                                        />
                                                                          <p id="postalCodeError" className="register-error" style={{ color: 'red' }}></p>
                                                                    </div>
                                                                    <div className="box-field">
                                                                    <label>Country</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Country*"
                                                                            value={country ?? userdetail?.country}
                                                                            onChange={e => setCountry(e.target.value)}
                                                                        />
                                                                         <p id="countryError" className="register-error" style={{ color: 'red' }}></p>
                                                                    </div>
                                                                </div>
                                                                <div className="box-field__row">
                                                                    <span>Main Contact Name</span>
                                                                    <div className="box-field">
                                                                    <label>First Name</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="First*"
                                                                            value={firstName ?? userdetail?.first_name}
                                                                            onChange={e => setFirstName(e.target.value)}
                                                                        />
                                                                         <p id="firstNameError" className="register-error" style={{ color: 'red' }}></p>
                                                                    </div>
                                                                    <div className="box-field">
                                                                    <label>Last Name</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Last*"
                                                                            value={lastName ?? userdetail?.last_name}
                                                                            onChange={e => setLastName(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="box-field__row">
                                                                    <div className="box-field box-field2">
                                                                    <label>Title/ Position</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Title/ Position *"
                                                                            value={position ?? userdetail?.position}
                                                                            onChange={e => setPosition(e.target.value)}
                                                                        />
                                                                         <p id="lastNameError" className="register-error" style={{ color: 'red' }}></p>
                                                                    </div>
                                                                </div>
                                                                <div className="box-field__row">
                                                                    <div className="box-field">
                                                                    <label>User Name</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Username*"
                                                                            readOnly
                                                                            value={username ?? userdetail?.username}
                                                                            onChange={e => setUserName(e.target.value)}
                                                                        />
                                                                          <p id="usernameError" className="register-error" style={{ color: 'red' }}></p>
                                                                        
                                                                    </div>
                                                                    <div className="box-field">
                                                                    <label>Email Address</label>
                                                                        <input
                                                                            type="email"
                                                                            className="form-control"
                                                                            placeholder="Email*"
                                                                            readOnly
                                                                            onChange={e => setEmail(e.target.value)}
                                                                            value={email ?? userdetail?.email}
                                                                        />
                                                                           <p id="positionError" className="register-error" style={{ color: 'red' }}></p>
                                                                    </div>
                                                                </div>
    
                                                                <button className="btn" type="submit">
                                                                    Update Information
                                                                </button>
                                                                <div className="response"></div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <img
                                                        className="promo-video__decor js-img"
                                                        src="/assets/img/promo-video__decor.jpg"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        )}
                                   

                                    {
                                        activeTab === "orders" && (
                                            <div className="tab-cont" id="profile-tab_2">
                                                <div className="profile-orders">
                                                    <div className="profile-orders__row profile-orders__row-head">
                                                        <div className="profile-orders__col">Date</div>
                                                        <div className="profile-orders__col">
                                                            Delivery address
                                                        </div>
                                                        <div className="profile-orders__col">Amount</div>
                                                        {/* <div className="profile-orders__col">Tracking No</div>
                                                        <div className="profile-orders__col">Status</div> */}
                                                    </div>


                                                    {Array.isArray(orders) && orders.map((order, index) => (
                                                        <div className="profile-orders__item" key={order.id}>
                                                            <div className="profile-orders__row">
                                                                <div className="profile-orders__col">
                                                                    <span className="profile-orders__col-mob">date</span>
                                                                    <span className="profile-orders__item-date">
                                                                        {moment(order?.created_at).format('dddd, M, yyyy')}
                                                                    </span>
                                                                </div>
                                                                <div className="profile-orders__col">
                                                                    <span className="profile-orders__item-addr">
                                                                        {order?.address_one}
                                                                    </span>
                                                                </div>
                                                                <div className="profile-orders__col">
                                                                    <span className="profile-orders__col-mob">Amount</span>
                                                                    <span className="profile-orders__item-price">
                                                                        ${order?.price}
                                                                    </span>
                                                                </div>

                                                                {/* <div className="profile-orders__col">
                                                                    <span className="profile-orders__col-mob">
                                                                        Tracking Number
                                                                    </span>
                                                                    <span className="profile-orders__item-price">
                                                                        {order?.tracking_no}
                                                                    </span>
                                                                </div> */}

                                                                {/* <div className="profile-orders__col"> */}
                                                                    {/* <span className="profile-orders__col-mob">Status</span> */}
                                                                    {/* Display the status for the corresponding order */}
                                                                    {/* {trackingResults[order.tracking_no] ? (
                                                                        <span>{trackingResults[order.tracking_no]}</span>
                                                                    ) : (
                                                                        <button style={{
                                                                            'border': '0px',
                                                                            'padding': '11px',
                                                                            'background': '#878686',
                                                                            'color': 'white'
                                                                        }} onClick={() => handleTrackShipment(order)}>
                                                                            Track Shipment
                                                                        </button>
                                                                    )} */}
                                                                {/* </div> */}
                                                            </div>
                                                        </div>
                                                    ))}


                                                </div>
                                            </div>
                                        )}


                                    {activeTab === "forgot" && (
                                        <div className="tab-cont" id="profile-tab_3" style={{'position' : 'relative'}}>
                                        
                                            <form onSubmit={changepassword}>
                                                <h3>Change Your Password</h3>
                                                <div className="box-field">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter your old password"
                                                        onChange={e => setPassword(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="box-field">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Enter your new password"
                                                        onChange={e => setNewPassword(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="box-field">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Confirm Your Password"
                                                        onChange={e => setConfirmPassword(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <button type="submit" className="btn">
                                                    Change Password
                                                </button>
                                                <div className="response"></div>
                                                <div className="error"></div>
                                            </form>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img
                    className="promo-video__decor js-img"
                    src="/assets/img/promo-video__decor.jpg"
                    alt=""
                />
            </div>
            </>
       

    }

    return (
        <div>
            <Layout>

                <div className='detail-block detail-block_margin bread'>
                    <div className='wrapper'>
                        <div className='detail-block__content'>
                            <h1>Dashboard</h1>

                            <ul className='bread-crumbs'>
                                <li className='bread-crumbs__item'>Home</li>
                                <li className='bread-crumbs__item'>
                                    <Link href='/'>
                                        <a className='bread-crumbs__link'>Dashboard</a>
                                    </Link>
                                </li>
                            </ul>

                            {/* IF NEED DESCRIPTION */}
                            {/* {description && <span className='error-descr'>{description}</span>} */}
                        </div>
                    </div>
                </div>

                {renderElement()}
            </Layout>

        </div>
    )
}