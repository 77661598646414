import { useState } from 'react';
import { Link } from 'react-router-dom';

export const Privacy = () => {
  return (
    <>
      {/* <!-- BEGIN DISCOUNT --> */}
      
      <div className="terms">
        <div className="container">
            <h3>Privacy Policy</h3>

            <h4>Collection</h4>
            <p>We will collect information directly from you (per your website entry and feedback). This could be as part of your interactions with Columbia Cosmetics Manufacturing Inc. ( CCMI ) sales rep or when you provide information via our digital properties such as websites.</p>
            
            <h4>Use</h4>
            <p>We will use your information to meet your expectations and give you a great experience with CCMI. For example, that could be to fulfill a purchase, provide a service, make our digital properties run better, or maintain our program.</p>
            <p>We’ll want to tell you about technology and other products and services that we think you’ll find useful. That means we’ll use information to try figure out what will be useful (of interest or new trend) to you and others, or we’ll develop new or different products and services. And if we think we’ve found something you might like, we’ll use information to place or send marketing messages to you that highlight our ideas, and we’ll monitor your response to that message.</p>

            <h4>Sharing</h4>
            <p>We may share your personally identifiable information with suppliers or business partners to fulfill your requests or perform on our behalf a purchase contract that we have with you.</p>
            <p>Sometimes we share information with suppliers and business partners for internal CCMI uses. The easiest examples of what we mean by this relate to information technology — they may be processing personally identifiable information, but we make sure we have contracts in place requiring that they only use the information in support of CCMI transaction. </p>
            <p>We also may share personally identifiable information in an attempt to enforce our legal rights, in response to a request from law-enforcement authorities, and when we are required to do so by applicable law. Also, please note that if we think that sharing personally identifiable information is going to protect CCMI or others from harm, that’s what we are going to do. </p>

        </div>
      </div>

      {/* <!-- DISCOUNT EOF   --> */}
    </>
  );
};

