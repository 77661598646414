import React from "react";
import { Link } from "react-router-dom";

export const Turnkey = () => {
  return (
    <>
      {/* <!-- BEGIN INFO BLOCKS --> */}
      <div className="info-blocks info-blocks-video info-blocks-video2 info-blocks-video3 turnkey">
        <div className="info-blocks__item info-blocks__item-reverse js-img">
          <div className="wrapper">
            <div className="info-blocks__item-img">
              <img src="assets/img/turnkey.png" alt="" />
            </div>
            <div className="info-blocks__item-text">
              <span className="saint-text">
                Beauty Manufacturing Made Simple
              </span>
              <h2>Find Your Turnkey Solution</h2>
              <span className="info-blocks__item-descr">
                We work closely with you as partners to bring your products from
                concept to completion with our one-stop, full-service solution.
                With our years of experience, we streamline the process and help
                eliminate the gaps in manufacturing that can lead to longer lead
                times. We support you through the entire process with our full
                turnkey solutions:
              </span>
              <div className="video2-dets points">
                <p>Product & Concept Development</p>
                <p>Product Matching & Custom Formulation</p>
                <p>Packaging Development & Sourcing</p>
                <p>Custom Label & Branding Services</p>
                <p>Fill & Complete Assembly</p>
                <p>Full Service Manufacturing</p>
                <p>Safety & Quality Control</p>
                <p>Regulatory Assistance</p>
                <p>Product & Quality Testing</p>
                <p>Global Compliance & Evaluation</p>
              </div>
              <span className="info-blocks__item-descr">
                Great ideas deserve to be turned into quality products. Learn
                more about how we can support you.
              </span>

              <Link to="/services" className="btn">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- INFO BLOCKS EOF   --> */}
    </>
  );
};
