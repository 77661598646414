export const header = {
  logo: '/assets/img/logonew.png',
};

export const navItem = [
  {
    name: 'Home',
    path: '/',
  },
  {
    name: 'Our Services',
    path: '/services',
    as: '/services.html',
    subNav: [
      {
        name: 'Product Development',
        path: '/services/productdevelopment',
        as: '/productdevelopment.html',
      },
      {
        name: 'Contract Manufacturing',
        path: '/services/whitelabel',
        as: '/whitelabel.html',
      },
      {
        name: 'Private Label',
        path: '/services/privatelabel',
        as: '/privatelabel.html',
      }
    ]
  },
  {
    name: 'Our Products',
    path: '/ourproducts',
    as: '/ourproducts.html',
  },
  {
    name: 'Our Facility',
    path: '/ourfacility',
    as: '/ourfacility.html',
  },
  {
    name: 'Shop',
    path: '/shopmain',
    as: '/shopmain.html',
    subNav: [
      {
        name: 'Make Up',
        path: '/shop?category=15',
        as: '/shop?category=15'
      },
      {
        name: 'Skin Care',
        path: '/shop?category=3',
        as: '/shop?category=3'
      },
      
      {
        name: 'Men Care',
        path: '/shop?category=32',
        as: '/shop?category=32'
      },
      {
        name: 'CBD',
        path: '/shop?category=1',
        as: '/shop?category=1'
      },
      {
        name: 'Essentials',
        path: '/shop?category=6',
        as: '/shop?category=6'
      },
      {
        name: 'Accessories',
        path: '/shop?category=33',
        as: '/shop?category=33'
      },
      {
        name: 'Preview Package',
        path: '/shop?category=35',
        as: '/shop?category=35'
      }
    ],
  },
  {
    name: 'About Us',
    path: '/about',
    as: '/about.html'
  },
  {
    name: 'contact',
    path: '/contact',
    as: '/contact'
  }
];

