import React from 'react';

export const Work = () => {
  return (
    <>
      <div className="help private-help" id='work' style={style}>
        <div className="container">
            <div className="row">
                <h2>Let's Work Together</h2>
                <span className="saint-text">We are here for all your business needs and questions.</span>
                <p>Please feel free to contact us on your queries and we will get back within 24 hours.</p>
                <a href='/contact' className="btn btn-primary">Contact Us</a>
            </div>
        </div>
      </div>
    </>
  );
};

const style = {
    'backgroundColor': "#fcf7f5",
    "marginTop": "-3px"
};
