import { Products } from "../../components/Product/Products/Products";
import { PagingList } from "../../components/shared/PagingList/PagingList";
import { usePagination } from "../../components/utils/Pagination/Pagination";
import productData from "../../data/product/product";
import Slider, { createSliderWithTooltip, Range } from 'rc-slider';
import { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import { AsideItem } from "../shared/AsideItem/AsideItem";
import axios from "axios";
import React from "react";
import { map } from "jquery";
import { Link } from "react-router-dom";
import $ from "jquery";
import SideNav from "../../components/shared/Header/Nav/SideNav";
// import { SingleProduct } from 'components/Product/Products/SingleProduct/SingleProduct';

// React Range



const options = [
  { value: "minToHigh", label: "Sort by: Alphabetical A-Z" },
  { value: "highToMin", label: "Sort by: Price Low to High" },
  { value: "minToHigh", label: "Sort by: Price High to Low" },
  { value: "minToHigh", label: "Sort by: Relevance" },
  { value: "minToHigh", label: "Sort by: New" },
  { value: "minToHigh", label: "Sort by: Alphabetical A-Z" },
];
export const Main = () => {
  const [category, setCategory] = React.useState([]);
  useEffect(() => {
    axios
      .get("https://api.columbiacosmetics.com/api/categories")
      .then((response) => {
        const data = response.data;
        setCategory(data);
      });
  }, []);

  const allProducts = [];

  const [productOrder, setProductOrder] = useState(
    allProducts.sort((a, b) => (a.price < b.price ? 1 : -1))
  );

  const [products, setProducts] = useState([...productOrder]);
  const [filter, setFilter] = useState({ isNew: false, isSale: true });

  useEffect(() => {
    setProducts(productOrder);
  }, [productOrder]);

  useEffect(() => {
    if (filter.isNew && filter.isSale) {
      const newPro = productOrder.filter(
        (pd) => pd.isNew === true && pd.isSale === true
      );
      setProducts(newPro);
    } else if (filter.isNew && !filter.isSale) {
      const newPro = productOrder.filter((pd) => pd.isNew === true);
      setProducts(newPro);
    } else if (filter.isSale && !filter.isNew) {
      const newPro = productOrder.filter((pd) => pd.isSale === true);
      setProducts(newPro);
    } else {
      setProducts([...productOrder]);
    }
  }, [filter, productOrder]);
  const recentlyViewed = [...productData].slice(0, 3);
  const todaysTop = [...productData].slice(3, 6);
  const paginate = usePagination(products, 9);

  const handleSort = (value) => {
    if (value === "highToMin") {
      const newOrder = allProducts.sort((a, b) => (a.price < b.price ? 1 : -1));
      setProductOrder(newOrder);
    }
    if (value === "minToHigh") {
      const newOrder = allProducts.sort((a, b) => (a.price > b.price ? 1 : -1));
      setProductOrder(newOrder);
    }
  };
  function link() {
    $(".hidden-menu").slideToggle("slow");
  }
  function link2() {
    $(".hidden-menu2").slideToggle("slow");
  }
  function link3() {
    $(".hidden-menu3").slideToggle("slow");
  }
  function link5() {
    $(".hidden-menu5").slideToggle("slow");
  }
  function link6() {
    $(".hidden-menu6").slideToggle("slow");
  }

  return (
    <div>
      {/* <!-- BEGIN SHOP --> */}
      <div className="shop">
        <div className="wrapper">
          <div className="shop-content">
            {/* <!-- Shop Aside --> */}
            <div className="shop-aside">
              <div className="box-field box-field__search">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search"
                />
                <i className="icon-search"></i>
              </div>
              <SideNav />
            </div>
            {/* <!-- Shop Main --> */}
            <div className="shop-main">
              <div className="shop-main__filter">
                <div className="shop-main__select">
                  <Dropdown
                    options={options}
                    className="react-dropdown"
                    onChange={(option) => handleSort(option.value)}
                    value={options[0]}
                  />
                </div>
              </div>
              <div className="shop-main__items" id="main-categories">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <Link to={"/blush"}>
                        <a>
                          <img
                            src="assets/img/categories/blush-cat-1.png"
                            alt=""
                          />
                          <h6>BLUSH</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/bronzers"}>
                        <a>
                          <img
                            src="assets/img/categories/bronzers-cat-1.png"
                            alt=""
                          />
                          <h6>BRONZERS</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/concealers-correctors"}>
                        <a>
                          <img
                            src="assets/img/categories/concealer-cat-1.png"
                            alt=""
                          />
                          <h6>CONCEALERS & CORRECTORS</h6>
                        </a>
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <Link to={"/eyebrows"}>
                        <a>
                          <img
                            src="assets/img/categories/eyebrow-cat-1.png"
                            alt=""
                          />
                          <h6>EYEBROWS</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/eyeliners"}>
                        <a>
                          <img
                            src="assets/img/categories/eyeliner-cat-1.png"
                            alt=""
                          />
                          <h6>EYELINERS</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/eyeshadows"}>
                        <a>
                          <img
                            src="assets/img/categories/eyeshadow-cat-1.png"
                            alt=""
                          />
                          <h6>EYESHADOWS</h6>
                        </a>
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <Link to={"/face-powders"}>
                        <a>
                          <img
                            src="assets/img/categories/face-cat-1.png"
                            alt=""
                          />
                          <h6>FACE POWDERS</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/foundations"}>
                        <a>
                          <img
                            src="assets/img/categories/foundation-cat-1.png"
                            alt=""
                          />
                          <h6>FOUNDATIONS</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/highlighters"}>
                        <a>
                          <img
                            src="assets/img/categories/highlight-cat-1.png"
                            alt=""
                          />
                          <h6>HIGHLIGHTERS</h6>
                        </a>
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <Link to={"/kits"}>
                        <a>
                          <img
                            src="assets/img/categories/kits-cat-1.jpg"
                            alt=""
                          />
                          <h6>KITS</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/lip-gloss"}>
                        <a>
                          <img
                            src="assets/img/categories/lipgloss-cat-1.png"
                            alt=""
                          />
                          <h6>LIP GLOSS</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/lip-pencils"}>
                        <a>
                          <img
                            src="assets/img/categories/lippencil-cat-1.png"
                            alt=""
                          />
                          <h6>LIP PENCILS</h6>
                        </a>
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <Link to={"/lipsticks"}>
                        <a>
                          <img
                            src="assets/img/categories/lipstick-cat-1.png"
                            alt=""
                          />
                          <h6>LIPSTICKS</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/mascaras"}>
                        <a>
                          <img
                            src="assets/img/categories/mascaras-cat-1.png"
                            alt=""
                          />
                          <h6>MASCARAS</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/perfectors-correctors"}>
                        <a>
                          <img
                            src="assets/img/categories/perfectors-cat-1.png"
                            alt=""
                          />
                          <h6>PERFECTORS & CORRECTORS</h6>
                        </a>
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <Link to={"/primers-setting-spray"}>
                        <a>
                          <img
                            src="assets/img/categories/primers-cat-1.png"
                            alt=""
                          />
                          <h6>PRIMERS/ SETTING SPRAY</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      {/* <img src="assets/img/categories/bronzers-cat-1.png" alt="" />
                            <h6>MASCARAS</h6> */}
                    </div>
                    <div className="col">
                      {/* <img src="assets/img/categories/concealer-cat-1.png" alt="" />
                            <h6>PERFECTORS & CORRECTORS</h6> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- PAGINATE LIST --> */}
              {/* <PagingList paginate={paginate} /> */}
              {/* <div class="pagination-outer">
                <ul class="actives styled-pagination"></ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- SHOP EOF   --> */}
    </div>
  );
};
