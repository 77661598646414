import { PublicLayout } from '../layout/PublicLayout';
import {Privacy} from '../components/Privacy/Privacy'
import ScrollToTop from "react-scroll-to-top";
import { Seo } from '../components/shared/Seo';

const breadcrumbsData = [
    {
      label: 'Home',
      path: '/',
    },
    {
      label: 'Privacy Policy',
      path: '/terms',
    },
  ];

export default function Guideorder() {
  return (
    <PublicLayout breadcrumb={breadcrumbsData} breadcrumbTitle='Privacy Policy'>
      <Seo />
      <Privacy />
      <ScrollToTop smooth />
    </PublicLayout>
  );
}
