import React from "react";
import { Link } from "react-router-dom";
export const Started = () => {
  return (
    <>
      <div className="started">
        <div className="container">
          <h2>Let's Get Started</h2>
          <div className="row">
            <h6>Do you have a specific product in in mind, colors or ingredients?</h6>
            <p>Our team loves a good challenge so send us your ideas and we'd be happy to work with you.</p>
            <Link to="/contact" className="btn btn-two">
              CONTACT US
            </Link>
          </div>
          {/* <div className="row">
            <h5>Questions? Find answers here</h5>
            <Link href="/guidetoorder">
              <a className="btn btn-two">FAQ</a>
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
};
