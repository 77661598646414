import { Seo } from '../components/shared/Seo';
import { Subscribe } from '../components/shared/Subscribe/Subscribe';
import { Shop } from '../components/Shop/Shop';
// import { PublicLayout } from '../layout/PublicLayout';
import { ShopLayout } from '../layout/ShopLayout';

const breadcrumbsData = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Shop',
    path: '/shop',
  },
];
const ShopPage = () => {
  return (
    <ShopLayout>
      <Seo />
      <Shop />
      <Subscribe />
    </ShopLayout>
  );
};

export default ShopPage;

