import React from "react";
import { Link } from "react-router-dom";

export const Info = () => {
  return (
    <>
      {/* <!-- BEGIN INFO BLOCKS --> */}
      <div className="know-more">
        <div className="container">
          <div className="row">
            <div className="col1">
              <span className="saint-text">Know More About</span>
              <h2>Columbia Cosmetics</h2>
              <p>
                Founded in 1978, Columbia has grown into one of the largest
                private label cosmetic manufacturers in North America. We offer
                complete custom formulation services as well as a full line of
                stock color cosmetics and cutting-edge skincare products.
              </p>
              <p>
                Our clientele, located worldwide, domestic and international are
                recognized as prestigious cosmetic retailers. We also cater to
                upscale beauty salons, spas, boutiques and color/image
                consultants.
              </p>
              <Link to="/about" data-aos="fade-right" className="btn">
                Read More
              </Link>
            </div>
            <div className="col2">
              <img src="assets/img/newabout-img.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- INFO BLOCKS EOF   --> */}
    </>
  );
};
