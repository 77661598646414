import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React from "react"
import PaymentForm from "../components/payment/PaymentForm"

// const PUBLIC_KEY = "pk_live_51Mdcp7AXX6HHHuHgnQm3raLmIOe6L5H1R13vKSylXHS0N09mquYq1QabdU0VvcpwU6aTlmdpCbimmwvJsBwwnaeH00EcXc4Ekc"
const PUBLIC_KEY = "pk_test_51JoYIzFXk2L9tImPsrQOyi75OhvTpwRTOkv1QL9D8pFK4gESwfNkL2ZCTeNucgrPnPIsRsa9o4yd9Es4Gr6BjS8t00EaiRmiMs"

const stripeTestPromise = loadStripe(PUBLIC_KEY)

export default function StripeContainer() {
	return (
		<Elements stripe={stripeTestPromise}>
			<PaymentForm />
		</Elements>
	)
}