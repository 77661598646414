import { Ordered } from '../components/Thankyou/ordered';
import React from 'react';
import { Layout } from '../layout/Layout';
import ReactDOM from 'react-dom';

export default function Privatelabel() {
  return (
      <Layout>
        <Ordered />
      </Layout>
  );
}



