import React, { createContext, useContext, useEffect, useState } from 'react';
import AuthUser from "../components/utils/AuthUser";
const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userDetail, setUserDetail] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const { http } = AuthUser();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userRes = await http.post("/me");
                setUserDetail(userRes.data);

                const cartRes = await http.post("/getcart", {
                    headers: { "X-Requested-With": "XMLHttpRequest" },
                    withCredentials: true,
                    client_id: userRes.data.id,
                });
                setCartItems(cartRes.data);
            } catch (error) {
                if (error?.response?.status === 401) {
                    console.log("Not Logged in");
                } else {
                    console.log("Unhandled error:", error);
                }
            }
        };

        fetchData();
    }, []);

    return (
        <UserContext.Provider value={{ userDetail, cartItems }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
