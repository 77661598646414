import { Layout } from '../layout/Layout';
import { Bread } from '../components/Contact/Bread/Bread';
import { ContactDetailBlock } from '../components/Contact/ContactDetailBlock/ContactDetailBlock';
import { ContactFrom } from '../components/Contact/ContactForm/ContactForm';
import { Map } from '../components/Contact/Map/Map';
import { Help } from '../components/Contact/Help/Help';
import { Booking } from '../components/Contact/Booking/Booking';
import ScrollToTop from "react-scroll-to-top";
import { Helmet } from 'react-helmet';
import { Seo } from '../components/shared/Seo';

const breadcrumbsData = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];

const ContactPage = () => {
  return (
    <Layout>
      <Seo />
      <Bread breadcrumbsData={breadcrumbsData} />
      <ContactDetailBlock />
      <ContactFrom />
      <Booking />
      <Help />
      <Map />
      <ScrollToTop smooth />
    </Layout>
  );
};

export default ContactPage;
