import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import AuthUser from "../utils/AuthUser";
import { useNavigate } from "react-router-dom";
import $, { ajax, isEmptyObject, map } from "jquery";

export default function PaymentForm({ onNext, onPrev }) {
  const navigate = useNavigate();

  const [cardNumber, setCardNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardCVV, setCardCVV] = useState("");
  const [cardExpiryError, setCardExpiryError] = useState("");

  const [payment, setPayment] = useState("stripe");
  const [success, setSuccess] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [newsletter, setNewsletter] = useState(true);
  const stripe = useStripe();

  const { http } = AuthUser();
  const [userdetail, setUserdetail] = useState("");
  const [item, setitem] = useState("");
  const [sum, setSum] = useState("");
  const [shippingPrice, setShippingPrice] = useState(0);
  const [shippingerror, setError] = useState("");
  const [universalError, setUniversalError] = useState("");

  const [giftPromo, setGiftPromo] = useState([]);
  const [giftPromoMessage, setGiftPromoMessage] = useState([]);

  const [loading, setLoading] = useState(false);
  const loader = (
    <div
      style={{
        position: "absolute",
        display: "flex",
        height: "-webkit-fill-available",
        alignItems: "center",
        justifyContent: "center",
        width: "-webkit-fill-available",
        "z-index": "999",
      }}
      className="loading ovelay"
    >
      <img
        style={{ width: "100px" }}
        src="/assets/img/loading.gif"
        alt="Loading"
      />
    </div>
  );

  //form
  const [email, setEmail] = useState();
  const [companyName, setcompanyName] = useState();
  const [number, setNumber] = useState();
  const [addressOne, setAddressOne] = useState();
  const [addressTwo, setAddressTwo] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [postalCode, setPostalCode] = useState();
  const [country, setCountry] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [shipping, setShippingAdress] = useState();
  const [specialnotes, setSpecialNotes] = useState();
  const [bankValue, setBankValue] = useState("");
  const [showLink, setShowLink] = useState(false);
  const [showButton, setshowButton] = useState(false);
  const [showStripeButton, setStripeButton] = useState(true);
  const [shippingChecked, setShippingChecked] = useState("");

  //Shipping Form Feilds
  const [shppingAddress, setShippingAdressColumn] = useState("");
  const [shppingApartment, setshppingApartment] = useState("");
  const [shppingCity, setshppingCity] = useState("");
  const [shppingPostalCode, setshppingPostalCode] = useState("");
  const [shppingState, setshppingState] = useState("");
  const [shippingAccountInfo, setShippingAccountInfo] = useState("");
  const [shippingForm, setShippingForm] = useState(false);

  //promoCode
  const [promocode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = useState("");
  const [promoCodeSuccess, setPromoCodeSuccess] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [couponData, setCouponData] = useState([]);
  const [handlingCost, setHandlingCost] = useState([]);
  const [handlingCostPrice, setHandlingCostPrice] = useState([]);
  const [subtotal, setSubTotal] = useState([]);
  const [checkPayError, setCheckPayError] = useState("");
  const [bankPayError, setBankPayError] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [stripeError, setStripeError] = useState("");

  //oldoptions radios
  const [oldOption, setOldOption] = useState(0);
  const [oldShipping, setOldShipping] = useState(0);

  //Erros
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [individualItemPromos, setIndividualItemPromos] = useState([]);

  const [shippingText, setShippingText] = useState('');

  // Add this function to handle changes
  const handleShippingText = (e) => {
    setShippingText(e.target.value);
  };

  useEffect(() => {
    if (firstName == "") {
      setFirstNameError("First Name feild cannot be empty");
    } else if (firstName?.length > 25) {
      setFirstNameError("First Name feild cannot be more than 10 letters");
    } else {
      setFirstNameError("");
    }

    if (lastName == "") {
      setLastNameError("Last Name feild cannot be empty");
    } else if (lastName?.length > 25) {
      setLastNameError("Last Name feild cannot be more than 10 letters");
    } else {
      setLastNameError("");
    }

    if (postalCode == "") {
      setLastNameError("Postal code feild cannot be empty");
    } else if (postalCode?.length > 25) {
      setLastNameError("Postal code feild cannot be more than 10 letters");
    } else {
      setLastNameError("");
    }
  }, [firstName, lastName]);

  useEffect(() => {
    http.post("/me").then(
      (res) => {
        setUserdetail(res.data);

        http
          .post("/getcart", {
            headers: {
              "X-Requested-with": "XMLHttpRequest",
            },
            withCredentials: true,
            client_id: res.data.id,
          })
          .then((response) => {
            const data = response.data;

            if (data?.cart?.length > 0) {
              const totalSum = data?.sum;
              const handlingPrice = data?.handling_cost[0];
              const productSum =
                parseFloat(totalSum) - parseFloat(handlingPrice?.subtotal);
              setSubTotal(productSum);
              setHandlingCostPrice(handlingPrice?.subtotal);
              setOldOption(handlingPrice?.subtotal);

              setSum(totalSum);
              setitem(data);
            } else {
              navigate("/cart");
            }
          });
      },
      function (error) {
        if (401 === error?.response?.status) {
          navigate("/login");
        } else {
          return Promise.reject(error);
        }
      }
    );
  }, []);

  useEffect(() => {
    liveRate();
  }, [
    shippingChecked,
    addressOne,
    addressTwo,
    state,
    city,
    postalCode,
    shppingApartment,
    shppingPostalCode,
    shppingState,
  ]);

  const paypal = (event) => {
    const paypal = event.target;
    if (paypal.checked) {
      setPayment("paypal");
      paypal.checked = false;
    } else {
      setPayment("paypal");
      paypal.checked = true;
    }
  };

  const stripeFunction = (event) => {
    var stripe = event.target;

    if (stripe.checked) {
      setPayment("stripe");
      stripe.checked = false;
    } else {
      setPayment("stripe");
      stripe.checked = true;
    }
  };

  const bankPay = () => {
    const cart = item?.cart;
    const productIds = [];
    const productPackagings = [];
    const cartIds = [];

    cart.forEach((element) => {
      productIds.push(element?.product_id);
      cartIds.push(element?.id);
      productPackagings.push(element?.product_packaging);
    });

    if (!shippingChecked) {
      setUniversalError('You must select the shipping method to proceed further.');
      return false;
    } else {
      setUniversalError('');
    }

    if (!isChecked) {
      $(".error")("Please Accept our privacy policy");
    } else {
      setLoading(true);
      http
        .post("/bankpay", {
          headers: {
            "X-Requested-with": "XMLHttpRequest",
          },
          withCredentials: true,
          client_id: userdetail?.id,
          name: firstName ?? userdetail?.first_name + " " + userdetail?.last_name,
          email: email ?? userdetail?.email,
          price: sum ?? userdetail?.sum,
          productIds: productIds,
          cartIds: cartIds,
          handling_cost: handlingCostPrice,
          promocode: promocode,
          discountedPrice: discountedPrice,
          productPackagings: productPackagings,
          currency: "usd",
          company_name: companyName ?? userdetail?.company_name,
          number: number ?? userdetail?.number,
          address_one: addressOne ?? userdetail?.address_one,
          address_two: addressTwo ?? userdetail?.address_two,
          city: city ?? userdetail?.city,
          state: state ?? userdetail?.state,
          postal_code: postalCode ?? userdetail?.postal_code,
          country: country ?? userdetail?.country,
          shipping: shipping ?? userdetail?.shipping,
          specialnotes: specialnotes ?? userdetail?.specialnotes,
          shppingAddress: shppingAddress ?? "",
          shppingApartment: shppingApartment ?? "",
          shppingCity: shppingCity ?? "",
          shppingPostalCode: shppingPostalCode ?? "",
          shppingState: shppingState ?? "",
          shippingAccountInfo: shippingAccountInfo ?? "",
          shippingPrice: shippingPrice ?? "",
          option: shippingChecked,
          shippingText: shippingText,
          payment_status: 2,
          newsletter: newsletter,
          order_status: 0,
          giftPromo: giftPromo,
        })
        .then((response) => {
          const data = response.data;

          console.log(data);

          // setLoading(false);
          // if (data.error) {
          //   setBankPayError(data.error);
          // } else {
          //   setSuccess(data);
          //   setError("");
          // }
          // if (data?.message == "success") {
          //   navigate("/ordered");
          // }
        });
    }
  };

  const checkPay = () => {
    const cart = item?.cart;
    const productIds = [];
    const productPackagings = [];
    const cartIds = [];


    cart.forEach((element) => {
      productIds.push(element?.product_id);
      cartIds.push(element?.id);
      productPackagings.push(element?.product_packaging);
    });


    if (!shippingChecked) {
      setUniversalError('You must select the shipping method to proceed further.');
      return false;
    } else {
      setUniversalError('');
    }

    if (!isChecked) {
      $(".error")("Please Accept our privacy policy");
    } else {
      setLoading(true);
      http
        .post("/checkpay", {
          headers: {
            "X-Requested-with": "XMLHttpRequest",
          },
          withCredentials: true,
          client_id: userdetail?.id,
          name: firstName ?? userdetail?.first_name + " " + userdetail?.last_name,
          email: email ?? userdetail?.email,
          price: sum ?? userdetail?.sum,
          productIds: productIds,
          cartIds: cartIds,
          promocode: promocode,
          discountedPrice: discountedPrice,
          productPackagings: productPackagings,
          currency: "usd",
          handling_cost: handlingCostPrice,
          company_name: companyName ?? userdetail?.company_name,
          number: number ?? userdetail?.number,
          address_one: addressOne ?? userdetail?.address_one,
          address_two: addressTwo ?? userdetail?.address_two,
          city: city ?? userdetail?.city,
          state: state ?? userdetail?.state,
          postal_code: postalCode ?? userdetail?.postal_code,
          country: country ?? userdetail?.country,
          shipping: shipping ?? userdetail?.shipping,
          specialnotes: specialnotes ?? userdetail?.specialnotes,
          shppingAddress: shppingAddress ?? "",
          shppingApartment: shppingApartment ?? "",
          shppingCity: shppingCity ?? "",
          shppingPostalCode: shppingPostalCode ?? "",
          shppingState: shppingState ?? "",
          shippingAccountInfo: shippingAccountInfo ?? "",
          shippingPrice: shippingPrice ?? "",
          option: shippingChecked,
          shippingText: shippingText,
          payment_status: 3,
          newsletter: newsletter,
          order_status: 0,
          giftPromo: giftPromo,
        })
        .then((response) => {
          const data = response.data;
          setLoading(false);
          if (data.error) {
            setCheckPayError(data?.error);
          } else {
            setSuccess(data);
            setError("");
          }
          if (data?.message == "success") {
            navigate("/order");
          }
        });
    }
  };

  const cheque = (event) => {
    var cheque = event.target;

    if (cheque.checked) {
      setPayment("cash");
      cheque.checked = false;
    } else {
      setPayment("cash");
      cheque.checked = true;
    }
  };

  useEffect(() => {
    if (payment == "paypal") {
      setPayment("paypal");
      setShowLink(true);
      setStripeButton(false);
      setshowButton(false);
    }
    if (payment == "cash") {
      setPayment("cash");
      setShowLink(false);
      setStripeButton(false);
      setshowButton(true);
    }
    if (payment == "stripe") {
      setStripeButton(true);
      setShowLink(false);
      setshowButton(false);
      setPayment("stripe");
    }
  }, [payment]);

  useEffect(() => {
    setSum(item?.sum);
  }, [item]);

  const handleCardNumberChange = (e) => {
    const input = e.target.value;
    // Remove non-numeric characters and spaces
    const sanitizedInput = input.replace(/\D/g, "");

    // Limit the card number to a standard length (e.g., 16 digits)
    const formattedInput = sanitizedInput.slice(0, 16);

    // Format the input by adding spaces every four characters
    let formattedNumber = "";
    for (let i = 0; i < formattedInput.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedNumber += " ";
      }
      formattedNumber += formattedInput[i];
    }

    setCardNumber(formattedNumber);
  };

  const handleCardNameChange = (e) => {
    setCardName(e.target.value);
  };

  const handleCardExpiryChange = (e) => {
    const inputValue = e.target.value;
    const formattedInput = inputValue
      .replace(/\D/g, "")
      .match(/(\d{2})(\d{2})/);

    if (formattedInput) {
      const formattedExpiry = formattedInput.slice(1).join("/");
      const array = formattedInput.slice(1);
      if (array[0] < 13) {
        setCardExpiry(formattedExpiry);
        setCardExpiryError("");
      }
    } else {
      setCardExpiry(inputValue);
      setCardExpiryError("Month Format is incorrect, please type valid month");
    }
  };

  const handleCardCVVChange = (e) => {
    const input = e.target.value;
    // Use a regular expression to enforce 3 or 4 digits
    const formattedInput = input.replace(/\D/g, "").slice(0, 4);
    setCardCVV(formattedInput);
  };

  useEffect(() => {
    if (cardNumber !== "" || cardExpiry !== "" || cardCVV !== "") {
      setStripeError("");
    }
  }, [cardNumber, cardExpiry, cardCVV]);

  //realpayment
  const handleSubmit = async (e) => {
    e.preventDefault();

    const cart = item?.cart;
    const productIds = [];
    const cartIds = [];
    const productPackagings = [];

    cart.forEach((element) => {
      productIds.push(element?.product_id);
      cartIds.push(element?.id);
      productPackagings.push(element?.product_packaging);
    });

    // if (cardNumber == "") {
    //   setStripeError("Please Fill Out Card Number");
    //   return false;
    // } else if (cardExpiry == "") {
    //   setStripeError("Please Fill Out Card Expiry Number");
    //   return false;
    // } else if (cardCVV == "") {
    //   setStripeError("Please Fill Out Card CVV Number");
    //   return false;
    // }


    if (!shippingChecked) {
      setUniversalError('You must select the shipping method to proceed further.');
      return false;
    } else {
      setUniversalError('');
    }


    try {
      setLoading(true);

      http
        .post("/payment", {
          headers: {
            "X-Requested-with": "XMLHttpRequest",
          },
          withCredentials: true,
          cardNumber: cardNumber,
          cardExpiry: cardExpiry,
          cardCVV: cardCVV,
          client_id: userdetail?.id,
          productIds: productIds,
          promocode: promocode,
          handlingPrice: handlingCostPrice,
          discountedPrice: discountedPrice,
          cartIds: cartIds,
          productPackagings: productPackagings,
          name: firstName ?? userdetail?.first_name + " " + userdetail?.last_name,
          email: email ?? userdetail?.email,
          price: sum ?? userdetail?.sum,
          currency: "usd",
          company_name: companyName ?? userdetail?.company_name,
          number: number ?? userdetail?.number,
          address_one: addressOne ?? userdetail?.address_one,
          address_two: addressTwo ?? userdetail?.address_two,
          city: city ?? userdetail?.city,
          state: state ?? userdetail?.state,
          postal_code: postalCode ?? userdetail?.postal_code,
          country: country ?? userdetail?.country,
          shipping: shipping ?? userdetail?.shipping,
          specialnotes: specialnotes ?? userdetail?.specialnotes,
          shppingAddress: shppingAddress ?? "",
          shppingApartment: shppingApartment ?? "",
          shppingCity: shppingCity ?? "",
          shppingPostalCode: shppingPostalCode ?? "",
          shppingState: shppingState ?? "",
          shippingAccountInfo: shippingAccountInfo ?? "",
          payment_status: 1,
          order_status: 5,
          shippingPrice: shippingPrice ?? 0.0,
          option: shippingChecked,
          newsletter: newsletter,
          giftPromo: giftPromo,
          shippingText: shippingText
        })
        .then((response) => {
          setLoading(false);
          const data = response.data;



          if (data?.error?.status == "error") {
            setError(data.error.errorMessage);
            return false;
          } else if (data?.redirection) {
            navigate(data?.redirection?.redirect_url);
            return false;
          } else {
            navigate('/ordersuccess');
            return false;
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const liveRate = (e) => {
    if (!e || !e.target) return;
    setLoading(true);
    const shippingType = e?.target?.value;

    if (!item?.cart || item.cart.length === 0) {
      setLoading(false);
      return; // No cart, no need to proceed with the request
    }

    console.log('Event object:', e);
    console.log('Event target:', e?.target);
    console.log('Shipping type:', shippingType);

    if (shippingType) {
      setShippingChecked(shippingType);
    }

    if (state === "") {
      setError("Please fill in the state field.");
      setLoading(false);
      return false;
    }

    if (postalCode === "") {
      setError("Please fill in the postal code field.");
      setLoading(false);
      return false;
    }

    if (state === "") {
      setError("Please fill in the state field.");
      setLoading(false);
      return false;
    }

    const cart = item?.cart;

    const productIds = [];
    const productSku = [];
    const productPackagings = [];
    const productqty = [];
    const cartIds = [];

    cart?.forEach((element) => {
      productqty.push(element?.product_qty);
      productIds.push(element?.product_id);
      cartIds.push(element?.id);
      productSku.push(element?.product_sku_no);
      productPackagings.push(element?.product_packaging);
    });

    http
      .post("/live-rates", {
        headers: {
          "X-Requested-with": "XMLHttpRequest",
        },
        withCredentials: true,
        ids: productIds,
        cartIds: cartIds,
        productSku: productSku,
        user_id: userdetail?.id,
        address: addressOne ?? userdetail?.address_one,
        country: country ?? userdetail?.country,
        postalCode: postalCode ?? userdetail?.postal_code,
        shppingPostalCode: shppingPostalCode ?? 0,
        state: state ?? userdetail?.state,
        city: city ?? userdetail?.city,
        handlingPrice: handlingCostPrice,
        packagings: productPackagings,
        productqty: productqty,
        shppingState: shppingState ?? "",
        option: shippingType ?? shippingChecked,
      })
      .then(
        (response) => {
          const data = response.data;
          setLoading(false);
          if (data.error) {
            setError(data?.error);
            setShippingPrice(0);
            setSum(item?.sum);
            return false;
          } else {
            let sumWithoutHandling;
            let discountedSum = 0;

            sumWithoutHandling =
              parseFloat(item?.sum) - parseFloat(handlingCostPrice);
            discountedSum =
              parseFloat(sumWithoutHandling) - parseFloat(discountedPrice);
            discountedSum +=
              parseFloat(handlingCostPrice) + parseFloat(data?.shippingPrice);
            setError("");
            setSum(discountedSum);
            setShippingPrice(data?.shippingPrice);
          }
        },
        function (error) {
          setLoading(false);
          if (500 === error.response?.status) {
            setError("");
          } else {
            return Promise.reject(error);
          }
        }
      );
  };

  useEffect(() => {
    let sumtotal = 0;
    sumtotal = item?.sum;

    setSum(parseFloat(sumtotal) + parseFloat(shippingPrice));
  }, [promocode]);

  //promocodestarts
  const promoCodeFunction = async (e) => {
    const code = e.target.value;

    setPromoCode(code);
    if (code && code.length > 5) {
      http
        .post("/validate-promocode", {
          headers: {
            "X-Requested-with": "XMLHttpRequest",
          },
          withCredentials: true,
          promocode: code,
          client_id: userdetail?.id,
        })
        .then((response) => {
          const data = response.data;
          let dbSum = item?.sum;
          if (data?.error) {
            setPromoCodeError(data?.error);
          }
          if (data?.success) {
            const coupon = data?.success[0];
            setCouponDiscount(coupon);

            let sumWithoutHandling = 0;
            let promoDiscount = 0;
            let discountRate = coupon?.discount_rate;
            let discountedSum = 0;

            sumWithoutHandling =
              parseFloat(item?.sum) - parseFloat(handlingCostPrice);
            promoDiscount = (parseInt(discountRate) / 100) * sumWithoutHandling;
            setDiscountedPrice(promoDiscount);

            discountedSum =
              parseFloat(sumWithoutHandling) - parseFloat(promoDiscount);
            discountedSum +=
              parseFloat(handlingCostPrice) + parseFloat(shippingPrice);
            setSum(discountedSum);
          }
        });
    } else {
      setPromoCodeError("");
      setCouponDiscount(null);
      setDiscountedPrice(0);
      setSum(item?.sum);
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const shipForm = () => {
    setShippingForm(!shippingForm);
  };

  useEffect(() => {
    if (shippingForm == true) {
      $(".different-form").slideDown();
    }

    if (shippingForm == false) {
      $(".different-form").slideUp();
      setShippingAdress(null);
      setshppingPostalCode(null);
      setshppingState(null);
    }
  }, [shippingForm]);

  const newsletterCheckbox = () => {
    setNewsletter(!newsletter);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setShippingChecked(event.target.value);
    //prevoius data
    setOldOption(handlingCostPrice);
    setOldShipping(shippingPrice);

    if (event.target.value === "willcall") {
      setHandlingCostPrice(0);
      setShippingPrice(0);

      let sumWithoutHandling = 0;
      sumWithoutHandling =
        parseFloat(item?.sum) - parseFloat(handlingCostPrice);
      console.log(sumWithoutHandling);
      console.log(shippingPrice);
      const totalsum =
        parseFloat(sumWithoutHandling) - parseFloat(discountedPrice);
      setSum(totalsum);
    } else if (event.target.value == "Own Account") {
      const additional = parseFloat(oldOption) + parseFloat(oldShipping);
      setHandlingCostPrice(oldOption);
      setShippingPrice(oldShipping);

      const totalsum =
        parseFloat(subtotal) +
        parseFloat(additional) -
        parseFloat(discountedPrice);
      setSum(totalsum);
    }
  };

  useEffect(() => {
    if (subtotal && userdetail?.id) {
      http.post("/gift-promos", {
        headers: {
          "X-Requested-with": "XMLHttpRequest",
        },
        withCredentials: true,
        userid: userdetail?.id,
        subtotal: subtotal,
      })
        .then(
          (response) => {
            const data = response.data;
            setGiftPromo(data);
            setGiftPromoMessage(data?.message);
          },
          (error) => {
            setLoading(false);
            if (500 === error.response?.status) {
              setError("");
            } else {
              return Promise.reject(error);
            }
          }
        );


      http.post("/individual-promo", {
        headers: {
          "X-Requested-with": "XMLHttpRequest",
        },
        withCredentials: true,
        userid: userdetail?.id,
        cart: item?.cart,
        subtotal: subtotal,
      })
        .then(
          (response) => {
            const data = response.data;
            setIndividualItemPromos(data);
          },
          (error) => {
            setLoading(false);
            if (500 === error.response?.status) {
              setError("");
            } else {
              return Promise.reject(error);
            }
          }
        );
    }
  }, [subtotal]);

  useEffect(() => {
    if (selectedOption === "Will Call") {
      $(".own-account").css("display", "none");
    } else if (selectedOption === "Own Account") {
      $(".own-account").css("display", "block");
    }
  }, [
    selectedOption,
    shippingPrice,
    handlingCostPrice,
    oldShipping,
    oldOption,
  ]);

  console.log(giftPromo);

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="checkout">
            <div className="wrapper">
              <div className="checkout-content">
                <div className="col">
                  <div className="checkout-form">
                    {/* CHECKOUT FORM */}

                    <div className="checkout-form__item">
                      <h4>Billing Details</h4>
                      <div className="box-field__row">
                        <div className="box-field">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your first name"
                            name="first_name"
                            value={firstName ?? userdetail?.first_name}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                          <small
                            className="firstNameError"
                            style={{ color: "red" }}
                          >
                            {firstNameError}
                          </small>
                        </div>
                        <div className="box-field">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your last name"
                            name="last_name"
                            value={lastName ?? userdetail?.last_name}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                          <small
                            className="lastNameError"
                            style={{ color: "red" }}
                          >
                            {lastNameError}
                          </small>
                        </div>
                      </div>
                      <div className="box-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your company name"
                          name="company_name"
                          value={companyName ?? userdetail?.company_name}
                          onChange={(e) => setcompanyName(e.target.value)}
                        />
                      </div>
                      <div className="box-field">
                        <input
                          name="countries"
                          id="countries"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={country ?? userdetail?.country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </div>
                      <div className="box-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Address"
                          name="number"
                          value={addressOne ?? userdetail?.address_one}
                          onChange={(e) => setAddressOne(e.target.value)}
                        />
                      </div>
                      <div className="box-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="apartment, suite, unit etc."
                          name="apartment"
                          value={addressTwo ?? userdetail?.address_two}
                          onChange={(e) => setAddressTwo(e.target.value)}
                        />
                      </div>
                      <div className="box-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Town/City"
                          name="Town/City"
                          value={city ?? userdetail?.city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <div className="box-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your State"
                          value={state ? state : userdetail?.state}
                          onChange={(e) => {
                            const eventstate = e.target.value;
                            setState(eventstate);
                          }}
                        />
                      </div>
                      <div className="box-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Postal Code"
                          name="postal_code"
                          value={
                            postalCode ? postalCode : userdetail?.postal_code
                          }
                          onChange={(e) => {
                            setPostalCode(e.target.value);
                          }}
                        />
                      </div>
                      <small
                        className="postalCodeError"
                        style={{ color: "red" }}
                      >
                        {postalCodeError}
                      </small>
                      <div className="box-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Enter your number"
                          name="number"
                          value={number ?? userdetail?.number}
                          onChange={(e) => setNumber(e.target.value)}
                        />
                      </div>
                      <div className="box-field">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter your email address"
                          name="email"
                          value={email ?? userdetail?.email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="box-field">
                        <textarea
                          name="notes"
                          className="form-control"
                          cols="30"
                          rows="6"
                          onChange={(e) => setSpecialNotes(e.target.value)}
                          placeholder="Notes about your order. eg special notes for your order"
                        ></textarea>
                      </div>

                      <div className="box-field">
                        <label>Promo Code</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Please provide any available Promo Code."
                          name="promocode"
                          style={{ margin: "14px 0 21px 0px" }}
                          value={promocode}
                          onChange={promoCodeFunction}
                        />
                        <span style={{ "font-style": "italic", color: "red" }}>
                          {promoCodeError}
                        </span>
                        <span
                          style={{
                            "font-style": "italic",
                            color: "green",
                          }}
                        >
                          {promoCodeSuccess}
                        </span>
                      </div>


                    </div>

                    {giftPromo?.promos && giftPromo?.promos?.length > 0 && (
                      <div>
                        {giftPromo?.promos?.map((promo, index) => (
                          <div key={index}>
                            <div className="success">{promo.completion_text}</div>
                            <div className="promo-row">
                              <div className="image-box">
                                <img
                                  src={
                                    "https://admin.columbiacosmetics.com/storage/app/public/promo-images/" +
                                    promo.promo_image_url
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="promo-gift-content">
                                <p className="promo-title">
                                  <strong>Name :</strong>{" "}
                                  {promo.promo_name}
                                </p>
                                <p className="promo-title">
                                  <strong>Quantity: </strong>
                                  {promo.free_gift_quantity}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {individualItemPromos && individualItemPromos.length > 0 && (
                      <div>
                        {individualItemPromos.map((promo, index) => (
                          <div key={index} style={{ 'margin-top': '15px' }}>
                            <div className="success">{promo.message.replace("(x)", `${'(' + promo.free_units + ')'}`).replace("Item Name", promo.name).replace("item_subtotal", `${parseFloat(promo.subtotal).toFixed(2)}`)}</div>

                            <div className="promo-row">
                              <div className="promo-image-box">
                                <img src={"https://admin.columbiacosmetics.com/storage/app/public/products/" + promo.image} alt={promo.name} />
                              </div>
                              <div className="promo-gift-content">
                                <p className="promo-title">
                                  <strong>Name :</strong>{" "}
                                  {promo.name}
                                </p>
                                <p className="promo-title">
                                  <strong>SKU: </strong>
                                  {promo.skuno}
                                </p>
                                <p className="promo-title">
                                  <strong>Free Units: </strong>
                                  {promo.free_units}
                                </p>

                                <p className="promo-title">
                                  <strong>Units Purchased: </strong>
                                  {promo.qty}
                                </p>
                              </div>
                            </div>

                          </div>
                        ))}
                      </div>
                    )}

                    <div style={{ marginTop: '35px', fontWeight: 600, lineHeight: '20px' }} className="msg mt-2">
                      If you wish to customize your products by adding labels, pad print, silkscreen, hot stamp or boxes, <a href="tel:+18008243328" style={{ textDecoration: 'underline' }}>
                        please call (800) 824-3328 for more information.
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="other-address">
                    <div className="check">
                      <input
                        className="styled-checkbox"
                        id="different"
                        type="checkbox"
                        onClick={shipForm}
                        checked={shippingForm}
                        value="Different Shipping"
                      />
                      <label for="different">Ship to a Different Address</label>
                    </div>
                    <div className="different-form">
                      <div className="box-field__row">
                        <div className="box-field">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your first name"
                            name="first_name"
                            value={firstName ?? userdetail?.first_name}
                            readOnly
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                        <div className="box-field">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your last name"
                            name="last_name"
                            readOnly
                            value={lastName ?? userdetail?.last_name}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="box-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your company name"
                          name="company_name"
                          readOnly
                          value={companyName ?? userdetail?.company_name}
                          onChange={(e) => setcompanyName(e.target.value)}
                        />
                      </div>
                      <div className="box-field">
                        <input
                          name="countries"
                          id="countries"
                          placeholder="Enter your Country"
                          className="form-control"
                          readOnly
                          value={country ?? userdetail?.country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </div>
                      <div className="box-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Address"
                          name="address"
                          value={shppingAddress ?? ""}
                          onChange={(e) =>
                            setShippingAdressColumn(e.target.value)
                          }
                        />
                      </div>
                      <div className="box-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="apartment, suite, unit etc."
                          name="apartment"
                          value={shppingApartment ?? ""}
                          onChange={(e) => setshppingApartment(e.target.value)}
                        />
                      </div>
                      <div className="box-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Town/City"
                          name="Town/City"
                          value={shppingCity ?? ""}
                          onChange={(e) => setshppingCity(e.target.value)}
                        />
                      </div>
                      <div className="box-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your State"
                          value={shppingState ?? ""}
                          onChange={(e) => {
                            const eventstate = e.target.value;
                            setshppingState(eventstate);
                          }}
                        />
                      </div>
                      <div className="box-field">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Postal Code"
                          name="postal_code"
                          value={shppingPostalCode ?? ""}
                          onChange={(e) => {
                            setshppingPostalCode(e.target.value);
                          }}
                        />
                      </div>
                      <div className="box-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Enter your number"
                          readOnly
                          name="number"
                          value={number ?? userdetail?.number}
                        />
                      </div>
                      <div className="box-field">
                        <textarea
                          name="other_address"
                          className="form-control"
                          cols="30"
                          rows="6"
                          onChange={(e) =>
                            setShippingAccountInfo(e.target.value)
                          }
                          placeholder="If you would like to use your own shipping account, please provide your account information "
                        >
                          {shippingAccountInfo ?? ""}
                        </textarea>
                      </div>
                    </div>

                    <div className="box-field">
                      <div
                        className={`stripe-pay checkout-payment__item ${payment === "stripe" && "active"
                          }`}
                      >
                        <div
                          className="checkout-payment__item-head"
                          bis_skin_checked="1"
                        >
                          <input
                            type="radio"
                            name="radio"
                            className="radio-box"
                            id="myRadioButton"
                          />
                          <label
                            onClick={(event) => stripeFunction(event)}
                            className="radio-box"
                            id=""
                          >
                            <div>
                              {" "}
                              <span className="checkmark"></span>
                            </div>
                            Credit Card / Debit Card
                          </label>
                        </div>
                        <div className="checkout-payment__item-content">
                          <p><strong>Note: </strong>Thank you for choosing this option. We can only process Credit/Debit Card payments over the phone at this time. After placing this order, please call (800) 824-3328 during business hours to confirm your credit card information so we can process your order.</p>
                          {/* <fieldset className="FormGroup">
                                                                        <div className="FormRow">
                                                                            <CardElement options={CARD_OPTIONS} />
                                                                        </div>
                                                                    </fieldset> */}
                        </div>
                        {/* 
                        <div className="card-number">
                          <label htmlFor="cardNumber">Card Number</label>
                          <input
                            type="text"
                            id="cardNumber"
                            value={cardNumber}
                            className="form-control"
                            onChange={handleCardNumberChange}
                            placeholder="1234 5678 9012 3456"
                          />
                        </div>

                        <div className="card-var">
                          <div className="box-field">
                            <label htmlFor="cardExpiry">Expiry Date</label>
                            <input
                              type="text"
                              id="cardExpiry"
                              className="form-control"
                              value={cardExpiry}
                              onChange={handleCardExpiryChange}
                              placeholder="MM/YY"
                            />
                            <label style={{ color: "red" }}>
                              {cardExpiryError}
                            </label>
                          </div>

                          <div className="box-field">
                            <label htmlFor="cardCVV">CVV</label>
                            <input
                              type="text"
                              id="cardCVV"
                              className="form-control"
                              value={cardCVV}
                              onChange={handleCardCVVChange}
                              placeholder="123"
                            />
                          </div>
                        </div> */}
                        <p className="error">{stripeError}</p>
                        {/* </div> */}
                      </div>

                      <div
                        className={`checkout-payment__item ${payment === "paypal" && "active"
                          }`}
                      >
                        <div className="checkout-payment__item-head">
                          <input
                            type="radio"
                            name="radio"
                            className="radio-box"
                            id="myRadioButton"
                          />

                          <label
                            onClick={(event) => paypal(event)}
                            className="radio-box"
                            id=""
                          >
                            <div>
                              {" "}
                              <span className="checkmark"></span>{" "}
                            </div>
                            Direct Bank Transfer
                          </label>
                        </div>
                        <div className="checkout-payment__item-content">
                          <p>
                            Make your payment directly into our bank account.
                            Please use your order ID as the payment reference.
                            Your order will not be shipped until the funds have
                            cleared in our account.
                          </p>
                        </div>
                        <p
                          style={{
                            color: "red",
                            "font-style": "italic",
                            "padding-top": "10px",
                          }}
                        >
                          {bankPayError}
                        </p>
                      </div>
                      <div
                        className={`checkout-payment__item ${payment === "cash" && "active"
                          }`}
                      >
                        <div className="checkout-payment__item-head">
                          <input
                            type="radio"
                            name="radio"
                            className="radio-box"
                            id="myRadioButton2"
                          />

                          <label
                            onClick={(event) => cheque(event)}
                            className="radio-box"
                          >
                            <div>
                              {" "}
                              <span className="checkmark"></span>{" "}
                            </div>
                            Check payment
                          </label>
                        </div>
                        <div className="checkout-payment__item-content">
                          <p>
                            Please send a check to the store name, Store Street,
                            Store Town, Store State/Country, Store Postcode.
                          </p>
                        </div>
                        <p
                          style={{
                            color: "red",
                            "font-style": "italic",
                            "padding-top": "10px",
                          }}
                        >
                          {checkPayError}
                        </p>
                      </div>
                    </div>

                    <div className="fedex-div" style={{ position: "relative" }}>
                      <h6>FedEX</h6>

                      {loading ? (
                        <div
                          style={{
                            position: "absolute",
                            display: "flex",
                            height: "-webkit-fill-available",
                            "align-items": "center",
                            "justify-content": "center",
                            width: "-webkit-fill-available",
                          }}
                          className="loading ovelay"
                        >
                          <img
                            style={{ width: "100px" }}
                            src="/assets/img/loading.gif"
                          />
                          {/* <h6 style={{"text-align":"center","font-size": "30px","font-weight": "bold","color": "#868686","margin-top": "190px"}}>Loading...</h6> */}
                        </div>
                      ) : null}
                      <div className="ship-options">
                        <input
                          type="radio"
                          onChange={liveRate}
                          id="Option1"
                          value="FIRST_OVERNIGHT"
                          name="shipping_method"
                        />
                        <label htmlFor="Option1">FIRST OVERNIGHT</label>
                        <span className="radio-box__info">
                          <i className="icon-info"></i>
                          <span className="radio-box__info-content">
                            First Overnight: Guaranteed delivery by the next
                            business morning.
                          </span>
                        </span>
                        <br />

                        <input
                          type="radio"
                          onChange={liveRate}
                          id="Option2"
                          value="PRIORITY_OVERNIGHT"
                          name="shipping_method"
                        />
                        <label for="Option2">PRIORITY OVERNIGHT</label>
                        <span className="radio-box__info">
                          <i className="icon-info"></i>
                          <span className="radio-box__info-content">
                            Priority Overnight: Guaranteed delivery by the next
                            business afternoon.
                          </span>
                        </span>
                        <br />

                        <input
                          type="radio"
                          onChange={liveRate}
                          id="Option3"
                          value="STANDARD_OVERNIGHT"
                          name="shipping_method"
                        />
                        <label for="Option3">STANDARD OVERNIGHT</label>
                        <span className="radio-box__info">
                          <i className="icon-info"></i>
                          <span className="radio-box__info-content">
                            Standard Overnight: Guaranteed delivery by the next
                            business day afternoon.
                          </span>
                        </span>
                        <br />

                        <input
                          type="radio"
                          onChange={liveRate}
                          id="Option4"
                          value="FEDEX_2_DAY_AM"
                          name="shipping_method"
                        />
                        <label for="Option4">FEDEX 2 DAY AM</label>
                        <span className="radio-box__info">
                          <i className="icon-info"></i>
                          <span className="radio-box__info-content">
                            FedEx 2 Day AM: Guaranteed delivery by the second
                            business morning.
                          </span>
                        </span>
                        <br />

                        <input
                          type="radio"
                          onChange={liveRate}
                          id="Option5"
                          value="FEDEX_2_DAY"
                          name="shipping_method"
                        />
                        <label for="Option5">FEDEX 2 DAY</label>
                        <span className="radio-box__info">
                          <i className="icon-info"></i>
                          <span className="radio-box__info-content">
                            FedEx 2 Day: Guaranteed delivery by the second
                            business day.
                          </span>
                        </span>
                        <br />

                        <input
                          type="radio"
                          onChange={liveRate}
                          id="Option6"
                          value="FEDEX_EXPRESS_SAVER"
                          name="shipping_method"
                        />
                        <label for="Option6">FEDEX EXPRESS SAVER</label>
                        <span className="radio-box__info">
                          <i className="icon-info"></i>
                          <span className="radio-box__info-content">
                            FedEx Express Saver: Guaranteed delivery by the
                            third business day.
                          </span>
                        </span>
                        <br />

                        <input
                          type="radio"
                          onChange={liveRate}
                          id="Option7"
                          value="FEDEX_GROUND"
                          name="shipping_method"
                        />
                        <label for="Option7">FEDEX GROUND</label>
                        <span className="radio-box__info">
                          <i className="icon-info"></i>
                          <span className="radio-box__info-content">
                            FedEx Ground: Economical, reliable, day-definite
                            delivery to businesses and residences in the US and
                            Canada.
                          </span>
                        </span>
                        <br />

                        <input
                          type="radio"
                          onChange={liveRate}
                          id="Option8"
                          value="INTERNATIONAL_PRIORITY"
                          name="shipping_method"
                        />
                        <label for="Option8">INTERNATIONAL PRIORITY</label>
                        <span className="radio-box__info">
                          <i className="icon-info"></i>
                          <span className="radio-box__info-content">
                            International Priority: Fast delivery in 1-3
                            business days to over 220 countries and territories.
                          </span>
                        </span>
                        <br />

                        <input
                          type="radio"
                          onChange={liveRate}
                          id="Option9"
                          value="INTERNATIONAL_ECONOMY"
                          name="shipping_method"
                        />
                        <label for="Option9">INTERNATIONAL ECONOMY</label>
                        <span className="radio-box__info">
                          <i className="icon-info"></i>
                          <span className="radio-box__info-content">
                            International Economy: Economical, day-definite
                            delivery to over 215 countries and territories.
                          </span>
                        </span>
                        <br />

                        <input
                          type="radio"
                          onChange={liveRate}
                          id="Option10"
                          value="INTERNATIONAL_FIRST"
                          name="shipping_method"
                        />
                        <label for="Option10">INTERNATIONAL FIRST</label>
                        <span className="radio-box__info">
                          <i className="icon-info"></i>
                          <span className="radio-box__info-content">
                            nternational First: Delivery of documents and
                            packages in 1-3 business days to select postal codes
                            in 20 countries.
                          </span>
                        </span>
                        <br />

                        <input
                          type="radio"
                          onChange={liveRate}
                          id="Option11"
                          value="INTERNATIONAL_PRIORITY_FREIGHT"
                          name="shipping_method"
                        />
                        <label for="Option11">
                          INTERNATIONAL PRIORITY FREIGHT
                        </label>
                        <span className="radio-box__info">
                          <i className="icon-info"></i>
                          <span className="radio-box__info-content">
                            International Priority Freight: Delivery of
                            heavyweight shipments in 1-3 business days to over
                            130 countries and territories.
                          </span>
                        </span>
                        <br />

                        <input
                          type="radio"
                          onChange={liveRate}
                          id="Option12"
                          value="INTERNATIONAL_ECONOMY_FREIGHT"
                          name="shipping_method"
                        />
                        <label for="Option12">
                          INTERNATIONAL ECONOMY FREIGHT
                        </label>
                        <span className="radio-box__info">
                          <i className="icon-info"></i>
                          <span className="radio-box__info-content">
                            International Economy Freight: Economical,
                            day-definite delivery for heavyweight shipments to
                            over 130 countries and territories.
                          </span>
                        </span>
                        <br />

                        <input
                          type="radio"
                          onChange={liveRate}
                          id="Option13"
                          value="GROUND_HOME_DELIVERY"
                          name="shipping_method"
                        />
                        <label for="Option13">GROUND HOME DELIVERY</label>
                        <span className="radio-box__info">
                          <i className="icon-info"></i>
                          <span className="radio-box__info-content">
                            Ground Home Delivery: Reliable day-definite delivery
                            to residences in the US within 1-7 business days.
                          </span>
                        </span>
                        <br />

                        <small
                          className="fedex_error"
                          style={{ color: "red", "line-height": "25px" }}
                        >
                          {shippingerror}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cart-bottom__total">
                <div className="cart-bottom__total-promo">
                  Shipping
                  <span className="checkout-shipping">
                    <div className="cart-bottom__total-promo">
                      <span className="checkout-shipping">
                        <input
                          type="radio"
                          id="ownaccount"
                          name="own_account"
                          value="Own Account"
                          checked={selectedOption === "Own Account"}
                          onChange={handleOptionChange}
                        />
                        <label htmlFor="ownaccount">Use Own Account</label>
                        <br />
                        <input
                          type="radio"
                          id="willcall"
                          name="own_account"
                          value="Will Call"
                          checked={selectedOption === "Will Call"}
                          onChange={handleOptionChange}
                        />
                        <label htmlFor="willcall">Will Call</label>
                      </span>
                    </div>
                  </span>
                </div>
                <div className="cart-bottom__total own-account">
                  <div className="box-field">
                    <textarea
                      name="shipping_account"
                      cols="30"
                      rows="7"
                      placeholder="If you would like to use your own shipping account, please provide your account information here (e.g. Courier Name, Account no.)"
                      value={shippingText}
                      onChange={handleShippingText}
                    ></textarea>
                  </div>
                </div>

                <div className="cart-bottom__total-num">
                  Sub Total:
                  <span>${parseFloat(subtotal).toFixed(2)}</span>
                </div>
                {couponDiscount ? (
                  <div className="cart-bottom__total-num">
                    Promo Discount :{" "}
                    <span>${parseFloat(discountedPrice).toFixed(2)}</span>
                  </div>
                ) : (
                  ""
                )}
                <div className="cart-bottom__total-num shipping">
                  Shipping:
                  <span>${shippingPrice}</span>
                </div>

                <div className="cart-bottom__total-num shipping">
                  Handling/Insurance/Environmental Fee:
                  <span>${handlingCostPrice}</span>
                </div>

                <div className="cart-bottom__total-num">
                  total:
                  <span>${parseFloat(sum).toFixed(2)}</span>
                </div>
              </div>
              <div className="box-field" id="checkout-cjeck">
                <div className="check">
                  <div className="check check2">
                    <input
                      className="styled-checkbox"
                      id="newsletter-check"
                      type="checkbox"
                      onChange={newsletterCheckbox}
                      checked={newsletter}
                    />
                    <label htmlFor="newsletter-check">
                      Subscribe to our newsletter
                    </label>
                  </div>
                </div>
                <div className="check check2">
                  <input
                    className="styled-checkbox"
                    id="contractmanufacturing"
                    checked={isChecked}
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    value="Contract Manufacturing"
                  />
                  <label for="contractmanufacturing">
                    Accept Privacy Policy, Terms & Conditions{" "}
                  </label>
                </div>
              </div>
              <p className="error">{universalError}</p>
            </div>
            <img
              className="promo-video__decor js-img"
              src="/assets/img/promo-video__decor.jpg"
              alt=""
            />
          </div>

          <div className="checkout checkout-payment">
            <div className="wrapper">
              <div className="checkout-btn">
                {showLink ? (
                  <Link href="#">
                    <a onClick={bankPay} className="btn">
                      Pay With Bank Transfer
                    </a>
                  </Link>
                ) : (
                  ""
                )}

                {showButton ? (
                  <Link href="#">
                    <a onClick={checkPay} className="btn">
                      Pay With Cheque
                    </a>
                  </Link>
                ) : (
                  ""
                )}

                {showStripeButton ? (
                  <button disabled={!stripe}>Pay With Card</button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
}
