import React, { useEffect, useState, useRef, useContext } from "react";
import {
  SlickArrowPrev,
  SlickArrowNext,
} from "../../../components/utils/SlickArrows/SlickArrows";
import Slider from "react-slick";
import faqData from "../../../data/faq/productfaq.json";
import { isEmptyObject } from "jquery";
import AuthUser from "../../utils/AuthUser";
import $ from "jquery";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const ProductDetails = () => {
  //Enviroment Variables
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const productURL = process.env.REACT_APP_PRODUCT_URL;
  const componentURL = process.env.REACT_APP_COMPONENT_URL;
  const swatchURL = process.env.REACT_APP_SWATCH_URL;

  const [galleryUrl, setGalleryUrl] = useState(productURL);

  const { token, logout } = AuthUser();
  const navigate = useNavigate();

  let { parent, slug, subcat } = useParams();


  const [pageSLug, setPageSlug] = useState(slug);
  const [categorySlug, setcategorySlug] = useState(parent);
  const [subcatSlug, setsubcatSlug] = useState(subcat);
  const [isLoggedIn, setisLoggedIn] = useState(false);

  const { http } = AuthUser();
  const [userdetail, setUserdetail] = useState([]);
  const [product, setProduct] = React.useState([]);
  const [viewedProducts, setViewedProducts] = React.useState([]);

  const [colorIngredient, setColorIngredient] = useState([]);

  const [item, setItem] = React.useState([]);
  const [component, setComponent] = React.useState([]);
  const [imageGallery, setimageGallery] = React.useState([]);
  const [quantityvalue, setQuantityvalue] = useState(0);
  const [counter, setCounter] = useState(0);
  const [idnull, setIdNull] = useState(0);
  const [selectedPackaging, setSelectedPackaging] = useState("");
  const [singlePack, setSinglePack] = useState("");
  const [definedSku, setDefinedSku] = useState("");
  const [bulkStock, setBulkStock] = useState(0);
  const [displayBulk, setDisplayBulk] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [productId, setProductId] = useState(0);
  const [addedInCart, setAddedInCart] = useState(false);

  const [quantity, setQuantity] = useState(1);
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const faqs = [...faqData];
  const [image, setImage] = useState("");
  const [swatch, setSwatch] = useState("");
  const [skuNo, setSkuNo] = useState("");
  const [colorCode, setColorCode] = useState("");
  const [colorName, setColorName] = useState("");
  const [productSwatchImage, setProductSwatchImage] = useState("");
  const [packagingLength, selectedPackagingLength] = useState("");
  const [sumCalculation, setSumCalculation] = useState(false);
  const [soldOutMessage, SetsoldOutMessage] = useState("");
  const [soldOutSku, SetsoldOutSku] = useState(0);
  const [cart, setCart] = useState([]);
  const [productImage, setProductImage] = useState("");
  const [productImageUrl, setimageUrl] = useState(productURL);

  const productsMinQty = [233, 223, 224, 225, 226, 227, 230, 231, 232, 233, 235, 236, 237];


  useEffect(() => {
    if (!selectedPackaging) {
      $(".btn-cart").hide();
    }
  }, [selectedPackaging]);

  const loader = (
    <div
      style={{
        position: "fixed",
        display: "flex",
        height: "-webkit-fill-available",
        alignItems: "center",
        justifyContent: "center",
        width: "-webkit-fill-available",
        zIndex: "999",
        top: "0",
        backgroundColor: "#0000009c",
      }}
      className="loading ovelay"
    >
      <img
        style={{ width: "100px" }}
        src="/assets/img/loading.gif"
        alt="Loading"
      />
    </div>
  );

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <SlickArrowPrev />,
    nextArrow: <SlickArrowNext />,
    lazyLoad: "progressive",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const response = await http.post("/getcart", {
          headers: {
            "X-Requested-with": "XMLHttpRequest",
          },
          withCredentials: true,
          client_id: userdetail?.id,
        });
        const data = response.data;
        setItem(data);
        setCounter(data.cart.length);
      } catch (error) {
        if (error.response && error.response.status === 429) {
          console.log("Too many requests, please try again later.");
        } else {
          console.error("Error fetching cart data:", error);
        }
      }
    };

    fetchCartData();

  }, [counter, slug, userdetail?.id]);


  //product api
  useEffect(() => {
    setLoading(true);

    http.post("/product_info", {
      headers: {
        "X-Requested-with": "XMLHttpRequest",
      },
      withCredentials: true,
      id: pageSLug,
      parent: categorySlug,
      subcategory: subcatSlug
    }).then((response) => {
      const data = response.data;
      setLoading(false);
      if (data?.id == undefined) {
        navigate("/404.html");
      }

      if (data?.wp == 1) {
        setProductImage(productImageUrl + data?.product_image);
      }

      selectedPackagingLength(data?.attribute_packageing?.length);

      if (data?.wp != 1 && data?.attribute_packageing && data?.attribute_packageing.length > 0) {
        setSelectedPackaging(data?.attribute_packageing[0]);
      }



      if (data?.wp === 1) {
        if (!isNaN(data?.productstock)) {
          setBulkStock(data?.productstock);
        }
        if (!data?.color_swatches == undefined && data?.color_swatches?.length > 0) {
          $(".pack-div").hide();
        }
        if (data?.attribute_packageing == undefined || data?.attribute_packageing?.length == 0) {
          $(".pack-div").hide();
        }


        if (data?.attribute_packageing?.length > 0) {
          data?.attribute_packageing?.forEach((element) => {
            if (element === "n/a" || element === "") {
              $(".pack-div").hide();
            }
          });
        }



      }

      const ProductPricingHide = [41, 33, 34];
      if (ProductPricingHide.includes(data?.sub_category_id)) {

        if (data?.id === 228) {
          $(".unit-price").show();
          $(".empty-retail-price").css("visibility", "hidden");
        } else {
          $(".unit-price").hide();
        }
        $(".pricingContainer").hide();
        $(".prod-detals").hide();
        $(".empty-retail-price").show();

      }

      if (!ProductPricingHide?.includes(data?.sub_category_id)) {
        $(".pricingContainer").show();
        $(".prod-detals").show();
        $(".unit-price").show();
      }

      if (data?.id === 229) {
        $(".pricingContainer").show();
      }

      if (data?.product_sku !== null) {
        setSkuNo(data?.product_sku);
      }

      setSinglePack(data?.single_packaging);
      setProduct(data);
      setIdNull(slug);

      if (data?.imageGallery?.length > 0) {
        setimageGallery(data?.imageGallery);
      } else {
        setimageGallery(data?.product_image);
      }

      setColorIngredient(data?.color_ingredients);


    });
  }, [slug, productId]);



  useEffect(() => {
    http.post("/me")
      .then((res) => {
        setUserdetail(res.data);
        setisLoggedIn(true);

      })
      .then((response) => console.log(response))
      .catch((error) => {
        if (error.response?.status === 401) console.log("Not Logged in");
        else console.error("Error:", error.message);
      });
  }, [slug, product]);



  useEffect(() => {
    if (userdetail && userdetail?.id) {


      if (product?.id !== undefined && userdetail?.id !== undefined) {
        http.post("/viewed/product", {
          headers: {
            "X-Requested-with": "XMLHttpRequest",
          },
          withCredentials: true,
          data: {
            userid: userdetail?.id,
            productid: product?.id
          }
        });
      }
      http.get(`/getviewed/${userdetail?.id}/${product?.id}`)
        .then((response) => {
          setViewedProducts(response?.data);
        })
        .catch((error) => {
          console.error("Error fetching viewed products:", error);
        });
    }
  }, [userdetail]);


  function link() {
    $(".item-det").slideToggle("slow");
    $(".item1 .head").toggleClass("active");
  }

  function link2() {
    $(".item-det2").slideToggle("slow");
    $(".item2 .head").toggleClass("active");
  }

  function link3() {
    $(".item-det3").slideToggle("slow");
    $(".item3 .head").toggleClass("active");
  }

  function link4() {
    $(".item-det4").slideToggle("slow");
    $(".item4 .head").toggleClass("active");
  }

  useEffect(() => {
    if (product) {
      setAddedInCart(Boolean(cart?.find((pd) => pd.id === product.id)));
    }
  }, [product, cart]);

  function componentImageShow(img) {
    var ImageFromGallery = "";
    var dynamicURL = "";

    if (img.includes("components")) {
      ImageFromGallery = img?.replace(componentURL, "");
      dynamicURL = componentURL;
    } else if (img.includes("products")) {
      ImageFromGallery = img?.replace(productURL, "");
      dynamicURL = productURL;
    } else if (img.includes("swatches")) {
      ImageFromGallery = img?.replace(`${swatchURL}`, "");
      dynamicURL = `${swatchURL}`;
    }

    setImage(dynamicURL + ImageFromGallery);
  }

  function handleClick(
    img,
    sku_no,
    color_code,
    color_name,
    product_swatchImage,
    colorIngredient
  ) {
    setSkuNo(sku_no);
    if (colorIngredient !== undefined && colorIngredient !== null) {
      $(".main-ingredients").html(colorIngredient);
    } else {
      $(".main-ingredients").html(product?.ingredients);
    }

    //Upon Clicking Color Swatches: Gallery

    if (isEmptyObject(product?.imageGallery) && isEmptyObject(component)) {
      setLoading(true);
      http.post("/color-gallery", {
        headers: {
          "X-Requested-with": "XMLHttpRequest",
        },
        withCredentials: true,
        product_id: product?.id,
        color_name: color_name,
      })
        .then((response) => {
          setLoading(false);
          let data = response?.data;
          if (isEmptyObject(data)) {
            data = product?.product_image;
          } else {
            setimageGallery(data);
          }

        });
    }
    const swatchImageReplace = img?.replace(`${swatchURL}`, "");
    setSwatch(swatchImageReplace);

    const imageUrl = (product?.id === 21 || product?.id === 170 || product?.sub_category_id === 41) && product_swatchImage ? swatchURL + swatchImageReplace : img;
    setImage(imageUrl);

    setColorCode(color_code);
    setColorName(color_name);

    if (product_swatchImage !== null && !product_swatchImage !== "null") {
      let newString = img?.replace(productURL, "");


      if (imageGallery) {
        if (!newString.includes("swatches/")) {
          imageGallery.push(newString);
        }


        if (!product_swatchImage.includes("null")) {
          let newImage = product_swatchImage?.replace("productswatchimages/", "components/");

          if (newImage !== null) {
            setimageGallery(prevItems => [newImage, ...prevItems]);

            setProductSwatchImage(newImage);
          }
        } else {
          if (!product_swatchImage.includes("null")) {
            imageGallery.push(product_swatchImage?.replace(productURL, ""));
            setProductSwatchImage(product_swatchImage);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (image) {
      console.log(image);
      $(".main_product").attr("src", image);
    }

    if (skuNo) {
      $(".sku_no").html(`SKU NO: ${skuNo}`);
    }

    if (colorName) {
      $(".colorShow").html(
        `<div style="marginTop:10px">COLOR NAME:  ${colorName}<div>`
      );
    }

    $(".color_code").val(colorCode);
    $(".product_color").val(colorName);
  }, [image, skuNo]);

  useEffect(() => {
    if (product?.single_packaging == 0) {
      if (productURL) {
        setGalleryUrl(productURL);
      }
    } else {
      if (product?.imageGallery?.length > 0) {
        if (productURL) {
          setGalleryUrl(productURL);
        }
      } else if (component?.component_image?.length > 0) {
        if (componentURL) {
          setGalleryUrl(componentURL);
        }
      }
    }



    if (product?.single_packaging == 1) {
      optionFunction();
    }
  }, [selectedPackaging, slug]);

  function optionFunction(selectBox) {
    var colornameo = $("#selectBox").data("color-name");
    var defaultColorM = colorCode;

    var selectedOptions = $("#selectBox option:selected").text();
    setSelectedPackaging(selectedOptions);

    defaultColorM = defaultColorM?.replace("#", "");

    if (colornameo !== defaultColorM) {
      colornameo = defaultColorM;
    }

    selectedValue = selectedPackaging;

    if (selectBox) {
      var selectedValue = selectBox.target.value;
    }

    // selectBox = selectBox.options[selectBox.selectedIndex].text;
    const hasSelected = selectedPackaging == "";

    if (hasSelected == false) {
      setLoading(true);
      http.post("/product_components", {
        headers: {
          "X-Requested-with": "XMLHttpRequest",
        },
        withCredentials: true,
        id: slug,
        packaging: selectedValue,
      }).then((response) => {
        const data = response.data;

        const customPrices = data?.custom_prices[0];
        setLoading(false);

        if (quantityvalue) {
          setBulkStock(customPrices?.bulk_stock - quantityvalue);
          setDisplayBulk(customPrices?.bulk_stock - quantityvalue);
        } else {
          setBulkStock(customPrices?.bulk_stock);
          setDisplayBulk(customPrices?.bulk_stock);
        }

        setimageGallery(data?.component_image);


        if (!data?.component_image) {
          setimageGallery(product?.imageGallery);
          $(".main_product").attr("src", productURL + product?.product_image);
        }

        setColorIngredient(data?.ingredients);

        if (singlePack == 0 || singlePack == 1) {
          setSkuNo(data?.sku_no[0]);
          if (data?.component_image) {
            setGalleryUrl(componentURL);
            setimageGallery(data?.component_image);
          }
        }
        const packaging = response.data?.wp;
        const quantity = $(".qty").val();
        let price = 0;
        const testprices = "ss";

        data?.custom_prices?.map((v, index) => {
          if (product.id == 22 || product.id == 23 || product.id == 26 || product.id == 200) {
            if (quantity < 144) {
              $(".btn-cart").hide();
              $(".btn-quote").hide();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("Please Refer to the price Table");
            } else if (quantity > 144 && quantity <= 500) {
              price = v?.price_two;
              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("");
            } else if (quantity > 500 && quantity <= 999) {
              price = v?.price_three;
              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("");
            } else if (quantity > 999) {
              $(".btn-cart").hide();
              $(".btn-quote").show();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("");
            }

            $(".unit-price").html(price);
          } else {
            if (quantity > 0 && quantity < 6) {

              price = parseFloat(v?.price_one).toFixed(2);

              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".error").html("");
              $(".priceandqty").show();
              $(".response").html(
                "Note: A $0.50 surcharge is added (per unit) if you wish to purchase less than 6 units."
              );
            } else if (quantity >= 6 && quantity <= 11) {
              price = v?.price_two;
              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("");
            } else if (quantity > 11 && quantity <= 72) {
              price = v?.price_three;
              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("");
            } else if (quantity > 72 && quantity <= 144) {
              price = v?.price_four;
              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("");
            } else if (quantity > 144) {
              $(".btn-cart").hide();
              $(".btn-quote").show();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html(
                "Note: If you wish to purchase more than 144 units, please request a quote"
              );
            }

            $(".unit-price").html(price);
          }
        });

        // console.log("packaging: "+ packaging);
        if (packaging === 0) {

          // $(".product-slider__nav").hide();
          $(".colors_row").css("display", "none");
          $(".addtocart").css("display", "inline-block");
          $(".addtocart").css("display", "inline-block");
          $(".product-info__quantity").show();
          $(".note").hide();
        }

        setComponent(data);


        if (singlePack == 1) {
          setDefinedSku(data?.sku_no[0]);
          const definedColorCode = data?.color_code[0];
          if (!data?.color_name[0] == "n/a") {
            setColorName(data?.color_name[0]);
          }
          setColorCode(data?.color_code[0]);

          $(".custom_row.prod-col." + definedColorCode?.replace("#", "")).addClass("active-color");
        }

        if (colornameo) {
          var getcolorcodeindex = data?.color_code;
          var getskuvalue = data?.sku_no;

          var colorIndex = getcolorcodeindex.indexOf("#" + colornameo);

          var skuvalue = getskuvalue.indexOf("#" + colornameo);
          var activeSku = getskuvalue[colorIndex];

          setSkuNo(activeSku);
          $(".custom_row .prod-col." + colornameo).addClass("active-color");

          $(".sku_no").html("SKU NO: " + activeSku);
        }
      });
    }
  }
  useEffect(() => {
    $(".product_packaging_images").val(component?.component_image);
    $(".product_packaging").val(component?.current_packaging);
    if (component?.component_image) {
      component?.component_image?.map((element, index) => {
        $(".main_product").attr("src", `${componentURL + element}`);
      });
    }

  }, [component, slug]);

  function addToCart() {
    const htmlQuantity = $(".qty").val();

    if (productsMinQty.includes(product?.id) && quantity < 12) {
      $(".js-menu__expanded").removeClass("js-menu__expanded");
      return false;
    }
  

    if (!skuNo) {
      $(".Quantityerror").html("Please Select The SKU Number");
      $(".js-menu__expanded").removeClass("js-menu__expanded");
      return false;
    }

    if (bulkStock == 0 || bulkStock < 0) {
      $(".Quantityerror").html(selectedPackaging + " is Out of Stock");
      $(".js-menu__expanded").removeClass("js-menu__expanded");
      return false;
    }

    if (soldOutSku === 1) {
      $(".Quantityerror").html("");
      $(".js-menu__expanded").removeClass("js-menu__expanded");
      return false;
    }

    if (!htmlQuantity) {
      $(".Quantityerror").html("No Quanity Added");
      return false;
    }

    let price = $(".unit-price").html();

    if (!price) {
      $(".Quantityerror").html("No Price Added");
      return false;
    }

    const obj = {
      product_dimension: $(".product_dimension").html(),
      product_packaging_images: $(".product_packaging_images").val(),
    };

    if (token != undefined) {
      http
        .post("/cart", {
          headers: {
            "X-Requested-with": "XMLHttpRequest",
          },
          withCredentials: true,
          clientId: userdetail?.id,
          swatch_img: swatch,
          product_id: product?.id,
          product_name: product?.product_name,
          product_image: product?.product_image,
          product_color: colorName,
          product_details: product?.product_details,
          product_color_code: colorCode,
          product_qty: quantity,
          remaining_stock: displayBulk,
          product_dimension: obj.product_dimension,
          product_ingredients: product?.ingredients?.replace(
            /[^\x00-\x7F]/g,
            ""
          ),
          product_certification: product?.certifications,
          product_sku_no: skuNo,
          product_packaging: selectedPackaging,
          product_packaging_images: obj.product_packaging_images,
          product_price: price,
        })
        .then((response) => {
          setCounter((state) => state + 1);
          const data = response.data;

          if (data?.error) {
            console.log({ Error: data?.error });
            // setError(data?.error);
          }
        });
    }
  }

  function requestquote() {
    const obj = {
      clientId: userdetail?.id,
      product_id: productId,
      product_name: $(".product_name").html(),
      product_details: $(".product_details").html(),
      product_color_code: $(".color_code").val(),
      product_qty: $(".qty").val(),
      product_dimension: $(".product_dimension").html(),
      product_ingredients: $(".product_ingredients").val(),
      product_certification: $(".product_certification").val(),
      product_sku_no: $(".sku_no").html(),
      product_packaging: $(".product_packaging").val(),
      product_packaging_images: $(".product_packaging_images").val(),
      product_price: $(".unit-price").html(),
      product_image: $(".product_image").val(),
    };

    setLoading(true);
    http
      .post("/requestqoute", {
        headers: {
          "X-Requested-with": "XMLHttpRequest",
        },
        withCredentials: true,
        clientId: obj.clientId,
        product_id: obj.product_id,
        product_name: obj.product_name,
        product_image: obj.product_image,
        product_details: obj.product_details,
        product_color_code: obj.product_color_code,
        product_qty: obj.product_qty,
        product_dimension: obj.product_dimension,
        product_ingredients: obj.product_ingredients,
        product_certification: obj.product_certification,
        product_sku_no: obj.product_sku_no,
        product_packaging: obj.product_packaging,
        product_packaging_images: obj.product_packaging_images,
        product_packaging: obj.product_packaging,
        product_price: obj.product_price,
      })
      .then((response) => {
        setLoading(false);
        const data = response.data;

        if (data?.success) {
          navigate("/thankyou");
        } else {
          $(".error").html(data?.error);
        }
      });
  }

  useEffect(() => {
    if (skuNo) {
      http
        .post("/soldouts", {
          headers: {
            "X-Requested-with": "XMLHttpRequest",
          },
          skuNo: skuNo,
          withCredentials: true,
        })
        .then((response) => {
          setLoading(false);
          const data = response.data;
          if (data?.success) {
            SetsoldOutMessage(data?.success);
            SetsoldOutSku(1);
            const elements = document.querySelectorAll(`[data-sku="${skuNo}"]`);
            elements.forEach((element) => {
              element.classList.add("disabled-sku");
            });
            $(".Quantityerror").html("");
          }
          if (data?.error) {
            SetsoldOutSku(0);
            SetsoldOutMessage("");
            $(".Quantityerror").html("");

            const elements = document.querySelectorAll(`.prod-col`);
            elements.forEach((element) => {
              element.classList.remove("disabled-sku");
            });
          }
        });
    } else {
      SetsoldOutSku(0);
      SetsoldOutMessage("");
    }
  }, [skuNo, soldOutSku]);

  function quantityChnage() {


    let quantity = $(".qty").val();
    quantity = isNaN(parseInt(quantity)) ? 0 : quantity;
    setQuantity(quantity);


    //display bulk starts
    let remainingStock = bulkStock - quantity;

    if (quantity) {
      if (remainingStock) {
        setDisplayBulk(remainingStock);
      }
    } else {
      setDisplayBulk(bulkStock);
    }

    //display bulk


    if (product.sub_category_id == 41) {
      if (productsMinQty.includes(product.id)) {
        if (quantity < 12) {
          $(".priceandqty").addClass("hideonwty");
          $(".responsoe-twelve").html(
            "Note: Quantity of this product can not be less than 12"
          );
          
        } else {
          $(".priceandqty").removeClass("hideonwty");
          $(".responsoe-twelve").html("");
        }
      }
    }
    
    if (isNaN(quantity) || quantity === "") {
      quantity = 1;
      $(".unit-price").html(0);
      $(".empty-retail-price").html(0);
      return false;
    }

    setQuantityvalue(quantity);
    let price = 0;

    if (component?.length == 0) {
      if (quantity == 0) {
        $(".unit-price").html(price * 1);
      }

      $(".unit-price").html(price);
    }

    if (product?.sub_category_id == 41) {
      let price = product?.retail_price;

      $(".unit-price").html(price * quantity);
      if ($(".empty-retail-price").is(":visible")) {
        $(".unit-price").html(price);
      }

      if (quantity <= 144) {
        price = product?.retail_price;
        $(".btn-cart").show();
        $(".btn-quote").hide();
        $(".error").html("");
        $(".priceandqty").show();
        $(".response").html("");
      }

      if (quantity > 144) {
        $(".unit-price").html(0);
        $(".btn-cart").hide();
        $(".btn-quote").show();
        $(".priceandqty").show();
        $(".error").html("");
        $(".response").html(
          "Note: If you wish to purchase more than 144 units, please request a quote"
        );
      }
    }



    if (product?.withoutpackingprice?.price_one) {
      if (quantity > 0 && quantity < 6) {
        if (product?.sub_category_id == 35 || product?.sub_category_id == 34 || product?.sub_category_id == 33) {
          price = product?.withoutpackingprice?.price_one;

        } else {

          price = parseFloat(product?.withoutpackingprice?.price_one).toFixed(2);


          $(".btn-cart").show();
          $(".btn-quote").hide();
          $(".error").html("");
          $(".priceandqty").show();
          $(".response").html(
            "Note: A $0.50 surcharge is added (per unit) if you wish to purchase less than 6 units."
          );
        }
      }

      if (quantity >= 6 && quantity < 11) {
        price = product?.withoutpackingprice?.price_two;
        $(".btn-cart").show();
        $(".btn-quote").hide();
        $(".error").html("");
        $(".priceandqty").show();
        $(".response").html("");
      }

      if (quantity >= 11 && quantity < 72) {
        price = product?.withoutpackingprice?.price_three;
        $(".btn-cart").show();
        $(".btn-quote").hide();
        $(".error").html("");
        $(".priceandqty").show();
        $(".response").html("");
      }

      if (quantity >= 72 && quantity <= 144) {
        price = product?.withoutpackingprice?.price_four;
        $(".btn-cart").show();
        $(".btn-quote").hide();
        $(".error").html("");
        $(".priceandqty").show();
        $(".response").html("");
      }

      if (quantity > 144) {
        $(".btn-cart").hide();
        $(".btn-quote").show();
        $(".priceandqty").show();
        $(".error").html("");
        $("");
        $(".response").html(
          "Note: If you wish to purchase more than 144 units, please request a quote"
        );
      }
      $(".unit-price").html(price);
    }


    if (product?.id === 229) {
      if (quantity < 6) {
        $(".empty-retail-price").html("13.50");
        $(".response").html(
          "Note: A $0.50 surcharge is added (per unit) if you wish to purchase less than 6 units."
        );
      } else if (quantity >= 6 && quantity < 11) {
        $(".empty-retail-price").html("13.00");
      } else if (quantity >= 11 && quantity < 72) {
        $(".empty-retail-price").html("12.75");
      } else if (quantity >= 72 && quantity <= 144) {
        $(".empty-retail-price").html("12.60");
      }
    }

    const componentProductsCon = [22, 23, 26, 200, 171];
    if (component?.custom_prices) {
      component?.custom_prices?.map((v, index) => {
        if (componentProductsCon.includes(product?.id)) {

          if (v?.price_five == "0.00" && v?.price_six == "0.00" || v?.price_five == null && v?.price_six == null) {
            if (quantity > 0 && quantity < 6) {
              price = parseFloat(v?.price_one).toFixed(2);
              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".error").html("");
              $(".priceandqty").show();
              $(".response").html(
                "Note: A $0.50 surcharge is added (per unit) if you wish to purchase less than 6 units."
              );
            } else if (quantity >= 6 && quantity <= 11) {
              price = v?.price_two;
              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("");
            } else if (quantity > 11 && quantity <= 72) {
              price = v?.price_three;
              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("");
            } else if (quantity > 72 && quantity <= 144) {
              price = v?.price_four;
              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("");
            } else if (quantity > 144) {
              $(".btn-cart").hide();
              $(".btn-quote").show();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html(
                "Note: If you wish to purchase more than 144 units, please request a quote"
              );
            }
          } else {
            if (quantity > 0 && quantity < 6) {

              price = parseFloat(v?.price_one).toFixed(2);

              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".error").html("");
              $(".priceandqty").show();
              $(".response").html(
                "Note: A $0.50 surcharge is added (per unit) if you wish to purchase less than 6 units."
              );
            } else if (quantity >= 6 && quantity <= 11) {
              price = v?.price_two;
              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("");
            } else if (quantity > 11 && quantity <= 72) {

              price = v?.price_three;
              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("");
            } else if (quantity > 72 && quantity <= 144) {

              price = v?.price_four;
              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("");
            } else if (quantity > 144 && quantity <= 500) {

              price = v?.price_five;
              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("");
            } else if (quantity > 500 && quantity <= 999) {
              price = v?.price_six;
              $(".btn-cart").show();
              $(".btn-quote").hide();
              $(".priceandqty").show();
              $(".error").html("");
              $(".response").html("");
            }
          }

          $(".unit-price").html(price);
        } else {
          if (quantity > 0 && quantity < 6) {

            price = parseFloat(v?.price_one).toFixed(2);

            $(".btn-cart").show();
            $(".btn-quote").hide();
            $(".error").html("");
            $(".priceandqty").show();
            $(".response").html(
              "Note: A $0.50 surcharge is added (per unit) if you wish to purchase less than 6 units."
            );
          } else if (quantity >= 6 && quantity <= 11) {
            price = v?.price_two;
            $(".btn-cart").show();
            $(".btn-quote").hide();
            $(".priceandqty").show();
            $(".error").html("");
            $(".response").html("");
          } else if (quantity > 11 && quantity <= 72) {
            price = v?.price_three;
            $(".btn-cart").show();
            $(".btn-quote").hide();
            $(".priceandqty").show();
            $(".error").html("");
            $(".response").html("");
          } else if (quantity > 72 && quantity <= 144) {
            price = v?.price_four;
            $(".btn-cart").show();
            $(".btn-quote").hide();
            $(".priceandqty").show();
            $(".error").html("");
            $(".response").html("");
          } else if (quantity > 144) {
            $(".btn-cart").hide();
            $(".btn-quote").show();
            $(".priceandqty").show();
            $(".error").html("");
            $(".response").html(
              "Note: If you wish to purchase more than 144 units, please request a quote"
            );
          }

          $(".unit-price").html(price);
        }
      });
    }

  }

  useEffect(() => {
    if (isLoggedIn == true) {

      $(".pricing-table").show();
      $(".qty-select").show();
      $(".btn-signin").hide();

      let hidePriceTable = [33, 34];

      if (hidePriceTable.includes(product?.sub_category_id)) {
        $(".pricing-table").hide();
      }

      $(".cart-div").show();
    } else {
      $(".pricing-table").hide();
      $(".qty-select").hide();

      $(".cart-div").hide();
      $(".btn-signin").show();
    }
  }, [isLoggedIn]);

  useEffect(() => { }, [sumCalculation]);

  function checkout() {


    const totalPrices = $(".cartprice")
      .map(function () {
        let price = parseFloat($(this).text()?.replace("Price: ", ""));
        const quantity = parseInt(
          $(this).siblings(".cartQuantity").text()?.replace("Quantity: ", ""),
          10
        );
        return price * quantity;
      })
      .get();

    const totalPrice = totalPrices.reduce((sum, price) => sum + price, 0);


    if (totalPrice < 275) {
      setError(
        "Error: Minimum checkout page amount is $275. Please adjust your cart items accordingly"
      );
      return false;
    } else {
      console.log(totalPrice);
      navigate("/payment");
    }
  }

  useEffect(() => {
    $(".js-menu__open").on("click", function () {
      const htmlQuantity = $(".qty").val();

      if (!htmlQuantity) {
        $(".Quantityerror").html("No Quanity Added");
        return false;
      }

      let price = $(".unit-price").html();

      if (!price) {
        $(".Quantityerror").html("No Price Added");
        return false;
      }

      var menu = $(this).attr("data-menu");

      $(menu).toggleClass("js-menu__expanded");
      $(menu).parent().toggleClass("js-menu__expanded");
    });

    $(".js-menu__context, .js-menu__close").on(
      "touchend click",
      function (event) {
        if (
          $(event.target).hasClass("js-menu__context") ||
          $(event.target).hasClass("js-menu__close")
        ) {
          $(".js-menu__expanded").removeClass("js-menu__expanded");
        }
      }
    );
  }, []);

  //
  useEffect(() => {
    if (product.id == 76) {
      $(".product-info__color").hide();
      $("#packaging-head").html("Fragnance");
      // $(".pricing-table").html(
      //   '<div className="container" bis_skin_checked="1"><div className="question active" bis_skin_checked="1">Pricing</div><div className="prices current" bis_skin_checked="1"><div className="col" bis_skin_checked="1"><h6>Quantity</h6><ul><li>6 - 11</li><li>12 - 72</li><li>73 - 144</li></ul></div><div className="col" bis_skin_checked="1"><h6>Price</h6><ul><li>2.15</li><li>2.05</li><li>1.90</li><li> </li><li></li></ul></div></div></div>'
      // );
    }
  }, [slug, product]);

  //
  useEffect(() => {
    if (product.id == 21 || product.id == 48) {
      $(".main_product").css("object-fit", "contain");
    }
  }, [idnull, product]);

  //
  useEffect(() => {
    const productIdsCon = [
      228, 234, 48, 242, 139, 171, 125, 124, 123, 122, 121, 120, 119, 118, 117, 241,
      115, 114, 113, 112, 111, 110, 109, 108, 107, 106, 105, 104, 103, 77, 78, 79,
      80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 95, 96, 97, 98, 99,
      100, 101, 102, 130, 131, 132, 133, 134, 135, 136, 137, 138, 140
    ];

    const anotherProductIdsCon = [
      76, 167, 166, 165, 164, 163, 162, 161, 160, 159, 158, 157, 156, 155, 154, 153,
      152, 151, 150, 149, 148, 147, 146, 141, 142, 143, 144, 145, 172, 173, 198, 197,
      196, 195, 194, 193, 192, 191, 190, 189, 188, 187, 186, 185, 184, 183, 182, 181,
      180, 179, 178, 177, 176, 175, 174, 201
    ];

    if (productIdsCon.includes(product.id)) {
      $(".product-info__color").hide();
      $(".sku_no").css("display", "block");
    }

    if (anotherProductIdsCon.includes(product.id)) {
      $(".item").hide();
    }
  }, [idnull, product]);



  useEffect(() => {
    if (product.id == 47) {
      $("#packaging-head").html("Size");
      $(".product-info__color").hide();
      $(".fisrt-disab").html("Select Size");
    }
    if (product.id == 228 || product.id == 234) {
      $("#packaging-head").html("Size");
      $(".fisrt-disab").html("Select Size");
    }
    if (product.id == 228 || product.id == 234) {
      $(".pricing-table").hide();
      $(".response").hide();
    }
  }, [idnull, product]);

  useEffect(() => {
    if (product.sub_category_id === 40) {
      $(".product-info__color").hide();
    }
  }, [idnull, product]);

  useEffect(() => {
    $(document).on("click", ".prod-col", function (event) {
      $(this).addClass("active-color");

      // if(skuNo){
      //   $(this).data("sku").val(skuNo);
      // }

      $(this).siblings().removeClass("active-color");
    });
  }, []);

  const [getelementone, setConditionone] = React.useState([]);
  const [getelementtwo, setConditiontwo] = React.useState([]);
  const [getelementthree, setConditionthree] = React.useState([]);
  const [getelementfour, setConditionfour] = React.useState([]);
  const [getelementfive, setConditionfive] = React.useState([]);

  useEffect(() => {
    let productIds = [22, 23, 26, 200, 171];
    if (productIds.includes(product?.id)) {
      $("#morethen").html("If you wish to purchase more than 999 units");

      let conditionone = <li>6 - 11</li>;
      let conditiontwo = <li>12 - 72</li>;
      let conditionthree = <li>73 - 144</li>;
      let conditionfour = <li>145-500</li>;
      let conditionfive = <li>501-999</li>;

      setConditionone(conditionone);
      setConditiontwo(conditiontwo);
      setConditionthree(conditionthree);
      setConditionfour(conditionfour);
      setConditionfive(conditionfive);


    } else {
      let conditionone = <li>6 - 11</li>;
      let conditiontwo = <li>12 - 72</li>;
      let conditionthree = <li>73 - 144</li>;

      setConditionone(conditionone);
      setConditiontwo(conditiontwo);
      setConditionthree(conditionthree);
    }
  }, [idnull, product]);

  // const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    if (product?.attribute_packageing && product?.attribute_packageing.length === 1) {
      $(".btn-cart").show();
      $(".priceandqty").show();
      $(".product-info__quantity").show();
    } else {

      if (product?.wp == 1 && isLoggedIn == true) {
        $(".btn-cart").show();
        $(".priceandqty").show();
        $(".product-info__quantity").show();
        $(".packaging-div").hide();
      }


    }
  }, [slug, isLoggedIn, product]);



  useEffect(() => {
    $(".question").on("click", function () {
      $(this).next().toggleClass("current");
      $(this).toggleClass("active");
    });
  }, [idnull, product]);

  useEffect(() => {
    if (product?.id === 228 || product?.id === 234) {
      $(".priceandqty").show();
      $(".product-info__quantity").show();
    }
  }, [idnull, product]);

  const items = item?.cart;
  const swatches = product?.color_swatches;

  const pricingTable = (
    <>
      {product && product?.wp == 1 ? (
        <div className={`container pricingContainer`}>
          <div className={`question active ${product?.withoutpackingprice?.price_one !== null && product?.withoutpackingprice?.price_two !== null && product?.withoutpackingprice?.price_three !== null ? "" : "hidden"}`}>
            Pricing Table
          </div>
          <div className="prices current">
            <div
              className={`col ${product?.withoutpackingprice?.price_one !== null &&
                product?.withoutpackingprice?.price_two !== null &&
                product?.withoutpackingprice?.price_three !== null
                ? ""
                : "hidden"
                }`}
            >
              <h6>Quantity</h6>
              <ul>
                {product?.withoutpackingprice?.price_one == null ? "" : getelementone}
                {product?.withoutpackingprice?.price_two == null ? "" : getelementtwo}
                {product?.withoutpackingprice?.price_three == null ? "" : getelementthree}
              </ul>
            </div>
            <div
              className={`col ${product?.withoutpackingprice?.price_one !== null &&
                product?.withoutpackingprice?.price_two !== null &&
                product?.withoutpackingprice?.price_three !== null
                ? ""
                : "hidden"
                }`}
            >
              <h6>Price</h6>
              <ul>
                <li>{product?.withoutpackingprice?.price_two}</li>
                <li>{product?.withoutpackingprice?.price_three}</li>
                <li>{product?.withoutpackingprice?.price_four}</li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        product?.packaging_prices?.map((v, i) => (
          <div key={i} className="container">
            <div className="question active">{v?.packaging}</div>
            <div className="prices current">
              <div className="col">
                <h6>Quantity</h6>
                <ul>
                  {getelementone}
                  {getelementtwo}
                  {getelementthree}

                  {v?.price_five == "0.00" || v?.price_five == null
                    ? ""
                    : getelementfour}
                  {v?.price_six == "0.00" || v?.price_six == null
                    ? ""
                    : getelementfive}
                </ul>
              </div>
              <div className="col">
                <h6>Price</h6>
                <ul>
                  <li>{v?.price_two}</li>
                  <li>{v?.price_three}</li>
                  <li>{v?.price_four}</li>

                  <li>
                    {" "}
                    {v?.price_five == "0.00" || v?.price_five == null
                      ? ""
                      : v?.price_five}
                  </li>
                  <li>
                    {v?.price_six == "0.00" || v?.price_six == null
                      ? ""
                      : v?.price_six}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );


  if (!product) return <></>;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{product?.product_name}</title>
        <meta name="description" content={product?.product_description}></meta>
        <link rel="canonical" href={`https://columbiacosmetics.com/${parent}${subcat ? '/' + subcat : ''}/${slug}`}></link>
        <meta property="og:url" href={`https://columbiacosmetics.com/${parent}${subcat ? '/' + subcat : ''}/${slug}`} />
        <meta property="og:image" content={productImageUrl + product?.product_image} />
      </Helmet>
      {loading ? loader : ""}

      <div className="js-menu__context">
        <div id="main-nav" className="js-menu js-menu--right">
          <span className="js-menu__close">✕(Continue Shopping)</span>
          {/* <h6>Foundation Stick has been added to cart!</h6> */}
          <div className="cart-table__row cart-table__row-head">
            <div className="cart-table__col">Product</div>
          </div>
          {token === undefined
            ? "Please Login again"
            : Array.isArray(items) &&
            items.map((evt, index) => (
              <div key={evt.product_id} className="cart-table__row">
                <div className="cart-table__col">
                  <Link to={"#"} className="cart-table__img">
                    <img
                      src={`${productURL}${evt.product_image}`}
                      className="js-img"
                      alt=""
                    />
                  </Link>
                  <div className="cart-table__info">
                    <a className="title5">{evt.product_name}</a>
                    <div className="dets">
                      <small>{evt.product_packaging}</small>
                      <br />
                      <small>{evt.product_color}</small>
                      <br />
                      <small>{evt.product_sku_no}</small>
                      <br />
                      <small className="cartprice">
                        Price: {evt.product_price}
                      </small>
                      <br />
                      <small className="cartQuantity">
                        Quantity: {evt.product_qty}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            ))}


          <div className="button">
            <p
              style={{
                "color": "red",
                "fontStyle": "italic",
                "paddingBottom": "7px",
              }}
            >
              {error}
            </p>
            <Link to="/cart" className="btn btn-primary" id="shopping">
              View Cart
            </Link>
            <Link to="javascript:void(0)" onClick={checkout} className="btn btn-primary">
              Checkout
            </Link>
          </div>
        </div>
      </div>

      <div className="product">
        <div className="wrapper">
          <div className="product-content">
            <div className="product-slider">
              <div className="product-slider__main">
                <div className="product-slider">
                  <div className="product-slider__main">

                    <Slider fade={true} asNavFor={nav2} arrows={false}
                      lazyLoad={true} ref={(slider1) => setNav1(slider1)}>

                      {imageGallery && Array.isArray(imageGallery) ? (
                        imageGallery.map((img, index) => (
                          <div
                            key={index}
                            className="product-slider__main-item abcd"
                          >
                            <img src={productURL + img}
                              loading="lazy"
                              alt="product"
                              className="main_product"
                            />
                          </div>
                        ))
                      ) : (
                        <div className="product-slider__main-item abcd">
                          <img
                            src={`${productURL + product?.product_image}`}
                            className="main_product singleone"
                            loading="lazy"
                            alt="product"
                          />
                        </div>
                      )}
                    </Slider>
                  </div>
                </div>
              </div>

              {/* <!-- Product Slide Nav --> */}

              <div className="product-slider__nav">
                <Slider
                  arrows={true}
                  asNavFor={nav1}
                  ref={(slider2) => setNav2(slider2)}
                  slidesToShow={imageGallery?.length === 1 ? 1 : 2}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  infinite={false}
                  lazyLoad={true}
                >
                  {Array.isArray(imageGallery) && galleryUrl && imageGallery.map((img, index) => {
                    let imageUrl;
                    imageUrl = galleryUrl + img;

                    return (
                      <div
                        key={index}
                        className="product-slider__nav-item main_product "
                        loading=""
                        onClick={() => componentImageShow(galleryUrl + img)}
                      >
                        <img
                          src={galleryUrl + img}
                          alt="product"
                          id="gallery-img"
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>
              <input
                type="hidden"
                className="product_image"
                value={product?.product_image}
              />

              <input type="hidden" className="product_packaging" />
              <input type="hidden" className="product_packaging_images" />
              {/* pricing-table */}
              <div className="pricing-table">{pricingTable}</div>
              <Link to={"#"} className="btn btn-primary btn-quote" onClick={() => requestquote()}>
                Request a Quote
              </Link>
              <p id="morethen">If you wish to purchase more than 144 units</p>
            </div>

            <div className="product-info">
              {/* <h3>{product.product_name}</h3> */}
              {product.isStocked ? (
                <span className="product-stock">in stock</span>
              ) : (
                ""
              )}

              {/* those products with zero packagings */}

              {/* those products with zero packagings */}

              {singlePack == 1 ? (
                <div className="shop-main__select pricing-div">
                  <span className="sku_no">SKU NO : {definedSku}</span>
                  <span className="colorShow"></span>
                </div>
              ) : (
                <div className="shop-main__select pricing-div">
                  <span className="sku_no"></span>
                  <div style={{ "marginTop": "10px" }}>
                    <span className="colorShow"></span>
                  </div>
                </div>
              )}

              <h3 className="product_name">{product.product_name}</h3>

              <p className="weight">
                {component?.net_weight?.map((v, i) => v?.net_wt)}
              </p>

              {/* <p id="description">{product.product_description}</p> */}
              <p
                id="description"
                dangerouslySetInnerHTML={{
                  __html: product?.product_description,
                }}
              />
              {/* <p className="product_details">{product.product_details}</p> */}
              <p
                className="product_details"
                dangerouslySetInnerHTML={{ __html: product?.product_details }}
              ></p>

              <p className="prod-certs">
                <strong>{product.certifications} </strong>
              </p>

              {product?.wp == 0 ? (
                <p className="prod-retail">{product?.retail_price}</p>
              ) : (
                ""
              )}

              <div className="shop-main__select packaging-div">
                <div className="product-info__color">
                  {product?.color_swatches?.length ? <span>Color:</span> : ''}
                  {/* {component?.color_swatches?.length  ? <span>Color:</span> : ''} */}

                  <div className="colors_row">
                    {Array.isArray(product?.color_swatches) &&
                      product?.color_swatches?.map((color, index) => (
                        <div
                          className={`prod-col color-box`}
                          key={index}
                          onClick={() =>
                            handleClick(
                              `${swatchURL + color}`,
                              product?.sku_no[index],
                              product?.colors[index],
                              product?.color_name[index],
                              `productswatchimages/${product?.productwithColor[index]}`,
                              product?.color_ingredients[index]
                            )
                          }
                          data-img={`${swatchURL + color}`}
                          data-sku={product?.sku_no[index]}
                          title={
                            product?.color_name[index] +
                            (product?.soldout[index] == 1
                              ? ` IS OUT OF STOCK`
                              : ``)
                          }
                          style={{
                            backgroundImage: `url('${swatchURL + color}')`,
                            height: "75px",
                            backgroundSize: "cover",
                            width: "75px",
                            display: "inline-block",
                          }}
                        >
                          <input
                            type="hidden"
                            className="color_code"
                            value=""
                            name="color_code"
                          />

                          <input
                            type="hidden"
                            className="color_swatch"
                            value=""
                            name="color_swatc"
                          />

                          <input
                            type="hidden"
                            className="product_color"
                            value=""
                            name="product_color"
                          />
                        </div>
                      ))}
                  </div>

                  <div className="custom_row">
                    {Array.isArray(component?.color_swatches) &&
                      component?.color_swatches?.map((color, colorindex) => (

                        <div
                          className={`prod-col ${component?.color_code[
                            colorindex
                          ]?.replace("#", "")}`}
                          key={colorindex}
                          onClick={(e) =>
                            handleClick(
                              `${swatchURL + color}`,
                              component?.sku_no[colorindex],
                              component?.color_code[colorindex],
                              component?.color_name[colorindex],
                              component?.productwithColor[colorindex],
                              component?.color_ingredients[colorindex]
                            )
                          }
                          data-img={`${swatchURL + color}`}
                          data-sku={component?.sku_no[colorindex]}
                          title={
                            component?.color_name[colorindex] +
                            (product?.soldout[colorindex] == 1
                              ? ` IS OUT OF STOCK`
                              : ``)
                          }
                          style={{
                            backgroundImage: `url('${swatchURL + color}')`,
                            height: "75px",
                            backgroundSize: "cover",
                            width: "75px",
                            display: "inline-block",
                          }}
                        >
                          <input
                            type="hidden"
                            className="color_code"
                            value=""
                            name="color_code"
                          />
                          <input
                            type="hidden"
                            className="product_color"
                            value=""
                            name="product_color"
                          />
                        </div>
                      ))}
                  </div>
                </div>
                <div className="pack-div">
                  <span className="product-info__quantity-title" id="packaging-head">
                    Packaging:
                  </span>
                  <select
                    className="react-dropdown abcde"
                    name=""
                    data-color-name={colorCode?.replace("#", "")}
                    onChange={optionFunction}
                    id="selectBox"
                  >
                    {product?.attribute_packageing?.length > 1 && (
                      <option value="" selected disabled >
                        Select The Packaging
                      </option>
                    )}
                    {Array.isArray(product?.attribute_packageing) &&
                      product?.attribute_packageing.map((v, i) => (
                        <option key={i} data-sku={skuNo} value={v}>
                          {v}
                        </option>
                      ))}
                  </select>

                  <input
                    type="hidden"
                    name="firstpackaging"
                    value="Matte Silver Barrel w/ Pencil-colored End & Black Protector"
                    id="firstPack"
                  />
                </div>





                <span className="error"></span>
              </div>




              <div className="note packaging"></div>
              <div className="error">{soldOutMessage}</div>
              {/* <!-- Product Color info--> */}
              <div className="product-optionse qty-select">
                {/* <!-- Order Item counter --> */}
                <div className="product-info__quantity">
                  <span className="product-info__quantity-title">
                    Quantity:
                  </span>
                  <div className="counter-box">
                    <input
                      className="qty"
                      type="text"
                      onKeyUp={() => quantityChnage()}
                      placeholder="Product Quantity"
                      name=""
                      maxLength="5"
                      id=""
                    />
                  </div>

                  <div className="Quantityerror"></div>
                  <div className="responsoe-twelve"></div>
                </div>
              </div>
              {/* clear */}
              {bulkStock != 0 ? (
                <div className="bulk-div a">
                  {/* <span className="product-info__quantity-title" >
                    Product Bulk Stock: <strong className="bulkstockQuantity">{displayBulk ? displayBulk : bulkStock} Pieces</strong>
                  </span> */}
                  <h5
                    className="stockheading"
                    style={{
                      margin: "0px 0px 15px 0px",
                      "fontWeight": "bolder",
                      color: "#000000c9",
                    }}
                  ></h5>
                  <small
                    className="bulkstockErr"
                    style={{ color: "red" }}
                  ></small>
                </div>
              ) : (
                ""
              )}
              <div className="cart-div a">
                <span className="product-info__quantity-title">
                  Unit Price:
                </span>
                <p className="dollar">
                  $<span className="unit-price">0</span>
                  <span
                    className="empty-retail-price"
                    style={{ display: "none" }}
                  >
                    {product?.retail_price}
                  </span>
                </p>
                <input type="hidden" className="priceunit" />

                <Link to="#" className="btn btn-primary btn-cart js-menu__open"
                  data-menu="#main-nav"
                  onClick={addToCart}
                >
                  Add to Cart

                </Link>

                <Link to={"#"} className="btn btn-primary btn-quote" onClick={() => requestquote()}>
                  Request a Quote
                </Link>

                <div className="error"></div>
                <div className="response"></div>

                <div
                  style={{
                    marginTop: "35px",
                    marginBottom: "15px",
                    fontWeight: 600,
                    lineHeight: "20px",
                  }}
                  className="msg mt-2"
                >
                  If you wish to customize this product by adding labels, pad
                  print, silkscreen, hot stamp, or boxes,{" "}
                  <a
                    href="tel:+18008243328"
                    style={{ textDecoration: "underline" }}
                  >
                    please call (800) 824-3328 for more information.
                  </a>
                </div>
              </div>
              
              <Link to={`/login?redirection=${parent}${subcat ? '/' + subcat : ''}/${slug}`} className="btn btn-primary btn-signin">
                Sign In / Register
              </Link>
              <input
                type="hidden"
                className="product_ingredients"
                value={product.ingredients}
              />
              <input
                type="hidden"
                className="product_certification"
                value={product.certifications}
              />
              <div className="prod-detals">
                <div className="container">
                  {component?.custom_dimensions?.map((v, i) => (
                    <div key={i} className="item item1">
                      <h5 className="head active" onClick={() => link()}>
                        Package Size
                      </h5>
                      <div className="item-det">
                        <p className="product_dimension">{v?.dimensions}</p>
                      </div>
                    </div>
                  ))}
                  <div className="item item2">
                    <h5 className="head" onClick={() => link2()}>
                      Benefits
                      {/* <span className="faq-item__head-btn"></span> */}
                    </h5>
                    <div className="item-det2">
                      <p>{product?.benefits}</p>
                    </div>
                  </div>
                  <div className="item item3">
                    <h5 className="head" onClick={() => link3()}>
                      Directions
                    </h5>
                    <div className="item-det3">
                      <p>{product?.directions}</p>
                    </div>
                  </div>
                  <div className="item item4">
                    <h5 className="head active" onClick={() => link4()}>
                      Ingredients
                    </h5>
                    <div className="item-det4">
                      <p className="main-ingredients">{product.ingredients}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {userdetail && Array.isArray(viewedProducts) && viewedProducts.length > 0 && (
        <section className="arrivals">
          <div className="trending-top" bis_skin_checked="1">
            <span className="saint-text">Cosmetics</span>
            <h2>You Have Viewed</h2>
            <p>
              Nourish your skin with toxin-free cosmetic products. With the offers
              that you can’t refuse.
            </p>
          </div>
          <Slider {...settings}>
            {viewedProducts.map((product, key) => (
              <div className="products-item" key={key}>
                <a href={`/${product?.category}/${product?.slug}`}>
                  <div className="products-item__img">
                    <img
                      src={productURL + product.image}
                      loading="lazy"
                      className="js-img img-fluid"
                      alt=""
                    />
                    <div className="products-item__hover"></div>
                  </div>
                </a>
                <div className="products-item__info">
                  <a href={`/${product?.category}/${product?.slug}`}>
                    {product.name}
                  </a>
                </div>
              </div>
            ))}
          </Slider>
        </section>
      )}
      {/* {userdetail && Array.isArray(viewedProducts) && viewedProducts.length === 0 && (
        <div className="no-viewed-products-message">
          <p>You haven't viewed any products yet.</p>
        </div>
      )} */}



    </>
  );
};

export default ProductDetails;
