import { PublicLayout } from '../layout/PublicLayout';
import { Advantage } from '../components/shared/Advantage/Advantage';
import { Infotwo } from '../components/Privatelabel/Infotwo/Infotwo';
import { How } from '../components/Privatelabel/How/How';
import { Privatefaq } from '../components/Privatelabel/Privatefaq/Privatefaq';
import { Branding } from '../components/Privatelabel/Branding/Branding';
import  Contact  from '../components/Privatelabel/Contact/Contact';
import { Started } from '../components/Privatelabel/Started/Started';
import React from 'react';
import ScrollToTop from "react-scroll-to-top";
import { Seo } from '../components/shared/Seo';


const breadcrumbsData = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Private Label',
    path: '/privatelabel',
  },
];


export default function Privatelabel() {
  return (
    <PublicLayout breadcrumb={breadcrumbsData} breadcrumbTitle='Private Label'>
      <Seo />
      <Infotwo />
      <How />
      <Advantage />
      <Branding />
      <Started />
      <Privatefaq />
      <Contact />
      <ScrollToTop smooth />
    </PublicLayout>
  );
}



