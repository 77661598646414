import React from 'react';

export const Work = () => {
  return (
    <>
      <div className="help private-help" style={style}>
        <div className="container">
            <div className="row">
                <h2>Let's Work Together</h2>
                <p>Great ideas deserve to be turned into quality products. Let’s discuss how we can support you.</p>
                <a href='/contact' className="btn btn-primary">Contact Us</a>
            </div>
        </div>
      </div>
    </>
  );
};

const style = {
    'backgroundColor': "#f2f2f2",
    "marginTop": "-3px"
};
