import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SearchInput from '../../components/Product/Products/SingleProduct/Search';
import SideNav from '../../components/shared/Header/Nav/SideNav';
import { useParams } from 'react-router-dom';
import Slider, { createSliderWithTooltip, Range } from 'rc-slider';
import { Helmet } from 'react-helmet';

export const Shop = () => {
  const [categories, setCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentCategoty, setCurrentCategoty] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [currentCatSlug, setCurrentCatSlug] = useState('');
  
  const [loading, setLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState('');

  let { category, catslug } = useParams();


  const slugify = (text) => {
    if (typeof text !== 'string') {
      return '';
    }
  
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/[\s_]+/g, "-")
      .replace(/[^\w-]+/g, "");
  };
  


  useEffect(() => {
    if (category) {
      axios.get('https://api.columbiacosmetics.com/api/categories')
        .then((response) => {
          const data = response.data;
          setCategory(data);

          let item = data?.category_name.find(item => item.category_slug == category);
          setCurrentCategoty(item);


          if (item == undefined) {
            item = data?.parent.find(item => item.link == category);
            setCurrentCategoty(item);
          }
        
         
          if (item && item?.category_slug !== undefined) {
            setCurrentCatSlug('/' + item?.category_slug)
            setCategoryName(item?.sub_category_name);
          }else if(item && item?.link !== undefined){
            setCurrentCatSlug('/' + item?.link)
            setCategoryName(item?.category_name);
          }

        });
    }


  }, [category]);

  const handleSearch = async (searchValue) => {

    console.log(searchValue);
    if (searchValue) {
      setLoading(true);
      axios.post('https://api.columbiacosmetics.com/api/products/search', {
        headers: {
          'X-Requested-with': 'XMLHttpRequest',
        },
        withCredentials: true,
        product_id: category,
        search: searchValue,
      }).then((response) => {
          setLoading(false);
          setProducts(response.data);
      }).catch((error) => console.error(error));
    }
  };

  useEffect(() => {
    handleSearch();
  }, [categories]);

  useEffect(() => {
    setLoading(true);
    axios.post('https://api.columbiacosmetics.com/api/products', {
      headers: {
        'X-Requested-with': 'XMLHttpRequest',
      },
      withCredentials: true,
      id: category,
    })
      .then((response) => {
        setLoading(false);
        setProducts(response.data);
        setPageTitle(response.data[0]?.category_name);
      })
      .catch((error) => console.error(error));
  }, [categories, category]);



  const bookList = Array.isArray(products) && products.map((product, index) => (
    <div className="products-item" data-aos="fade-up" key={index}>
      <div className="products-item__type">
        {product.product_badge_title ? (
          <span className="products-item__sale">
            {product.product_badge_title}
          </span>
        ) : ''}
      </div>
    
      <Link to={`${products[index]?.category_name ? `/`+slugify(products[index]?.category_name) : ''}${currentCatSlug}/${product.slug}`}>
        <div className="products-item__img">
          <img
            src={`https://admin.columbiacosmetics.com/storage/app/public/products/${product.product_image}`}
            className="js-img img-fluid"
            alt=""
          />
          <div className="products-item__hover"></div>
        </div>
      </Link>
      <div className="products-item__info">
      <Link to={`${products[index]?.category_name ? `/`+slugify(products[index]?.category_name) : ''}${currentCatSlug}/${product.slug}`}>
          <span className="products-item__name">
            {product.product_name}
          </span>
        </Link>
      </div>
    </div>
  ));

  const optionFunction = (selectBox) => {
    const selectedValue = selectBox.target.value;
    setLoading(true);
    axios.post('https://api.columbiacosmetics.com/api/products/filter', {
      headers: {
        'X-Requested-with': 'XMLHttpRequest',
      },
      withCredentials: true,
      id: selectedValue,
      category_id: category,
    })
      .then((response) => {
        setLoading(false);
        setProducts(response.data);
      })
      .catch((error) => console.error(error));
  };


  return (
    <div>
       <div className='detail-block detail-block_margin bread'>
        <div className='wrapper'>
          <div className='detail-block__content'>
            <h1>{categoryName}</h1>

              <ul className='bread-crumbs'>
                <li  className='bread-crumbs__item'>
                    <Link to={'/'} className='bread-crumbs__link'>
                      Home
                    </Link>
                  </li>
                  <li  className='bread-crumbs__item'>
                    <Link to={setCurrentCatSlug} className='bread-crumbs__link'>
                      {categoryName}
                    </Link>
                  </li>
              </ul>
          </div>
        </div>
      </div>
      <Helmet title={categoryName ? `${categoryName} | Columbia Cosmetics` : "Columbia Cosmetics"}>
      </Helmet>
      <div className="shop">
        <div className="wrapper">
          <div className="shop-content">
            <div className="shop-aside">
              <div className="box-field box-field__search">
                <SearchInput onSearch={handleSearch} />
              </div>
              <SideNav />
            </div>
            <div className="shop-main">
              <div className="shop-main__filter">
                <div className="shop-main__select">
                  <select
                    className="react-dropdown"
                    onChange={optionFunction}
                    id="sortBox"
                  >
                    <option value="0">Sort by</option>
                    <option value="1">Price Low to High</option>
                    <option value="2">Price High to Low</option>
                    <option value="3">Recent</option>
                    <option value="4">Alphabetical A-Z</option>
                    <option value="5">Alphabetical Z-A</option>
                  </select>
                </div>
              </div>
              <div className="shop-main__items" style={{ position: 'relative' }}>
                {loading && (
                  <div
                    style={{
                      position: 'absolute',
                      display: 'flex',
                      height: '-webkit-fill-available',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '-webkit-fill-available',
                      zIndex: '999',
                    }}
                    className="loading ovelay"
                  >
                    <img
                      style={{ width: '100px' }}
                      src="/assets/img/loading.gif"
                      alt="Loading"
                    />
                  </div>
                )}
                {bookList.length > 0 ? (
                  bookList
                ) : (
                  <div
                    className="container"
                    style={{ width: '100%', textAlign: 'center' }}
                    data-aos="fade-up"
                  >
                    <h4>Product not found</h4>
                  </div>
                )}
              </div>
              <div className="pagination-outer">
                <ul className="actives styled-pagination"></ul>
              </div>
            </div>
          </div>
        </div>
        <img
          className="promo-video__decor js-img"
          src="/assets/img/promo-video__decor.jpg"
          alt=""
        />
        <img
          className="shop-decor js-img"
          src="/assets/img/shop-decor.jpg"
          alt=""
        />
      </div>
    </div>
  );
};
