import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../utils/AuthUser";

export const ContactFrom = () => {
  const navigate = useNavigate();
  const { http } = AuthUser();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [privatelabel, setPrivatelabel] = useState("");
  const [contractmanufacturing, setContractManufacturing] = useState("");
  const [productdevelopment, setProductDevelopment] = useState("");
  const [generalinquiry, setGeneralInquiry] = useState("");
  const [hear, setHear] = useState("");
  const [message, setMessage] = useState("");

  const contact = async (e) => {
    e.preventDefault();
    try {
      const response = await http.post('/contactus', {
        headers: {
          "X-Requested-with": "XMLHttpRequest",
        },
        withCredentials: true,
        name: name,
        email: email,
        number: number,
        privatelabel: privatelabel,
        contractmanufacturing: contractmanufacturing,
        productdevelopment: productdevelopment,
        generalinquiry: generalinquiry,
        hear: hear,
        message: message
      });

      const data = response.data;
      if (!data?.error) {
        navigate('/thankyou');
      } else {
        alert(data?.error);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
      <div className='discount discount-contacts js-img' style={{ backgroundImage: `url('/assets/img/contactbg.jpg')` }}>
        <div className='wrapper'>
          <div className='discount-info'>
            <span className='saint-text'>GET IN TOUCH</span>
            <span className='main-text'>Send Us a Message</span>
            <p>
              In order to best serve your needs, please complete the information below and our
              dedicated team will contact you shortly. We look forward to connecting with you.
            </p>
            <form onSubmit={contact}>
              <div className='box-field'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter your name'
                  value={name}
                  onChange={e => setName(e.target.value)}
                  required
                />
              </div>
              <div className='box-field'>
                <input
                  type='email'
                  className='form-control'
                  placeholder='Enter your email'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className='box-field'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter your Number'
                  value={number}
                  onChange={e => setNumber(e.target.value)}
                  required
                />
              </div>
              <div className='box-field'>
                <h6><strong>Inquiry:</strong></h6>
                <div className="main-checks">
                  <div className="check">
                    <input
                      type="checkbox"
                      id="privatelabel"
                      value="Private Label"
                      className="styled-checkbox"
                      checked={privatelabel}
                      onChange={e => setPrivatelabel(e.target.checked)}
                    />
                    <label htmlFor="privatelabel">Private Label</label>
                  </div>
                  <div className="check check2">
                    <input
                      type="checkbox"
                      id="contractmanufacturing"
                      value="Contract Manufacturing"
                      className="styled-checkbox"
                      checked={contractmanufacturing}
                      onChange={e => setContractManufacturing(e.target.checked)}
                    />
                    <label htmlFor="contractmanufacturing">Contract Manufacturing</label>
                  </div>
                  <div className="check">
                    <input
                      type="checkbox"
                      id="productdevelopment"
                      value="Product Development"
                      className="styled-checkbox"
                      checked={productdevelopment}
                      onChange={e => setProductDevelopment(e.target.checked)}
                    />
                    <label htmlFor="productdevelopment">Product Development</label>
                  </div>
                  <div className="check">
                    <input
                      type="checkbox"
                      id="generalinquiry"
                      value="General Inquiry"
                      className="styled-checkbox"
                      checked={generalinquiry}
                      onChange={e => setGeneralInquiry(e.target.checked)}
                    />
                    <label htmlFor="generalinquiry">General Inquiry</label>
                  </div>
                </div>
              </div>
              <div className='box-field'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='How did you hear about us?'
                  value={hear}
                  onChange={e => setHear(e.target.value)}
                  required
                />
              </div>
              <div className='box-field box-field__textarea'>
                <textarea
                  className='form-control'
                  placeholder='Enter your message'
                  rows={7}
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>
              <button className='btn' type="submit">SEND MESSAGE</button>
              <div className="error"></div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
