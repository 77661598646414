import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for routing

const NavCol = ({ nav }) => {
  return (
    <div className='footer-nav__col'>
      <span className='footer-nav__col-title'>{nav.title}</span>
      <ul>
        {nav?.navLinks?.map((navLink, index) => (
          <li key={navLink.name + index}>
            <Link to={navLink.path}>
              {navLink.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavCol;
