import {Overview} from '../../components/OurFacility/Overview/Overview'
import {Slider} from '../../components/OurFacility/Slider/Slider'
import { Capabilities } from '../../components/OurFacility/Capabilities/Capabilities';
import { OurLocation } from '../../components/OurFacility/Ourlocation/Ourlocation';
import {Certification} from '../../components/shared/Certification/Certification'
import { Layout } from '../../layout/Layout';
import { Bread } from '../../components/OurFacility/Bread/Bread';
import { Work } from '../../components/OurFacility/Work/Work';
import ScrollToTop from "react-scroll-to-top";
import { Helmet } from 'react-helmet';
import {CcmiLabs} from '../../components/OurFacility/CcmiLabs/CcmiLabs'
import {CcmiLabsLocation} from '../../components/OurFacility/CcmiLabsLocation/CcmiLabsLocation'
import { Seo } from '../shared/Seo';

const breadcrumbsData = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Our Facility',
    path: '/ourfacility',
  },
];


export default function ourfacility() {
  return (
    <Layout>
      <Seo />
      <Bread /> 
      <Overview />
      <CcmiLabs />
      <Slider />
      <Capabilities />
      <OurLocation />
      <CcmiLabsLocation />
      <Work />
      <Certification />
      <ScrollToTop smooth />
    </Layout>
  )
}



