import { useState } from "react";
import { Link } from "react-router-dom";
import { header } from "../../data/data.header";

export const Thankyou = () => {
  return (
    <>
      {/* <!-- BEGIN DISCOUNT --> */}

      <div className="thankyou">
        <div className="container">
          <div className="thankyou-main" id="subscribed">
            <div className="thankyou-main-content">
              <h2>Thank You!</h2>
              <br></br>
              <p>
                Thank you for reaching out! Your message has been received. Our
                team will be in touch with you shortly. We appreciate your
                interest in connecting with us.
              </p>

              <p className="thankyou-contact">
                Please contact us if you have any questions or concerns.{" "}
              </p>

              <div className="thankyou-btn">
                <Link to="/contact" >
                  <a className="btn btn-primary mt-4 register-btn">
                    CONTACT US
                  </a>
                </Link>
                <br />
                <Link href="/" as="/">
                  <a className="btn btn-primary mt-4 register-btn">
                    BACK TO HOME
                  </a>
                </Link>
              </div>

              <img src="assets/img/thankyou-img.png" alt="" />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
