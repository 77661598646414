import React from "react";
import { Link } from "react-router-dom";

export const Products = () => {
  return (
    <>
      {/* <!-- BEGIN Info --> */}

      <div className="custom-info">
        <div className="container">
          <div className="row">
            <div className="column col-one">
              <img src="/assets/img/products.png" alt="" />
            </div>
            <div className="column">
              <span className="saint-text">
                World-class Formulas for Every Market we Serve
              </span>
              <h2>Our Products</h2>
              <p>
                Columbia Cosmetics continuously develops industry-leading
                products and formulas for every market we serve. We strive to
                create innovative products that shape and set trends within the
                beauty industry by continuously searching for novel ingredients
                and leveraging technology to exceed your expectations. With an
                extensive library of colors, products, and formulas, we can meet
                any of your needs.
              </p>
              <Link to="/ourproducts" className="btn btn-primary">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- INFO EOF   --> */}
    </>
  );
};
