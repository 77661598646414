import React from "react";

const Mission = () => {
  return (
    <>
      {/* <!-- BEGIN MISSION --> */}

      <div className="mission-sec">
        <div className="container">
          <div className="mission-cont">
            <h2>Our Mission</h2>
            <p> Creating innovative products that shape and set trends within the
              beauty industry by continuously searching for novel ingredients
              and leveraging craftsmanship to exceed your expectations.</p>
            <p className="quote">
              "Exceeding the customer expectations through innovation and technology."
            </p>
          </div>
        </div>
      </div>

      {/* <!-- MISSION EOF   --> */}
    </>
  );
};

export default Mission;
