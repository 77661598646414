import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

export default function Authentication() {
  const navigate = useNavigate();

  const getToken = () => {
    const tokenString = Cookies.get('token');
    const userToken = tokenString && JSON.parse(tokenString);
    return userToken;
  };

  const getUser = () => {
    const userString = Cookies.get('user');
    const user_detail = userString && JSON.parse(userString);
    return user_detail;
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());

  const saveToken = (userData, tokenData, previousPage) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 365); // set expiration time to 1 year from now

    Cookies.set('token', JSON.stringify(tokenData), { expires });
    Cookies.set('user', JSON.stringify(userData), { expires });

    setToken(tokenData);
    setUser(userData);

    navigate(previousPage);
  };

  const logout = () => {
    Cookies.remove('token');
    Cookies.remove('user');

    axios.post('https://api.columbiacosmetics.com/api/logout', {
      headers: {
        'X-Requested-with': 'XMLHttpRequest',
      },
      withCredentials: true,
    })
      .then((response) => {
        console.log(response);
        navigate('/login');
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else {
          return Promise.reject(error);
        }
      });
  };
  const url = 'https://api.columbiacosmetics.com/api/';
  // const url = 'https://api.columbiacosmetics.com/api/';
  const http = axios.create({
    baseURL: url,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    http.defaults.headers.Authorization = `Bearer ${token}`;
  }, [token]);

  return {
    setToken: saveToken,
    token,
    user,
    getToken,
    http,
    logout,
  };
}
