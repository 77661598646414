import { Link } from "react-router-dom";
export const Branding = () => {
  return (
    <>
      {/* <!-- BEGIN MAIN BLOCK --> */}

      <div className="branding" id="custom-branding-id">
        <div className="container">
          <div className="brand-head">
            <div className="row">
              <span className="saint-text">
                Crafted with Your Customers in Mind
              </span>
              <h2>Custom Branding</h2>
              <p>
                Columbia supplies the trend-setting high quality products, you
                supply your creativity and vision, and together we partner to
                develop your exclusive brand image. All of our elegantly
                packaged products and boxes can be easily branded with your
                logo. Whether you launch with a single signature item, or start
                with our full product range, Columbia is there with you every
                step of the way to help achieve your brand’s success.
              </p>
            </div>
          </div>
          <div className="brand-main">
            <div className="row">
              <div className="col" style={col1}>
                <img src="/assets/img/branding1.png" alt="" />
                <Link to="/contact" className="btn btn-primary">
                  Custom Label
                </Link>
              </div>
              <div className="col" style={col2}>
                <img src="/assets/img/branding2.png" alt="" />
                <Link to="/contact" className="btn btn-primary">
                  Custom Printing
                </Link>
              </div>
              <div className="col" style={col3}>
                <img src="/assets/img/branding3.png" alt="" />
                <Link to="/contact" className="btn btn-primary">
                  Custom Boxes
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- MAIN BLOCK EOF --> */}
    </>
  );
};

const col1 = {
  background: "#f9dfd4",
};

const col2 = {
  background: "#efcabd",
};

const col3 = {
  background: "#f4c7b3",
};
