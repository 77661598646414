import { useState } from "react";
import { useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";

export const Slider = () => {
  useEffect(() => {
    $(function () {
      var slideCount = $(".slider ul li").length;
      var slideWidth = $(".slider ul li").width();
      var slideHeight = $(".slider ul li").height();
      var slideUlWidth = slideCount * slideWidth;

      $(".slider").css({ "max-width": slideWidth, height: slideHeight });
      $(".slider ul").css({ width: slideUlWidth, "margin-left": -slideWidth });
      $(".slider ul li:last-child").prependTo($(".slider ul"));

      function moveLeft() {
        $(".slider ul")
          .stop()
          .animate(
            {
              left: +slideWidth,
            },
            700,
            function () {
              $(".slider ul li:last-child").prependTo($(".slider ul"));
              $(".slider ul").css("left", "");
            }
          );
      }

      function moveRight() {
        $(".slider ul")
          .stop()
          .animate(
            {
              left: -slideWidth,
            },
            700,
            function () {
              $(".slider ul li:first-child").appendTo($(".slider ul"));
              $(".slider ul").css("left", "");
            }
          );
      }

      $(".next").on("click", function () {
        moveRight();
      });

      $(".prev").on("click", function () {
        moveLeft();
      });
    });
  }, []);
  return (
    <>
      <div className="slider">
        <a href="#0" className="next control">
          <i className="fa-solid fa-angle-right fa-lg"></i>
        </a>
        <a href="#0" className="prev control">
          <i className="fa-solid fa-angle-left fa-lg"></i>
        </a>

        <ul>
          {/* <li className="c"> Slide 1 </li>
            <li className="r"> Slide 2 </li>
            <li className="g"> Slide 3 </li> */}

          

          <li className="c">
            <div className="shop-banner">
              <div className="shop-ban-cont">
                <span className="saint-text">START YOUR OWN</span>
                <h1>BEAUTY BRAND</h1>
                <Link href="/shop" className="btn">
                    SHOP PRIVATE LABEL COLLECTION
                </Link>
              </div>
            </div>
          </li>

          <li className="b">
            <div className="shop-banner">
              <div className="shop-ban-cont">
                <span className="saint-text">START YOUR OWN</span>
                <h1>BEAUTY BRAND</h1>
                <Link href="/shop" className="btn">
                    SHOP PRIVATE LABEL COLLECTION
                </Link>
              </div>
            </div>
          </li>
        </ul>

        <div className="slider_option">
          {/* <Button className="btn">Autoplay</Button> */}
        </div>
      </div>
    </>
  );
};
