import React from 'react';
import { Link } from "react-router-dom";

export const Bread = ({ breadcrumb, title, description }) => {

  return (
    <>
      {/* <!-- BEGIN DETAIL MAIN BLOCK --> */}
      <div className='detail-block detail-block_margin' id="product-dev" style={bg}>
        <div className='wrapper'>
          <div className='detail-block__content'>
            <h1>Product Development</h1>

            {breadcrumb && (
              <ul className='bread-crumbs'>
                {breadcrumb?.map(({ path, label }, i) => {
                  return (
                    <React.Fragment key={i}>
                      {path === window.location.pathname ? (
                        <li className='bread-crumbs__item'>{label}</li>
                      ) : (
                        <li className='bread-crumbs__item'>
                          <Link to={path} className='bread-crumbs__link'>{label}</Link>
                        </li>
                      )}
                    </React.Fragment>
                  );
                })}
              </ul>
            )}

            {/* IF NEED DESCRIPTION */}
            {description && <span className='error-descr'>{description}</span>}
          </div>
        </div>
      </div>
      {/* <!-- DETAIL MAIN BLOCK EOF   --> */}
    </>
  );
};

const bg = {
  "backgroundImage": "url(/assets/img/prod-devbg.png)"
};
