import { Layout } from '../layout/Layout';
import { Infothree } from '../components/Whitelabel/Infothree/Infothree';
import { Aboutus } from '../components/Whitelabel/Aboutus/Aboutus';
import { Started } from '../components/Whitelabel/Started/Started';
import { Bread } from '../components/Whitelabel/Bread/Bread';
import { Liquid } from '../components/Whitelabel/Liquid/Liquid';
import { Filling } from '../components/Whitelabel/Filling/Filling';
import React, { useEffect } from 'react';
import ScrollToTop from "react-scroll-to-top";
import { Helmet } from 'react-helmet';
import AOS from "aos";
import "aos/dist/aos.css";
import { Seo } from '../components/shared/Seo';


export default function Whitelabel() {

  AOS.init({ duration: 2000 });
  useEffect(() => {
  }, []);

  return (
    <Layout>
      <Seo />
      <Bread />
      <Infothree />
      <Aboutus />
      <Liquid />
      <Filling />
      <Started />
      <ScrollToTop smooth />
    </Layout>
  );
}
