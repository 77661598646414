import React from 'react';

export const Filling = () => {
  return (
    <>
      <div className="filling" id='fill-pack'>
        <div className="container">
          <div className="row">
            <div className="col">
              <img src="/assets/img/filling.jpeg" alt="" />
            </div>
            <div className="col" id="filling-col">
              <span data-aos="fade-right" className="saint-text">
                CAPABILITIES
              </span>
              <h2>FILLING & PACKAGING </h2>
              <p>
                We have a wide range of packaging equipment that can press
                powders, fill loose powders, fill bottles, tubes, pumps, boxes,
                gift sets, and more.
                Our versatile equipment, supported by a talented and creative
                team of mechanics, allowsus to fill just about any packaging
                option in the industry.
              </p>

              <ul className="points">
                <li>Auger Fillers</li>
                <li>Powder Presses</li>
                <li>Lipstick/ Lip Balm Filling Lines</li>
                <li>Automated Mascara Filling</li>
                <li>Hot Pour Piston Pump Fillers</li>
                <li>Conveyed Packaging Lines</li>
                <li>Piston Pump Single Head Fillers</li>
                <li>Tube Filling Machines, Hot Air – Cold Jaw</li>
                <li>Capper (cap tumbler, Applicator, Torquer)</li>
                <li>4 Head Volumetric Filler</li>
                <li>6 Head Volumetric Filler</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- INFO BLOCKS EOF   --> */}
    </>
  );
};
