import React from "react";
import { Link } from "react-router-dom";

export const Services = () => {
  return (
    <>
      {/* <!-- BEGIN MAIN BLOCK --> */}
      <section className="products-cat">
        <div className="skincare" id="pd-overview">
          <div className="container">
            <div className="content">
              <h2>
                PRODUCT <br /> DEVELOPMENT{" "}
              </h2>
              <p>
                Our clients work with our qualified chemist to tailor formulas{" "}
                <br /> that are specific to their needs. We also work with
                proprietary <br /> blends that are special to each client
              </p>
              <Link
                to="/services/productdevelopment"
                className="btn btn-primary"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>

        <div className="cbd-cat" id="cm-overview">
          <div className="container">
            <div className="content">
              <h2>
                CONTRACT <br /> MANUFACTURING
              </h2>
              <p>
                We offer Contract Manufacturing to clients to fill your existing{" "}
                <br /> formula into your desired packaging. The items are then{" "}
                <br /> shipped to your preferred location.
              </p>
              <Link to="/services/whitelabel" className="btn btn-primary">
                Learn More
              </Link>
            </div>
          </div>
        </div>

        <div className="otc" id="pl-overview">
          <div className="container">
            <div className="content">
              <h2>PRIVATE LABEL</h2>
              <p>
                We offer our existing formulas to clients that wish to create a{" "}
                <br /> product line of their own, using their own branding and
                packaging. <br /> As you start the process with our team, we
                will learn about your <br /> needs, goals and vision to assist
                in creating you product line.
              </p>
              <Link to="/services/privatelabel" className="btn btn-primary">
                Learn More
              </Link>
            </div>
          </div>
        </div>

        <Link to="https://www.mocraregistrar.com/" target="_blank">
          <div className="cbd-cat new-mocra-service" id="cm-overview">
            <div className="container">
              <div className="mocra-img">
                <img src="assets/img/5.png" alt="" />
              </div>
              <div className="content">
                <h2>
                  MoCRA <br /> COMPLIANCE
                </h2>
                <p>
                  We offer MoCRA facility registration and product listing with
                  the FDA.
                  <br /> Our team will handle the entire registration process
                  for you, saving you
                  <br /> time and resources. We also take care of the
                  annual/biennial renewals,
                  <br /> so you can have peace of mind knowing you stay
                  compliant with
                  <br /> MoCRA requirements.
                </p>
              </div>
            </div>
          </div>
        </Link>
      </section>

      {/* <!-- MAIN BLOCK EOF --> */}
    </>
  );
};
