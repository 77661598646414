import React from "react";
import certData from '../../../data/certification/certification';

export const Certification = () => {
  const certsData = [...certData];
  return (
    <>
      <div className='main-logos certs'>
        {certsData.map((cert, index) => (
            <img key={index} src={cert.logoSrc} className='js-img cert-img' alt='' />
        ))}
      </div>
    </>
  );
};
