import React, { useState } from "react";

function SearchInput({ onSearch }) {
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    onSearch(searchValue);
  };

  return (
    <form onSubmit={handleSearch} className="search-form">
      <div className="box-field box-field__search">
        <input type="search" value={searchValue} onChange={handleChange} className="form-control" placeholder="Search for products..." />
        <button type="submit" className="icon-search"></button>
      
      </div>
    </form>
  );
}

export default SearchInput;
