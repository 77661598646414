import React from "react";
import { Link } from "react-router-dom";

export const Facility = () => {
  return (
    <>
      {/* <!-- BEGIN Info --> */}

      <div className="facility">
        <div className="row">
          <div className="col fac-col-1">
            <div className="fac-cont">
              <span className="saint-text">Proudly Made in USA</span>
              <h2>Our Facility</h2>
              <p>
                Our flexible manufacturing facility gives us the capability to
                develop quality products consistently. Strategically located in
                San Francisco Bay Area, our 65,000 square ft manufacturing
                plant, houses our Research & Development Laboratory, QA/QC
                Development Laboratory, Regulatory Department, Corporate
                Offices, Art and Marketing Department, and our main Product
                Showroom.
              </p>
              <Link to="/ourfacility" className="btn btn-primary">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- INFO EOF   --> */}
    </>
  );
};
