import React from "react";
import { Link } from "react-router-dom";

export const Banner = () => {
  return (
    <>
      {/* <!-- BEGIN MAIN BLOCK --> */}
      <div className="journey-banner">
        <h3>Your journey begins here.</h3>
      </div>
      <div className="main-block load-bg" id="main-banner">
        <div className="container">
          <div className="row">
            <div className="col animate__animated animate__fadeInLeft col1">
              <h1>
                <span className="white">START</span> <br /> YOUR <br /> BRAND
              </h1>
              <Link to="/services/privatelabel" className="btn">
                PRIVATE LABEL
              </Link>
            </div>
            <div className="col animate__animated animate__fadeInRight col2">
              <h1>
                <span className="white">EXPAND</span> <br /> YOUR <br /> BRAND
              </h1>
              <Link to="/services/whitelabel" className="btn">
                CONTRACT MANUFACTURING
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
