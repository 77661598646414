import { useState } from 'react';
import { Link } from 'react-router-dom';
import { header } from "../../data/data.header";

export const Order = () => {
  return (
    <>
      {/* <!-- BEGIN DISCOUNT --> */}
      
      <div className="thankyou">
        <div className="container">
          <div className="thankyou-main" id='subscribed'>
            <div className="thankyou-main-content">
              <h2>Thank You!</h2>
              <p>Your Order Has Been Received through <strong> Cheque Payments.</strong></p>
              <p className='thankyou-contact'>Payment should be received within 5 days from receipt of Invoice. Checks should be payable to COLUMBIA COSMETICS MFG INC. and sent to 1661 Timothy Dr. San Leandro CA 94577. Order will be released once the check has been cleared.</p>


              <div className="thankyou-btn">
                <Link to="/contact" >
                  <a className='btn btn-primary mt-4 register-btn'>CONTACT US</a>
                </Link>
                <br />
                <Link to="/" as="/">
                  <a className='btn btn-primary mt-4 register-btn'>BACK TO HOME</a>
                </Link>
              </div>

              <img src="assets/img/thankyou-img.png" alt="" />

            </div>
          </div>
        </div>
      </div>

      {/* <!-- DISCOUNT EOF   --> */}
    </>
  );
};

