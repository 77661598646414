import { useState } from 'react';
import { Link } from 'react-router-dom';
import { header } from "../../data/data.header";

export const Ordered = () => {
  return (
    <>
      {/* <!-- BEGIN DISCOUNT --> */}
      
      <div className="thankyou">
        <div className="container">
          <div className="thankyou-main" id='subscribed'>
            <div className="thankyou-main-content">
              <h2>Thank You!</h2>
              <p>Your Order Has Been Received through <strong> Direct Bank Transfer.</strong></p>
              <p className='thankyou-contact'>Please contact us if you have any questions or concerns.</p>


                
                <h5>Our Bank Details:</h5>
                <div className="bank-info">
                    <div className="col">
                        <strong>Bank:</strong> Wells Fargo
                    </div>
                    <div className="col">
                        <strong>Account No:</strong> 0001138928
                    </div>
                    <div className="col">
                        <strong>Routing No:</strong> 121000248
                    </div>
                </div>


              <div className="thankyou-btn">
                <Link to="/contact" >
                  <a className='btn btn-primary mt-4 register-btn'>CONTACT US</a>
                </Link>
                <br />
                <Link href="/" as="/">
                  <a className='btn btn-primary mt-4 register-btn'>BACK TO HOME</a>
                </Link>
              </div>

              <img src="assets/img/thankyou-img.png" alt="" />

            </div>
          </div>
        </div>
      </div>

      {/* <!-- DISCOUNT EOF   --> */}
    </>
  );
};

