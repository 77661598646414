import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthUser from "../utils/AuthUser";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
export const Cart = () => {
  const navigate = useNavigate();
  const { http } = AuthUser();
  const [userdetail, setUserdetail] = useState("");
  const [sum, setSum] = useState(0);
  const [productCount, setproductCount] = useState(0);

  const [item, setitem] = useState("");
  const [cart, setCart] = useState("");
  const [products, setProduct] = useState([]);
  const [sluggedroducts, setSluggedroducts] = useState([]);
  const [mainCategories, setMainCategory] = useState([]);
  const [childCategories, setChildCategory] = useState([]);

  const productsMinQty = [233, 223, 224, 225, 226, 227, 230, 231, 232, 233, 235, 236, 237];
  
  const [error, setError] = useState("");
  
  useEffect(() => {
    http.post("https://api.columbiacosmetics.com/api/allproducts", {
        headers: {
          "X-Requested-with": "XMLHttpRequest",
        },
        withCredentials: true,
      })
      .then((response) => {
        setSluggedroducts(response?.data);
      })
      .catch((error) => console.error(error));
  }, []);


  useEffect(() => {
    http.get('https://api.columbiacosmetics.com/api/categories')
      .then((response) => {
        const data = response.data;
        setMainCategory(data?.parent);
        setChildCategory(data?.category_name);
      });

  }, []);



  useEffect(() => {
    http.post("/me").then(
      (res) => {
        setUserdetail(res.data);
        http
          .post("/getcart", {
            headers: {
              "X-Requested-with": "XMLHttpRequest",
            },
            withCredentials: true,
            client_id: res.data.id,
          })
          .then((response) => {
            const data = response.data;
            setitem(data);

            let totalPrice = 0;
            const cartitems = data?.cart;

            const allProductComponents = [];
            const productIds = cartitems.map((item) => item.product_id);
            const product_packaging = cartitems.map(
              (item) => item.product_packaging
            );
            const product_sku_no = cartitems.map((item) => item.product_sku_no);

            cartitems.forEach((element) => {
              const qty = element?.product_qty;
              const product_price = element?.product_price;

              const itemPrice = qty * product_price;
              totalPrice += itemPrice;
            });

            http.post("/get-cart-table-pricing", {
                headers: {
                  "X-Requested-with": "XMLHttpRequest",
                },
                withCredentials: true,
                id: productIds,
                product_sku_no: product_sku_no,
                product_packaging: product_packaging,
              })
              .then((response) => {
                setProduct(response.data);
              });

            setSum(totalPrice);
            setCart(cartitems);
          });
      },
      function (error) {
        if (401 === error.response.status) {
          navigate("/login");
        } else {
          return Promise.reject(error);
        }
      }
    );
  }, []);

  function checkout() {
    if (sum) {
      if (sum < 275) {
        setError(
          "Error: Minimum checkout page amount is $275. Please adjust your cart items accordingly"
        );
        return false;
      }
    }
    http
      .post("/product-status", {
        headers: {
          "X-Requested-with": "XMLHttpRequest",
        },
        withCredentials: true,
        cart: cart,
      })
      .then((response) => {
        const data = response.data;
        if (data?.message) {
          navigate("/payment");
        }
      });
  }

  useEffect(() => {
    const totalPrice = Array.isArray(cart)
      ? cart.reduce(
          (total, item) => total + item.product_price * item.product_qty,
          0
        )
      : 0;
    setSum(totalPrice);
  }, [sum, cart]);

  // console.log(products);
  async function removeCartItem(itemId) {
    try {
      const response = await fetch(
        `https://api.columbiacosmetics.com/api/removecart/${itemId}`,
        {
          method: "DELETE",
        }
      );
      const data = await response.json();
      if (data.success) {
        const updatedItems = cart.filter((item) => item.id !== itemId);
        setCart(updatedItems);
        const updatedSum = updatedItems.reduce(
          (acc, item) => acc + item.product_price * item.product_qty,
          0
        );
        setSum(updatedSum);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {/* <!-- BEGIN CART --> */}
      <div className="cart">
        <div className="wrapper">
          <div className="row">
            <div className="col1">
              <div className="cart-table">
                <div className="cart-table__box">
                  <div className="cart-table__row cart-table__row-head">
                    <div className="cart-table__col">Product</div>
                    <div className="cart-table__col">Price</div>
                    <div className="cart-table__col">Quantity</div>
                    <div className="cart-table__col">Total</div>
                  </div>

                  {Array.isArray(cart) &&
                    cart.map((evt, index) => (
                      <div key={index} className="cart-table__row">
                        <button
                          onClick={() => removeCartItem(evt.id)}
                          class="fa-solid fa-xmark"
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>

                        <div className="cart-table__col">
                          <Link to="#">
                            <a className="cart-table__img">
                              {evt?.swatch_image == "" ? (
                                <img
                                  src={
                                    "https://admin.columbiacosmetics.com/storage/app/public/products/" +
                                    evt?.product_image
                                  }
                                  className="js-img"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={
                                    "https://admin.columbiacosmetics.com/storage/app/public/products/swatches/" +
                                    evt?.swatch_image
                                  }
                                  className="js-img"
                                  alt=""
                                />
                              )}
                            </a>
                          </Link>
                          <div className="cart-table__info">
                            <Link to={`/product/${childCategories?.find((item) => item.id == sluggedroducts?.find((item) => item.id == evt.product_id)?.sub_category_id)?.category_slug}/${sluggedroducts?.find((item) => item.id == evt.product_id )?.slug}`}>
                              <a className="title5">{evt.product_name}</a>
                            </Link>

                            <p className="details-cart">
                              {evt.product_packaging}{" "}
                              {evt.product_packaging ? <br /> : ""}
                              {evt.product_color ? evt.product_color : ""}{" "}
                              {evt.product_color ? <br /> : ""}
                              {evt.product_sku_no}
                            </p>
                          </div>
                        </div>
                        <div className="cart-table__col">
                          <span className="cart-table__price">
                            ${evt.product_price}
                          </span>
                        </div>
                        <div className="cart-table__col">
                          <div className="cart-table__quantity">
                            <span className="cart-table__price">
                              <input
                                type="number"
                                className="productQty"
                                name="product_total"
                                value={evt.product_qty}
                                onChange={(e) => {
                                  let newQty = parseInt(e.target.value);
                                  if (newQty >= 0 && !isNaN(newQty)) {

                                    const updatedCart = [...cart];
                                    const updatedProducts = [...products];

                                    const updatedCartIndex = updatedCart.findIndex((item) => {
                                        const itemSku = item.product_sku_no?.replace("SKU NO: ", "");
                                        const evtSku = evt?.product_sku_no?.replace("SKU NO: ","");
                                        return itemSku === evtSku;
                                      });

                                    updatedCart[updatedCartIndex].product_qty = newQty;
                                   
                                    const rearrangedProducts = updatedCart.map((cartItem) => {
                                      const commonIndex = updatedProducts.findIndex((item) => {
                                        const itemSku = (item.sku ?? item.product_sku)?.replace("SKU NO: ", "");
                                        const cartItemSku = cartItem.product_sku_no?.replace("SKU NO: ", "");
                                        return itemSku === cartItemSku;
                                      });
                                      return commonIndex !== -1 ? updatedProducts[commonIndex] : cartItem;
                                    });

                                    const productId = rearrangedProducts[updatedCartIndex].id;
                             
                                    if (productsMinQty.includes(productId) && newQty > 0 && newQty < 12) {
                                        setError("Note: Quantity of this product has been set to 12 as it cannot be less than 12");
                                        return false;
                                      } else {
                                        setError("");
                                    }
                                  
                                  

                                    if (newQty > 0 && newQty <= 5) {
                                     
                                      if (updatedCart[index].product_sku_no === rearrangedProducts[updatedCartIndex].sku) {
                                        if (rearrangedProducts[updatedCartIndex].wp == 1) {
                                          let priceOne = rearrangedProducts[updatedCartIndex].product_price_one;

                                          updatedCart[index].product_price = parseFloat(priceOne);
                                        }else {
                                            let priceOne = rearrangedProducts[updatedCartIndex].price_one;
                                            updatedCart[index].product_price = parseFloat(priceOne);
                                        }
                                      
                                      }
                                    } else if (newQty >= 6 && newQty <= 11) {
                                      if (rearrangedProducts[updatedCartIndex].wp == 1) {
                                        updatedCart[index].product_price = rearrangedProducts[updatedCartIndex].product_price_two;
                                      } else {
                                        updatedCart[index].product_price = rearrangedProducts[updatedCartIndex].price_two;
                                      }
                                    } else if (newQty >= 12 && newQty <= 72) {
                                      if (rearrangedProducts[updatedCartIndex].wp == 1) {
                                        const price = parseFloat(rearrangedProducts[updatedCartIndex].product_price_three ?? rearrangedProducts[updatedCartIndex].price_three);
                                   
                                        updatedCart[index].product_price = parseFloat(price);
                                        
                                      } else {
                                        updatedCart[index].product_price = rearrangedProducts[updatedCartIndex].price_three;
                                      }
                                    } else if (newQty >= 73 && newQty <= 144) {
                                      if (rearrangedProducts[updatedCartIndex].wp == 1) {
                                        updatedCart[index].product_price = rearrangedProducts[updatedCartIndex].product_price_four ?? rearrangedProducts[updatedCartIndex].price_four;
                                      } else {
                                        updatedCart[index].product_price = rearrangedProducts[updatedCartIndex].price_four;
                                      }
                                    } else if (updatedCart[index].product_id == 22 || updatedCart[index].product_id == 23 || updatedCart[updatedCartIndex] .product_id == 26 ) {
                                      if (newQty > 145 && newQty < 500) {
                                        updatedCart[index].product_price =
                                          rearrangedProducts[
                                            updatedCartIndex
                                          ].price_five;
                                      } else if (
                                        newQty > 500 &&
                                        newQty < 1000
                                      ) {
                                        updatedCart[index].product_price =
                                          rearrangedProducts[
                                            updatedCartIndex
                                          ].price_six;
                                      } else if (newQty > 999) {
                                        return false;
                                      }
                                    } else {
                                      if (newQty > 144) {
                                        return false;
                                      }
                                    }

                                    setproductCount(newQty);
                                    setCart(updatedCart);
                                    setProduct(rearrangedProducts);

                                    http.post("/update-quantity", {
                                        cart_id: evt?.id,
                                        productId: evt.product_id,
                                        quantity: newQty,
                                        packaging : evt?.product_packaging,
                                        price: updatedCart[updatedCartIndex].product_price,
                                      })
                                      .then((response) => {})
                                      .catch((error) => {
                                        console.log(error);
                                      });
                                  }
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="cart-table__col">
                          <span className="cart-table__total">
                            $
                            {(
                              parseFloat(evt.product_price) *
                              parseFloat(evt.product_qty)
                            ).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="col2">
              <div className="cart-bottom">
                <div className="cart-bottom__total">
                  <div className="cart-bottom__total-goods">
                    Subtotal
                    <span>${sum.toFixed(2)}</span>
                  </div>
                  <div className="cart-bottom__total-promo">
                    Shipping
                    <span>$0.00</span>
                  </div>
                  <div className="cart-bottom__total-num">
                    total:
                    <span>${sum.toFixed(2)}</span>
                  </div>
                  <Link to="javascript:void(0)">
                    <a
                      className="btn"
                      style={{ "margin-botttom": "12px" }}
                      onClick={checkout}
                    >
                      Checkout
                    </a>
                  </Link>
                  <small
                    className="fedex_error"
                    style={{
                      color: "red",
                      "line-height": "25px",
                      "font-style": "italic",
                    }}
                  >
                    {error}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="promo-video__decor js-img"
          src="assets/img/promo-video__decor.jpg"
          alt=""
        />
      </div>
      {/* <!-- CART EOF   --> */}
    </>
  );
};
