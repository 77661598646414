import React from 'react';

export const Infothree = () => {
  return (
    <>
      <div className="info-blocks info-blocks-video info-blocks-video2 info-blocks-video3 turnkey over white-capabilities">
        <div className="info-blocks__item info-blocks__item-reverse js-img">
          <div className="wrapper">
            <div className="info-blocks__item-img">
              <img src="/assets/img/contract1.jpg" alt="" />
            </div>
            <div className="info-blocks__item-text">
              <span data-aos="fade-right" className="saint-text">
                Cosmetics & Skincare Manufacturing
              </span>
              <h2>Capabilities</h2>
              <p>
                Our flexible manufacturing facility gives us the capability to
                develop quality products consistently.
              </p>
              <ul className="points">
                <li>Custom Formulations & Manufacturing</li>
                <li>Lipstick Molding, Powder Pressing, Liquid, Cream & Tube Filling </li>
                <li>Natural, Botanical, & Vegan Ability</li>
                <li>In-house R&D Team</li>
                <li>Total Customer Confidentiality</li>
                <li>Strict Quality Control</li>
                <li>Packaging & Component Sourcing</li>
                <li>Many Formulas to Choose From</li>
                <li>Complete Turnkey Operations</li>
                <li>GMP, OTC Facility</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- INFO BLOCKS EOF   --> */}
    </>
  );
};
