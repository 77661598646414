import React from 'react';

const Contact = () => {
  return (
    <>
      <div className="help private-help">
        <div className="container">
            <div className="row">
                <h2>Let's Work Together</h2>
                <p>Great ideas deserve to be turned into quality products. <br /> Let’s discuss how we can support you.</p>
                <a href='/contact' className="btn btn-primary">Contact Us</a>
            </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
