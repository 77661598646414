import React, { useState } from "react";
import axios from "axios";

export const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [newsError, setNewsError] = useState("");
  const [newsSuccess, setNewsSuccess] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    console.log(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get("https://api.columbiacosmetics.com/api/newsletter/" + email);
      const data = response.data;
      if (data?.message === "Email already exists") {
        setNewsError(data?.message);
        setNewsSuccess("");
      } else {
        setNewsError("");
        setNewsSuccess(
          "Thank you for subscribing! You will be the first to know about our products launches, exclusive sales, and more."
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <!-- BEGIN SUBSCRIBE --> */}
      <div className="subscribe">
        <div className="wrapper">
          <div className="subscribe-form">
            <div className="subscribe-form__img">
              <img src="/assets/img/news.jpg" className="js-img" alt="" />
            </div>
            <form onSubmit={handleSubmit}>
              <h3>Stay In Touch</h3>
              <p>
                Be the first to hear about product launches, exclusive sales,
                and more.
              </p>
              <div className="box-field">
                <div className="box-field__row">
                  <div className="box-field">
                    <input
                      type="email"
                      required
                      className="form-control"
                      placeholder="Enter your email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </div>
                  <button type="submit" className="btn">
                    Subscribe
                  </button>
                </div>
                <p className="newsletter-error">{newsError}</p>
                <p className="newsletter-success">{newsSuccess}</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
