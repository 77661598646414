import Overview from '../components/Ourproducts/Overview/Overview'
import Bread from '../components/Ourproducts/Bread/Bread'
import Products from '../components/Ourproducts/Products/Products'
import { Layout } from '../layout/Layout';
import { Work } from '../components/Ourproducts/Work/Work';
import ScrollToTop from "react-scroll-to-top";
import { Seo } from '../components/shared/Seo';

export default function Ourproducts() {
  return (
    <Layout>
      <Seo />
      <Bread />
      <Overview />
      <Products />
      <Work />
      <ScrollToTop smooth />
    </Layout>
  );
}



