export const CcmiLabsLocation = () => {
  return (
    <>
      
      <div className="labs-header">
        <img src="assets/img/labs-logo.png" alt="" />
        <p>19845 Nordhoff St, Northridge, CA 91324</p>
      </div>
    
      <div>
        <iframe
          width="100%"
          height="650"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=19845%20Nordhoff%20St,%20Northridge,%20CA%2091324+(Your%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.gps.ie/sport-gps/">swimming watch</a>
        </iframe>
      </div>
    </>
  );
};
