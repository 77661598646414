import { PublicLayout } from '../layout/PublicLayout';
import {Terms} from '../components/Terms/Terms'
import ScrollToTop from "react-scroll-to-top";
import { Seo } from '../components/shared/Seo';


const breadcrumbsData = [
    {
      label: 'Home',
      path: '/',
    },
    {
      label: 'Terms & Conditions',
      path: '/terms',
    },
  ];

export default function Guideorder() {
  return (
    <PublicLayout breadcrumb={breadcrumbsData} breadcrumbTitle='Terms & Conditions'>
      <Seo />
      <Terms />
      <ScrollToTop smooth />
    </PublicLayout>
  );
}
