import React from 'react';

export const Gallery = () => {
    return (
        <>
            <div className="gallery">
                <span className="saint-text">FOLLOW US</span>
                <a href="https://www.instagram.com/columbiacosmeticsmfg/" target='_blank' rel="noopener noreferrer">
                    <h2>@columbiacosmeticsmfg</h2>
                </a>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <a href="https://www.instagram.com/columbiacosmeticsmfg/" target='_blank' rel="noopener noreferrer">
                                <img src="assets/img/gallery/gallery-img-1.png" alt="" />
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://www.instagram.com/columbiacosmeticsmfg/" target='_blank' rel="noopener noreferrer">
                                <img src="assets/img/gallery/gallery-img-2.png" alt="" />
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://www.instagram.com/columbiacosmeticsmfg/" target='_blank' rel="noopener noreferrer">
                                <img src="assets/img/gallery/gallery-img-3.png" alt="" />
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://www.instagram.com/columbiacosmeticsmfg/" target='_blank' rel="noopener noreferrer">
                                <img src="assets/img/gallery/gallery-img-4.png" alt="" />
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://www.instagram.com/columbiacosmeticsmfg/" target='_blank' rel="noopener noreferrer">
                                <img src="assets/img/gallery/gallery-img-5.png" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
