import { useState } from 'react';
import { Link } from 'react-router-dom';
import { header } from "../../data/data.header";

export const OrderSuccess = () => {
  return (
    <>
      {/* <!-- BEGIN DISCOUNT --> */}
      
      <div className="thankyou">
        <div className="container">
          <div className="thankyou-main" id='subscribed'>
            <div className="thankyou-main-content">
              <h2>Thank You!</h2>
              <p>Your Order Has Been Received. </p><br></br>
              <p>Before we can process your order, please have your Order Confirmation Number ready and call (800) 824-3328 to confirm your credit card details. Your Order Confirmation Number will be sent via email. </p>
              <p>Our business hours are 8:00 AM to 5:00 PM PST Monday through Friday.</p>
              
              <p className='thankyou-contact'>Please contact us if you have any questions or concerns. </p>

              <div className="thankyou-btn">
                <Link to="/contact" >
                  <a className='btn btn-primary mt-4 register-btn'>CONTACT US</a>
                </Link>
                <br />
                <Link to="/" as="/">
                  <a className='btn btn-primary mt-4 register-btn'>BACK TO HOME</a>
                </Link>
              </div>

              <img src="assets/img/thankyou-img.png" alt="" />

            </div>
          </div>
        </div>
      </div>

      {/* <!-- DISCOUNT EOF   --> */}
    </>
  );
};

