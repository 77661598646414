import React from "react";

function Products() {
  return (
    <section className="products-cat">
      <a href="/skincare">
        <div className="skincare">
          <div className="container">
            <div className="content">
              <h2>SKINCARE</h2>
              <p>
                Cleansers | Dry & Sensitive Skin Care | Face Creams | Lip Care |
                <br />
                makeup Remover | Masques | Moisturizers | Scrubs | Serums |{" "}
                <br />
                Toners | Men's Skincare
              </p>
            </div>
          </div>
        </div>
      </a>

      <a href="/make-up">
        <div className="makeup">
          <div className="container">
            <div className="content">
              <h2>MAKEUP</h2>
              <p>
                Blush | Bronzers | Concealers & Correctors | Eyeliners | <br />
                Eyeshadows | Face Powders | Foundations | Highlighters | <br /> Lip
                Glosses | Lip Pencils | Lipsticks | Mascaras | Primers & <br />
                Setting Spray | Tinted Moisturizers
              </p>
            </div>
          </div>
        </div>
      </a>

      <a href="#">
        <div className="organic">
          <div className="container">
            <div className="content">
              <h2>ORGANIC</h2>
              <p>Skincare | Body Care | Hair Care | Cosmetics</p>
            </div>
          </div>
        </div>
      </a>

      <a href="#">
        <div className="otc">
          <div className="container">
            <div className="content">
              <h2>OTC</h2>
              <p>
                Sunscreens | Tinted Sunscreens | Hand Sanitizer | 
                <br /> Acne | Anti-Bacterial
              </p>
            </div>
          </div>
        </div>
      </a>

      <a href="/cbd">
        <div className="cbd-cat">
          <div className="container">
            <div className="content">
              <h2>CBD</h2>
              <p>
                Foundation Sticks | Lip Glosses | Lipsticks | Body Care |
                Moisturizers | <br /> Cleansers | Toners | Lip Care | Face Creams
                | Serums | Primers
              </p>
            </div>
          </div>
        </div>
      </a>
    </section>
  );
}

export default Products;
