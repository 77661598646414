import { Subscribe } from '../components/shared/Subscribe/Subscribe';
import Catalogs from '../components/Catalogs/Catalogs';
import { PublicLayout } from '../layout/PublicLayout';
import { Certs } from '../components/About/Certs/Certs';
import ScrollToTop from "react-scroll-to-top";
import { Seo } from '../components/shared/Seo';

const breadcrumbsData = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Catalogs',
    path: '/catalogs',
  },
];
const CatalogsPage = () => {
  return (
    <PublicLayout breadcrumb={breadcrumbsData} breadcrumbTitle='Catalogs'>
      <Seo />
      <Catalogs />
      <Subscribe />
      <ScrollToTop smooth />
    </PublicLayout>
  );
};

export default CatalogsPage;
