import React from "react";
export const Support = () => {
  return (
    <>
      
      <div className="support">
        <div className="container">
          <div className="support-head">
            <span className="saint-text">Deformulation & Reformulation</span>
            <h2>Product Support</h2>
            <p>
              We can troubleshoot your existing formulation and deliver a
              cost-effective solution to modify it.
            </p>
          </div>
          <div className="support-main">
            
            <p>PRODUCT DEFORMULATION & REFORMULATION</p>
            <ul className="points">
              <li>
                Reverse engineer, replicate, and modify existing products.
              </li>
              <li>
                We have helped customers expand their product lines through
                deformulation and reformulation services on existing products.
                This saves time as well as costs as reverse engineers a
                competing product that you like, in order to have it
                reformulated adding
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* <!-- SUPPORT BLOCK EOF --> */}
    </>
  );
};
