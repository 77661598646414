import { Layout } from './Layout';

export const ShopLayout = ({
  children,
}) => {
  return (
    <Layout>
      <>{children}</>
    </Layout>
  );
};
