import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Nav from "./Nav/Nav";
import { header } from "../../../data/data.header";
import AuthUser from "../../utils/AuthUser";
import { navItem } from "../../../data/data.header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import { Helmet } from "react-helmet";
import { useUser } from "../../../Contexts/LoginContext";
import { useDynamicNav } from "../../../Contexts/DynamicNav";
export const Header = () => {
  const [promo, setPromo] = useState(true);
  const [fixedNav, setFixedNav] = useState(false);
  const { token, logout } = AuthUser();
  const { http } = AuthUser();
  const [count, setCount] = useState("");

  const { userDetail, cartItems } = useUser();
  const { dynamicnav } = useDynamicNav();

  const logoutUser = () => {
    if (token != undefined) {
      logout();
    }
  };

  useEffect(() => {
    if (userDetail) {
      $(".logout-buttons").css("display", "flex");
      $(".logout-buttons").css("visibility", "visible");
      $(".login-buttons").css("display", "none");
    } else {
      $(".logout-buttons").css("display", "none");
      $(".login-buttons").css("display", "flex");
    }
  }, [token, userDetail]);

  // console.log(item);
  useEffect(() => {
    setCount(cartItems?.cart?.length);
  }, [cartItems]);

  // For Fixed nav
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  // Search
  useEffect(() => {
    $('a[href="#search"]').on("click", function (event) {
      $("#search").addClass("open");
      $('#search > form > input[type="search"]').focus();
    });
    $("#search, #search button.close").on("click keyup", function (event) {
      if (
        event.target == this ||
        event.target.className == "close" ||
        event.keyCode == 27
      ) {
        $(this).removeClass("open");
      }
    });
  }, []);

  // menu button main
  useEffect(() => {
    $(".js-btn-menu").click(function () {
      $(".mobile-left-menu").addClass("act-head");
    });

    $(".mobile-left-menu-top svg").click(function () {
      $(".mobile-left-menu").removeClass("act-head");
    });
  }, []);

  useEffect(() => {
    $("#services-link").click(function () {
      $(".mobile-left-menu-services").addClass("act-head");
    });

    $(".back-link").click(function () {
      $(".mobile-left-menu-services").removeClass("act-head");
    });
  }, []);

  // menu button shop
  useEffect(() => {
    $("#shop-link").click(function () {
      $(".mobile-left-menu-shop").addClass("act-head");
    });

    $(".back-link").click(function () {
      $(".mobile-left-menu-shop").removeClass("act-head");
    });
  }, []);

  const openMobileNav = (navSlug) => {
    const navLink = document.querySelector(`#${navSlug}-link`);
    const backLink = document.querySelector(`.back-link-${navSlug}`);
    const mobileMenu = document.querySelector(`.mobile-left-menu-${navSlug}`);

    if (navLink) {
      navLink.addEventListener("click", () => {
        if (mobileMenu) {
          mobileMenu.classList.add("act-head");
        }
      });
    }

    if (backLink) {
      backLink.addEventListener("click", () => {
        if (mobileMenu) {
          mobileMenu.classList.remove("act-head");
        }
      });
    }

    console.log(mobileMenu);
  };

  useEffect(() => {
    // Setup event listeners for all dynamicNav items
    if (Array.isArray(dynamicnav)) {
      dynamicnav.forEach((category) => {
        const navSlug = category?.main_category?.link;
        if (navSlug) {
          openMobileNav(navSlug);
        }
      });
    }
  }, [dynamicnav]);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 10) {
      setFixedNav(true);
    } else {
      setFixedNav(false);
    }
  };

  return (
    <>
      {/* <!-- BEGIN HEADER --> */}
      <header className="header">
        {promo && (
          <div className="header-top">
            <Link to="/" as="/">
              <img src={header.logo} alt="" />
            </Link>
          </div>
        )}

        <div className="mobile-header-bar">
          <div className="mobile-header-bar-items">
            <div className="js-btn-menu btn-menu">
              <FontAwesomeIcon icon={faBars} />
            </div>
            <div className="mobile-main-menu"></div>
          </div>
        </div>

        {/* MAIN MOBILE LEFT MENU */}

        <div className="mobile-left-menu">
          <div className="mobile-left-menu-top">
            <FontAwesomeIcon icon={faX} />
            <h5>Your journey begins here.</h5>
          </div>

          <form className="search-form">
            <input type="text" className="form-control" placeholder="Search" />
          </form>

          <div className="main-menu-mobile">
            <ul>
              <div className="logout-buttons">
                <li
                  className="brown-menu"
                  id="signin-link"
                  style={{ flex: "6%" }}
                >
                  <Link to="#" className="log-button" onClick={() => logoutUser()}>
                    Logout /
                  </Link>
                </li>
                <li
                  className="brown-menu"
                  id="signin-link"
                  style={{ flex: "50%" }}
                >
                  <Link className="log-button" to="/dashboard">Dashboard</Link>
                </li>
              </div>

              <div className="login-buttons">
                <li
                  className="brown-menu"
                  id="signin-link"
                  style={{ flex: "22%" }}
                >
                  <i className="fa-regular fa-user"></i>
                  <Link className="log-button" to="/login">Sign In </Link>
                </li>
                <li
                  className="brown-menu"
                  id="signin-link"
                  style={{ flex: "50%" }}
                >
                  <Link className="log-button" to="/register">Register</Link>
                </li>
              </div>

              <li>
                <Link to="/">HOME</Link>
              </li>

              <li id="services-link">
                <Link to="#">OUR SERVICES</Link>
                <i className="fa-solid fa-angle-right"></i>
              </li>

              <li>
                <Link to="/ourproducts" as="/ourproducts">
                  OUR PRODUCTS
                </Link>
                <i className="fa-solid fa-angle-right"></i>
              </li>

              <li>
                <Link to="/ourfacility" as="/ourfacility">
                  OUR FACILITY
                </Link>
              </li>

              <li className="brown-menu" id="shop-link">
                <Link to="#">SHOP</Link>
                <i className="fa-solid fa-angle-right"></i>
              </li>

              <li>
                <Link to="/catalogs" as="/catalogs">
                  Catalogs
                </Link>
              </li>

              <li>
                <Link to="/about" as="/about">
                  ABOUT US
                </Link>
              </li>

              <li>
                <Link to="/contact">CONTACT</Link>
              </li>
            </ul>
          </div>
          <div className="mobile-address">
            <p>
              1661 Timothy Drive <br /> San Leandro, CA 94577
            </p>
          </div>

          <div className="mobile-contact">
            <p>Tel: (800) 824-3328</p>
            <p>Fax: (510) 562-5900</p>
            <p>sales@columbiacosmetics.com</p>
          </div>
        </div>

        <div className="mobile-left-menu-services">
          <div className="main-menu-mobile">
            <ul>
              <li className="back-link brown-menu">
                <Link to="/">BACK</Link>
                <i className="fa-solid fa-angle-left"></i>
              </li>

              <li>
                <Link to="/services/productdevelopment">
                  PRODUCT DEVELOPMENT
                </Link>
                <i className="fa-solid fa-angle-right"></i>
              </li>

              <li>
                <Link to="/services/whitelabel">CONTRACT MANUFACTURING</Link>
                <i className="fa-solid fa-angle-right"></i>
              </li>
              <li>
                <Link to="/services/privatelabel">PRIVATE LABEL</Link>
                <i className="fa-solid fa-angle-right"></i>
              </li>
              <li>
                <Link to="https://www.mocraregistrar.com/">
                  MoCRA COMPLIANCE
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mobile-left-menu-shop">
          <div className="main-menu-mobile">
            <ul>
              <li className="back-link brown-menu">
                <Link to="/">BACK</Link>
                <i className="fa-solid fa-angle-left"></i>
              </li>
              {Array.isArray(dynamicnav) &&
                dynamicnav.map((category, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() =>
                        openMobileNav(category?.main_category?.link)
                      }
                      id={`${category?.main_category?.link}-link`}
                    >
                      <Link to="#">
                        {category?.main_category.category_name}
                      </Link>
                      <i className="fa-solid fa-angle-right"></i>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>

        {Array.isArray(dynamicnav) &&
          dynamicnav.map((category, index) => (
            <div
              key={index} // Add a key here for the outer map
              className={`mobile-left-menu-inner mobile-left-menu-${category?.main_category?.link}`}
            >
              <div className="main-menu-mobile">
                <ul>
                  <li
                    key={`back-link-${category?.main_category?.link}`} // Add a unique key here
                    className={`back-link-${category?.main_category?.link} brown-menu`}
                  >
                    <Link to="/">BACK</Link>
                    <i className="fa-solid fa-angle-left"></i>
                  </li>
                  {category?.child_categories &&
                    category.child_categories.map((subcategory, subIndex) => (
                      <li
                        key={subcategory.category_slug} // Add a unique key here for subcategories
                      >
                        <Link to={`/${subcategory.category_slug}`}>
                          {subcategory.sub_category_name}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          ))}

        <div
          className={`header-content ${fixedNav ? "fixed" : ""}`}
          style={styles1}
        >
          <div className="header-logo"></div>
          <div className="header-box">
            <i className="fa-solid fa-xmark"></i>
            {/* Nav */}
            <Nav navItem={navItem} />
            {/* header options */}

            <div className="logout-buttons">
              <li>
                <a onClick={() => logoutUser()}>Logout /</a>
              </li>
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
            </div>

            <div className="login-buttons">
              <li>
                <Link to="/login">Sign In /</Link>
              </li>
              <li>
                <Link to="/register">Register</Link>
              </li>
            </div>

            <ul className="header-options">
              <li>
                <Link to="/cart" as={"/cart"}>
                  <i className="icon-cart"></i>
                  <span>{count ?? "0"}</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="btn-menu js-btn-menu">
            <FontAwesomeIcon icon={faBars} />
          </div>
        </div>
      </header>

      {/* <!-- HEADER EOF   --> */}
    </>
  );
};

const styles1 = {
  background: "linear-gradient(27deg, #f4e9d7, #ecdecb)",
  display: "flex",
  alignItems: "flex-start",
};

const logo = {
  marginTop: "-11px",
};

export default Header;
