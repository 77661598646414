import React from 'react'
import { Layout } from '../layout/Layout';
import Reset from '../components/reset/Reset';

export default function register() {   
  return (

    <Layout>
      <Reset />
    </Layout>
  )
}