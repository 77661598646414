import { Banner } from '../components/landing/Banner/Banner';
import { Info } from '../components/landing/Info/Info';
import { Subscribe } from '../components/shared/Subscribe/Subscribe';
import { Development } from '../components/landing/Developement/Developement';
import { Turnkey } from '../components/landing/Turnkey/Turnkey';
import { Products } from '../components/landing/Products/Products';
import { Facility } from '../components/landing/Facility/Facility';
import { Certification } from '../components/shared/Certification/Certification';
import { Video } from '../components/landing/Video/Video';
import { Mocra } from '../components/landing/Mocra/Mocra';
import { Layout } from '../layout/Layout';
import { Seo } from '../components/shared/Seo';
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";


export default function Home() {

  AOS.init({ duration: 2000 });
  useEffect(() => {
  }, []);

  return (
    <div>

      <Layout>
        <Seo />
        <Banner />
        <Turnkey />
        <Video />
        <Products />
        <Facility />
        <Development />
        <Info />
        <Mocra />
        <Subscribe />
        <Certification />
      </Layout>

    </div>
  );
}
