export const OurLocation = () => {
  return (
    <>
      <div className="our-location-capability">
        <div className="container">
          <div className="location-content">
            <h2>Our Location</h2>
            <p>
              Strategically located 2.5 miles from Oakland International Airport
              <br />
              in the San Francisco Bay Area, where many prestige beauty brands
              are headquartered.
            </p>
            <img src="assets/img/facility/map1.png" alt="location img" />
            <p className="location-para">1661 Timothy Dr, San Leandro, CA 94577, USA</p>
            <p className="location-para">1771 Timothy Dr, San Leandro, CA, 94577, USA</p>
          </div>
        </div>
      </div>

      {/* <div className="maps">
        <div className="container">
          <div className="row">
            <div className="col col1">
              <img src="assets/img/facility/map2.png" alt="" />
              <p>1661 Timothy Drive, San Leandro, CA, 94577, US</p>
            </div>

            <div className="col">
              <img src="assets/img/facility/map3.png" alt="" />
              <p>1771 Timothy Drive, San Leandro, CA, 94577, US</p>
            </div>
          </div>
        </div>
      </div> */}

      <div>
        <iframe
          width="100%"
          height="650"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1661%20Timothy%20Drive,%20San%20Leandro,%20CA,%2094577,%20US+(Your%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.gps.ie/sport-gps/">swimming watch</a>
        </iframe>
      </div>
    </>
  );
};
