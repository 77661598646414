import { Order } from '../components/Thankyou/order';
import React from 'react';
import { Layout } from '../layout/Layout';
import ReactDOM from 'react-dom';

export default function Privatelabel() {
  return (
      <Layout>
        <Order />
      </Layout>
  );
}



