// import Carousel from 'carousel-react-rcdev'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export const Slider = () => {
  return (
    <>
      {/* <div className="facility-gallery">
        <div className="container">
          <div className="row">
            <div className="col" id="col1">
              <img src="assets/img/facility/1.jpg" alt="" />
            </div>
            <div className="col">
              <img src="assets/img/facility/4.jpg" alt="" />
            </div>
            <div className="col">
              <img src="assets/img/facility/5.jpg" alt="" />
            </div>
            <div className="col">
              <img src="assets/img/facility/6.jpg" alt="" />
            </div>
            <div className="col">
              <img src="assets/img/facility/3.png" alt="" />
            </div>
            <div className="col">
              <img src="assets/img/facility/7.jpg" alt="" />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};


