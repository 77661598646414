import React from 'react';

export const Bread = ({ breadcrumb, title, description }) => {

  return (
    <>
      {/* <!-- BEGIN DETAIL MAIN BLOCK --> */}
      <div className='detail-block detail-block_margin' id="product-dev" style={bg}>
        <div className='wrapper'>
          <div className='detail-block__content'>
            <h1>Our Facility</h1>

            {breadcrumb && (
              <ul className='bread-crumbs'>
                {breadcrumb?.map(({ path, label }, i) => {
                  return (
                    <React.Fragment key={i}>
                      <li className='bread-crumbs__item'>
                        <a className='bread-crumbs__link' href={path}>{label}</a>
                      </li>
                    </React.Fragment>
                  );
                })}
              </ul>
            )}

            {/* IF NEED DESCRIPTION */}
            {description && <span className='error-descr'>{description}</span>}
          </div>
        </div>
      </div>
      {/* <!-- DETAIL MAIN BLOCK EOF   --> */}
    </>
  );
};

const bg = {
  "background" : "#f2f2f2",
  "backgroundPositionY": "-490px"
};
