import React, { createContext, useContext, useEffect, useState } from 'react';
import AuthUser from "../components/utils/AuthUser";

const NavContext = createContext();

export const NavProvider = ({ children }) => {
    const [dynamicnav, setDynamicNav] = useState([]);
    const { http } = AuthUser();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.post("/dynamicnav", {
                    headers: { "X-Requested-With": "XMLHttpRequest" },
                    withCredentials: true,
                });
                setDynamicNav(response?.data?.categories || []);
            } catch (error) {
                if (error?.response?.status === 401) {
                    console.log("Not Logged in");
                } else {
                    console.log("Unhandled error:", error);
                }
            }
        };

        fetchData();
    }, []);

    return (
        <NavContext.Provider value={{ dynamicnav }}>
            {children}
        </NavContext.Provider>
    );
};

export const useDynamicNav = () => useContext(NavContext);
