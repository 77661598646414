import React from 'react';

export const AboutDetailBlock = () => {

  return (
    <>

      <div className="about-main-section">
        <div className="row">
          <div className="col" id='about-col-1'></div>
          <div className="col" id='about-col-2'>
            <div className="about-content">
              <p>
                Founded 1978 in the San Francisco Bay Area, Columbia Cosmetics has grown into one of the largest private label cosmetic manufacturers in North America. We offer a full line of stock color cosmetics and cutting-edge skin care products as well as complete custom formulation services.
              </p>
              <p>
                Columbia Cosmetics Manufacturing Inc. is located in a modern, state-of-the-art, 65,000 square foot facility, which houses our Research and Development Laboratory, QA/QC Laboratory, Corporate Offices, Art Department and our main product showroom.
              </p>
              <p>
              Our private label and custom formulas can be found in over 170 different countries. Our clientele includes internationally recognized prestigious cosmetic retailers, beauty salons and spas, upscale boutiques, and color and image consultants. Many of our products have been featured in high end magazines, used on blockbuster movie sets and runways, and have won numerous awards.
              </p>
            </div>
          </div>

        </div>
      </div>

    </>
  );
};
