import { Bread } from '../components/About/Bread/Bread';
import { AboutPromo } from '../components/About/AboutPromo/AboutPromo';
import { AboutDetailBlock } from '../components/About/AboutDetailBlock/AboutDetailBlock';
import { Certification } from '../components/shared/Certification/Certification';
import { Certs } from '../components/About/Certs/Certs';
import Mission from '../components/About/Mission/Mission';
// import OurPromise from '../components/About/Promise/Promise'; 
import Corporate from '../components/About/Corporate/Corporate';
import Contact from '../components/Privatelabel/Contact/Contact';
import { Layout } from '../layout/Layout';
import ScrollToTop from "react-scroll-to-top";
import { Helmet } from 'react-helmet';
import { Seo } from '../components/shared/Seo';

const breadcrumbsData = [{ label: 'Home', path: '/', }, { label: 'About', path: '/about' }];

const AboutPage = () => {
  return (
    <Layout>
      <Seo />
      <Bread breadcrumbsData={breadcrumbsData} />
      <AboutPromo />
      <AboutDetailBlock />
      <Certs />
      <Mission />
      <Corporate />
      <Contact />
      <Certification />
      <ScrollToTop smooth />
    </Layout>
  );
};

export default AboutPage;
