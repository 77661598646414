import { Layout } from '../layout/Layout'
import { Bread } from '../components/Makeup/Bread'
import { Main } from '../components/Makeup/Main'
import { Discover } from '../components/Shopmain/Discover'
import  Private  from '../components/Shopmain/Private'
import { Gallery } from '../components/Shopmain/Gallery'
import { Subscribe } from '../components/shared/Subscribe/Subscribe'
import ScrollToTop from "react-scroll-to-top";
import { Seo } from '../components/shared/Seo'


const ShopPage = () => {
  return (
    <Layout>
      <Seo />
      <Bread />
      <Main />
      <Discover />
      <Private />
      <Subscribe />
      <Gallery />
      <ScrollToTop smooth />
    </Layout>
  );
};

export default ShopPage;
