import React from 'react';

export const AboutPromo = () => {

  return (
    <>

      {/* <iframe width="100%" height="880" src="https://www.youtube.com/embed/XqzjzDHZksE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}

     
      <div className="custom-video-sec" dangerouslySetInnerHTML={{ __html: `
        <video
          controls
          loop
          src="assets/img/video.mp4"
          className="asd"
        />,
      ` }}></div>
    </>
  );
};
