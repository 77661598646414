import { Subscribe } from '../components/shared/Subscribe/Subscribe';
import { CatalogDetail } from '../components/Catalogs/CatalogDetail';
import { PublicLayout } from '../layout/PublicLayout';
import ScrollToTop from "react-scroll-to-top";
import { useNavigate, useParams } from 'react-router-dom';
import AuthUser from '../components/utils/AuthUser';
import { useEffect, useState } from 'react';

const CatalogDetails = () => {
    const { name } = useParams();

    const [catalogData, setCatalogData] = useState(null);
    const { http } = AuthUser();

    const breadcrumbsData = [
        {
            label: 'Home',
            path: '/',
        },
        {
            label: 'Catalogs',
            path: '/catalogs',
        },
        {
            label: catalogData?.name || 'Catalog Detail',
            path: `/catalogs/${name}`,
        },
    ];

    useEffect(() => {
        if (name) {
            http
                .post(`/catalogs/${name}`, { slug: name })
                .then((response) => {
                    if (response?.data) {
                        setCatalogData(response?.data);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching catalog details:", error);
                });
        }
    }, [name]);

    return (
        <PublicLayout breadcrumb={breadcrumbsData} breadcrumbTitle='Catalogs'>


            <div className="catalog-flip">
                {catalogData ? (
                    <iframe src={`${catalogData?.link}/page/1?embed&transparent`} width="100%" height="700" scrolling="no" frameborder="0" allowfullscreen="" allow="clipboard-write" className="publuuflip"></iframe>
                ) : (
                    ""
                )}
            </div>
            <Subscribe />
            <ScrollToTop smooth />
        </PublicLayout>
    );
};

export default CatalogDetails;
