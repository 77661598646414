import { Link } from "react-router-dom";
import { useState } from "react";
import faqData from "../../../data/faq/privatefaq";

export const Privatefaq = () => {
  const faqs = [...faqData];
  const [active, setActive] = useState(-1);

  const handleCollapse = (item) => {
    if (item === active) {
      setActive(-1);
    } else {
      setActive(item);
    }
  };
  return (
    <>
      <div className="custom-faq private-faq" id="guideorder">
        <div className="container">
          <div className="row">
            <div className="faq-head col">
              <span className="saint-text">Private Label</span>
              <h2>GUIDE TO ORDER</h2>
              <p>
                Our sales team is specially trained to guide your private label
                from concept to completion. The team works closely with our
                in-house art and QC/QA departments to create custom label
                design, hot stamping, and silk screening. We offer a wide
                selection of packaging and sourcing in order to completely
                satisfy your product specifications. Our quick a dependable
                customer service is only a toll-free call or chat away.
              </p>
            </div>
          </div>
          <div className="wrapper">
            <div className="faq-items" data-aos="fade-up">
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className={`faq-item ${active === index && "active"}`}
                >
                  <div
                    onClick={() => handleCollapse(index)}
                    className="faq-item__head"
                  >
                    <span className="faq-item__head-num">{index + 1}</span>
                    {faq.title}
                    <span className="faq-item__head-btn"></span>
                  </div>
                  <div
                    style={{ display: active === index && "block" }}
                    className="faq-item__content"
                  >
                    <div className="faq-content-para" dangerouslySetInnerHTML={ { __html: faq.content } }></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
