import React from 'react';

export const Help = () => {
  return (
    <>
      <div className="help" id="contact-help">
        <div className="container">
          <div className="row">
            <h3>FIND US</h3>
            <p>1661 Timothy Dr, San Leandro, CA 94577, USA</p>
            <p>Business Hours <br /> Monday - Friday <br /> 08:00am - 05:00pm</p>
            <p>Saturday - Sunday <br /> Closed</p>
          </div>
        </div>
      </div>

      <div className="offices">
        <div className="wrapper">
          <div className="row">
            <div className="col">
              <h6>CORPORATE HEADQUARTERS/ SHOWROOM/ FACTORY</h6>
              <p>
                1661 Timothy Drive San Leandro, CA 94577 <br /> 
                <a href="tel:(800) 824-3328">(800) 824-3328</a> / <a href="tel:(510) 562-5900">(510) 562-5900</a> <br />
                <a href="mailto:sales@columbiacosmetics.com">sales@columbiacosmetics.com</a>
              </p>
            </div>

            <div className="col">
              <h6>CHICAGO SHOWROOM</h6>
              <p>
                400 N. Wells Suite 328 Chicago, IL 60654 <br /> 
                <a href="tel:(312) 527-4418">(312) 527-4418</a> / <a href="tel:(800) 423-2007">(800) 423-2007</a> / Cell: <a href="tel:(847) 370-4353">(847) 370-4353</a> <br />
                <a href="mailto:ccmichgo@aol.com">ccmichgo@aol.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
