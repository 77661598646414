import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

export const How = () => {
  AOS.init({ duration: 2000 });
  useEffect(() => {}, []);

  return (
    <>
      {/* <!-- BEGIN How --> */}

      <div className="how-works-sec" id="private-hiw">
        <div className="wrapper">
          <div className="how-head">
            <div className="row">
              <div className="col">
                <h4 className="saint-text">Start Your Beauty Journey</h4>
                <h2>How It Works</h2>
                <p>
                  Columbia Cosmetics is here to make the process as simple as
                  possible. <br></br> Since we have already created the
                  foundation of a beautiful brand for you, all you need to do is
                  follow these 5 simple steps.
                </p>
              </div>
            </div>
          </div>

          <div className="how-cont">
            <div className="row">
              <div className="col">
                {/* <h6>Lorem Ipsum</h6> */}
                <h3>1. Open an Account</h3>
                <p>
                  Get started by opening an account with Columbia Cosmetics.
                  Fill out a New Customer Form with your company details and
                  provide appropriate proof of business in the form of a Federal
                  EIN number, Reseller’s Certificate ST-120 form, or Cosmetic
                  Business License. Once we receive your information, we will
                  review and email you the next steps to get you started
                </p>
                <p>
                  To request for a New Customer Form, please{" "}
                  <Link to="/contact">contact us</Link>. You may also register
                  your account online by clicking{" "}
                  <Link to="/register">here</Link>.
                </p>
              </div>
              <div className="col" style={img1}>
                <img src="/assets/img/how1.png" alt="" />
              </div>
            </div>
          </div>

          <div className="how-cont" data-aos="flip-down">
            <div className="row">
              <div className="col">
                <img src="/assets/img/how2.png" style={img2} />
              </div>
              <div className="col">
                {/* <h6>Lorem Ipsum</h6> */}
                <h3>2. Select Your Products</h3>
                <p>
                  With an immense library of products and shades available, you
                  choose the right products for your brand. Our website contains
                  all of the products, colors, and packaging options. If you
                  have any questions, our team can assist you in selecting the
                  right products and shades for your line
                </p>
                <p>
                  Once your account has been approved and your online account
                  activated, you can start shopping for your products. Click
                  <Link to="/shopmain">here</Link> to start shopping.
                </p>
              </div>
            </div>
          </div>

          <div className="how-cont" data-aos="flip-up">
            <div className="row">
              <div className="col">
                {/* <h6>Lorem Ipsum</h6> */}
                <h3>3. Customize</h3>
                <p>
                  Help your brand stand out from the crowd! Individualize your
                  product selection by adding labels or printing with your own
                  unique logo. We can also have individual boxes created for
                  your line to pop on the shelf.
                </p>
                <p>
                  Learn more about our{" "}
                  <Link
                    to="/services/privatelabel/#custom-branding-id"
                    as="/services/privatelabel/#custom-branding-id"
                  >
                    Custom Branding
                  </Link>{" "}
                  Services.
                </p>
              </div>
              <div className="col">
                <img src="/assets/img/how3.png" style={img3} />
              </div>
            </div>
          </div>

          <div className="how-cont" data-aos="flip-down">
            <div className="row">
              <div className="col">
                <img src="/assets/img/how4.png" alt="" />
              </div>
              <div className="col">
                {/* <h6>Lorem Ipsum</h6> */}
                <h3>4. Receive Your Products</h3>
                <p>
                  Most in stock products ship within 72 business hours of order
                  approval.
                </p>
                <p>
                  Visit our{" "}
                  <Link to="/services/privatelabel#guideorder">FAQs</Link> to
                  learn more.
                </p>
              </div>
            </div>
          </div>

          <div className="how-cont" data-aos="flip-up">
            <div className="row">
              <div className="col">
                {/* <h6>Lorem Ipsum</h6> */}
                <h3>5. Enjoy Your Success</h3>
                <p>
                  Market your product line and build your brand. You choose your
                  markup – approximately 500%. (e.g. Your cost $2.50 sell at
                  $14.00+. With all of the right ingredients, packaging, design,
                  and our support, selling your products will be as easy as
                  creating them. Enjoy your success!
                </p>
                <p>
                  <Link to="/register">Let's Get You Started!</Link>
                </p>
              </div>
              <div className="col">
                <img src="/assets/img/how5.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- DISCOUNT How   --> */}
    </>
  );
};

const img1 = {
  backgroundColor: "#fde0e0",
};

const img2 = {
  backgroundColor: "#f7e7e7",
};

const img3 = {
  backgroundColor: "#eddbd6",
};
