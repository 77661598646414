import React from "react";

export const Aboutus = () => {
  return (
    <>
      <div className="powder" id="powd">
        <div className="container">
          <div className="row">
            <div className="col"></div>
            <div className="col">
              <span className="saint-text">Compounding Capabilities</span>
              <h2>POWDER</h2>
              <p>
                We can produce test batches at 1 kg to full-scale batches up to
                100 kg, which allows us to produce a run of any volume to meet
                your market needs.
              </p>
              <p id="head">COMPOUNDING EQUIPMENTS:</p>
              <ul className="points">
                <li>Pharmaceutical quality V-Blenders</li>
                <li>Internally agitated powder mixer</li>
                <li>Quadro Mills</li>
                <li>Three Roll Mill</li>
                <li>And more...</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
