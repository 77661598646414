import { Layout } from '../layout/Layout';
import { Slider } from '../components/Shopmain/Slider';
import Categoryslider from '../components/Shopmain/Categoryslider';
import { Discover } from '../components/Shopmain/Discover';
import { Makeup } from '../components/Shopmain/Makeup';
import Private from '../components/Shopmain/Private';
import { Subscribe } from '../components/shared/Subscribe/Subscribe';
import { Gallery } from '../components/Shopmain/Gallery';
import ScrollToTop from "react-scroll-to-top";
import { Seo } from '../components/shared/Seo';


const breadcrumbsData = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Shop',
    path: '/shop',
  },
];
const ShopPage = () => {
  return (
    <Layout breadcrumb={breadcrumbsData} breadcrumbTitle='Shop'>
      <Seo />
      <Slider />
      <Categoryslider />
      <Discover /> 
      <Makeup />
      <Private />
      <Subscribe />
      <Gallery />
      <ScrollToTop smooth />
    </Layout>
  );
};

export default ShopPage;