import { PromoVideo } from "../../../components/shared/PromoVideo/PromoVideo";
import { useState } from "react";
import { Link } from "react-router-dom";

export const Started = () => {
  return (
    <>
      {/* <!-- BEGIN started BLOCKS --> */}

      <div className="started">
        <div className="container">
          <h2>Let's Get Started</h2>
          <div className="row">
            <h5>Ready to launch your brand?</h5>
            <Link to="/shopmain" className="btn btn-two">
              SHOP NOW
            </Link>
          </div>
          <div className="row">
            <h5>Questions? Find answers here</h5>
            <Link to="#guideorder" className="btn btn-two">
             GUIDE TO ORDER
            </Link>
          </div>
          <div className="row">
            <h5>Still Deciding?</h5>
            <Link to="/contact" className="btn btn-two">
              CONTACT US
            </Link>
          </div>
        </div>
      </div>

      {/* <!-- started BLOCKS EOF   --> */}
    </>
  );
};
