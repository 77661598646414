import React from "react";
import { Link } from "react-router-dom";

export const Capabilities = () => {
  return (
    <>
      <div className="capabilities">
        <div className="container-fluid">
          <div className="capa-content-main">
            <span className="saint-text">
              Cosmetics & Skincare Manufacturing
            </span>
            <h2>COMPOUNDING CAPABILITIES</h2>
            <p id="main-capa-cont">
              Our flexible manufacturing facility gives us the capability to
              develop quality products consistently.{" "}
            </p>

            <p>
              <Link to="/services/whitelabel#liqu" className="btn btn-primary btn-1">LIQUID</Link>
            </p>

            <p>
              <Link to="/services/whitelabel#powd" className="btn btn-primary btn-2">POWDER</Link>
            </p>

            <p>
              <Link to="/services/whitelabel#fill-pack" className="btn btn-primary btn-3">FILLING & PACKAGING</Link>
            </p>      
          </div>
        </div>
      </div>
    </>
  );
};
