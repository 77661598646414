import { useState } from "react";

export const Infotwo = () => {
  const [play, setPlay] = useState(false);
  const url = play
    ? "https://www.youtube.com/embed/K1yp7Q1hH1c?autoplay=1"
    : "";
  
  return (
    <>
      <div className="info-blocks info-blocks-video info-blocks-video2 info-blocks-video3 turnkey over">
        <div className="info-blocks__item info-blocks__item-reverse js-img">
          <div className="wrapper">
            <div className="info-blocks__item-img">
              <img src="/assets/img/priviate1.png" alt="" />
            </div>
            <div className="info-blocks__item-text">
              <span data-aos="fade-right" className="saint-text">
                Overview
              </span>
              <h2>Start your brand</h2>
              <p>
                With over 40 years of experience in formula development, we
                offer our in-house formulas to clients that wish to create a
                product line of their own, through our ready made cosmetics and
                skincare line. Our team of experts will work with you to
                customize these products through our Packaging and Branding
                services to align perfectly with your brand & vision
              </p>
              <p>
                We have already done the research, development and manufacturing
                for you so you can just simply brand and launch your new product
                line.
              </p>
              <div className="points">
                <p>
                  Over 200 in-house formulations made with high-quality
                  ingredients
                </p>
                <p>
                  Over 3000 SKUs of ready-made quality Cosmetics, Skincare and
                  Accessories
                </p>
                <p>Full range of quality components and packaging options</p>
                <p>Custom label design and logo development services</p>
                <p>Hot Stamp, Pad Print, and Silk Screen printing options</p>
                <p>Natural, Vegan, Organic, Clean formulas available</p>
                <p>Sustainable Packaging alternatives available</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
