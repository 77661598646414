import { Layout } from '../layout/Layout';
import { Services } from '../components/Services/Services'
import { Bread } from '../components/Services/Bread'
import { Work } from '../components/Services/Work'
import ScrollToTop from "react-scroll-to-top";
import { Seo } from '../components/shared/Seo';

const ContactPage = () => {
  return (
    <Layout>
      <Seo />
      <Bread />
      <Services />
      <Work />
      <ScrollToTop smooth />
    </Layout>
  );
};

export default ContactPage;
