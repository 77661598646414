import React from 'react';

export const Certs = () => {

    return (
        <>

            <div className="about-certs">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>Certificates & Registrations</h2>
                            <p>We have the necessary certifications and approvals to perform your operator certification.
Our competent certification specialists have long experience in the industry, and we know what it
takes to get your devices through the process optimally while making sure that it meets the
expectations of the industry, operators, and this way the end users.</p>
                            <div className="video2-dets points">
                                <p>FDA Registered Facility</p>
                                <p>FDA/CDPH Drug Manufacturing License</p>
                                <p>FDA/CDPH Cosmetic Manufacturing Registration</p>
                                <p>FDA/COPH Organic Processed Product Registration</p>
                                {/* <p>IBA OAP Cettificate</p> */}
                                <p>COSMOS/ECOCERT Certified</p>
                            </div>
                        </div>
                        <div className="col">
                            <img src="assets/img/certs/cert1.png" alt="" />
                            <img src="assets/img/certs/fdanew.png" alt="" />
                            <img src="assets/img/certs/cert-9.png" alt="" />
                            {/* <img src="assets/img/certs/cert10.png" alt="" /> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
