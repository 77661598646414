import { useState } from "react";

export const Info = () => {
  const [play, setPlay] = useState(false);
  const url = play
    ? "https://www.youtube.com/embed/K1yp7Q1hH1c?autoplay=1"
    : "";
  return (
    <>
      {/* <!-- BEGIN INFO BLOCKS --> */}
      {/* <div className="info-blocks">
        <div className="info-blocks__item js-img">
          <div className="wrapper">
            <div
              className="info-blocks__item-text"
              style={{
                backgroundImage: `url('/assets/img/info-item-bg1.jpg')`,
                backgroundPositionX: "-102px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            >
              <span data-aos="fade-right" className="saint-text">
                Full Service
              </span>
              <h2 data-aos="fade-right">Full Service Product R&D</h2>
              <p data-aos="fade-right">
                We bring your ideas to life from refining your initial idea to
                getting your finished product into your customers’ hands
                including:
              </p>
              <div className="points">
              <p>- Source & Test Raw Materials</p>
              <p>- Find & test appropriate Product Packaging</p>
              <p>- Verify Marketing Claims</p>
              <p>- Perform Stability & Shelf Life Studies</p>
              <p>- Verify your product meets government and industrial regulations</p>
              <p>- Produce Small Batches to show Investors, Distributors, Manufacturers, or for use at Trade Shows</p>
              <p>- Perform Scale up and Manufacturing Support</p>
              </div>
              
            </div>
            <div className="info-blocks__item-img">
              <img src="/assets/img/knowmore.png" className="js-img" alt="" />
            </div>
          </div>
        </div>
      </div> */}

      <div className="custom-info prod-info">
        <div className="container">
          <div className="row">
            <div className="column col-one">
              <img
                data-aos="fade-right"
                src="/assets/img/over.png"
                alt=""
              />
            </div>
            <div className="column">
              <span data-aos="fade-left" className="saint-text">
                Full Service
              </span>
              <h2 data-aos="fade-left">Product R&D</h2>
              <p data-aos="fade-left">
                We bring your ideas to life from refining your initial idea to
                getting your finished product into your customers’ hands
                including:
              </p>

              <ul className="points">
                <li>Source & Test Raw Materials</li>
                <li>Find & test appropriate Product Packaging</li>
                <li>Verify Marketing Claims</li>
                <li>Perform Stability & Shelf Life Studies</li>
                <li>
                  Verify your product meets government and industrial
                  regulations
                </li>
                <li>
                  Produce Small Batches to show Investors, Distributors,
                  Manufacturers, or for use at Trade Shows
                </li>
                <li>Perform Scale up and Manufacturing Support</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- INFO BLOCKS EOF   --> */}
    </>
  );
};
