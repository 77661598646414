import { usePagination } from "../../components/utils/Pagination/Pagination";
import Slider from "rc-slider";
import { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import SideNav from "../../components/shared/Header/Nav/SideNav";

// React Range
// const { createSliderWithTooltip } = Slider;
// const Range = createSliderWithTooltip(Slider.Range);
const options = [
  { value: "minToHigh", label: "Sort by: Alphabetical A-Z" },
  { value: "highToMin", label: "Sort by: Price Low to High" },
  { value: "minToHigh", label: "Sort by: Price High to Low" },
  { value: "minToHigh", label: "Sort by: Relevance" },
  { value: "minToHigh", label: "Sort by: New" },
  { value: "minToHigh", label: "Sort by: Alphabetical A-Z" },
];
export const Main = () => {
  const [category, setCategory] = React.useState([]);
  useEffect(() => {
    axios
      .get("https://api.columbiacosmetics.com/api/categories")
      .then((response) => {
        const data = response.data;
        setCategory(data);
      });
  }, []);

  const allProducts = [];

  const [productOrder, setProductOrder] = useState(
    allProducts.sort((a, b) => (a.price < b.price ? 1 : -1))
  );

  const [products, setProducts] = useState([...productOrder]);
  const [filter, setFilter] = useState({ isNew: false, isSale: true });

  useEffect(() => {
    setProducts(productOrder);
  }, [productOrder]);

  useEffect(() => {
    if (filter.isNew && filter.isSale) {
      const newPro = productOrder.filter(
        (pd) => pd.isNew === true && pd.isSale === true
      );
      setProducts(newPro);
    } else if (filter.isNew && !filter.isSale) {
      const newPro = productOrder.filter((pd) => pd.isNew === true);
      setProducts(newPro);
    } else if (filter.isSale && !filter.isNew) {
      const newPro = productOrder.filter((pd) => pd.isSale === true);
      setProducts(newPro);
    } else {
      setProducts([...productOrder]);
    }
  }, [filter, productOrder]);

  const handleSort = (value) => {
    if (value === "highToMin") {
      const newOrder = allProducts.sort((a, b) => (a.price < b.price ? 1 : -1));
      setProductOrder(newOrder);
    }
    if (value === "minToHigh") {
      const newOrder = allProducts.sort((a, b) => (a.price > b.price ? 1 : -1));
      setProductOrder(newOrder);
    }
  };

  return (
    <div>
      {/* <!-- BEGIN SHOP --> */}
      <div className="shop">
        <div className="wrapper">
          <div className="shop-content">
            {/* <!-- Shop Aside --> */}
            <div className="shop-aside">
              <div className="box-field box-field__search">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search"
                />
                <i className="icon-search"></i>
              </div>
              <SideNav />
              {/* <div className="shop-aside__item">
                <span className="shop-aside__item-title">Price</span>
                <div className="range-slider">
                  <Range
                    min={0}
                    max={20}
                    defaultValue={[0, 20]}
                    tipFormatter={(value) => `${value}$`}
                    allowCross={false}
                    tipProps={{
                      placement: "bottom",
                      prefixCls: "rc-slider-tooltip",
                    }}
                  />
                </div>
              </div> */}
            </div>
            {/* <!-- Shop Main --> */}
            <div className="shop-main">
              <div className="shop-main__filter">
                <div className="shop-main__select">
                  <Dropdown
                    options={options}
                    className="react-dropdown"
                    onChange={(option) => handleSort(option.value)}
                    value={options[0]}
                  />
                </div>
              </div>
              <div className="shop-main__items" id="main-categories">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <Link to={"/body"}>
                          <img
                            src="assets/img/categories/body-cat-2.png"
                            alt=""
                          />
                          <h6>BODY</h6>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/cleansers"}>
                        <a>
                          <img
                            src="assets/img/categories/cleansers-cat-2.png"
                            alt=""
                          />
                          <h6>CLEANSERS</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/face-mist"}>
                          <img
                            src="assets/img/categories/mist-cat-2.png"
                            alt=""
                          />
                          <h6>FACE MIST</h6>
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <Link to={"/lipcare"}>
                        <a>
                          <img
                            src="assets/img/categories/lipcare-cat-2.png"
                            alt=""
                          />
                          <h6>LIP CARE</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/makeup-removers"}>
                        <a>
                          <img
                            src="assets/img/categories/remover-cat-2.png"
                            alt=""
                          />
                          <h6>MAKEUP REMOVERS</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/masques"}>
                        <a>
                          <img
                            src="assets/img/categories/masq-cat-2.png"
                            alt=""
                          />
                          <h6>MASQUES</h6>
                        </a>
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <Link to={"/men-care"}>
                        <a>
                          <img
                            src="assets/img/categories/menskincare-cat-2.jpg"
                            alt=""
                          />
                          <h6>MEN SKINCARE</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/moisturizers"}>
                        <a>
                          <img
                            src="assets/img/categories/moisturizer-cat-2.png"
                            alt=""
                          />
                          <h6>MOISTURIZERS</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/night-creams"}>
                        <a>
                          <img
                            src="assets/img/categories/nightcream-cat-2.png"
                            alt=""
                          />
                          <h6>NIGHT CREAMS</h6>
                        </a>
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <Link to={"/professional-sizes"}>
                        <a>
                          <img
                            src="assets/img/categories/sizes-cat-2.png"
                            alt=""
                          />
                          <h6>PROFESSIONAL SIZES</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/scrub"}>
                        <a>
                          <img
                            src="assets/img/categories/scrub-cat-2.png"
                            alt=""
                          />
                          <h6>SCRUB</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/serums-treatments"}>
                        <a>
                          <img
                            src="assets/img/categories/serum-cat-2.png"
                            alt=""
                          />
                          <h6>SERUMS & TREATMENTS</h6>
                        </a>
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <Link to={"/toners"}>
                        <a>
                          <img
                            src="assets/img/categories/toners-cat-2.png"
                            alt=""
                          />
                          <h6>TONERS</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/undereye-care"}>
                        <a>
                          <img
                            src="assets/img/categories/undereye-cat-2.png"
                            alt=""
                          />
                          <h6>UNDEREYE CARE</h6>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to={"/skincare/30"}>
                        <a>
                          {/* <img
                            src="assets/img/categories/perfectors-cat-1.png"
                            alt=""
                          />
                          <h6>PERFECTORS & CORRECTORS</h6> */}
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- PAGINATE LIST --> */}
              {/* <PagingList paginate={paginate} /> */}
              {/* <div className="pagination-outer">
                <ul className="actives styled-pagination"></ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- SHOP EOF   --> */}
    </div>
  );
};
