import React from 'react';
import { Link } from 'react-router-dom';

export const Breadcrumb = ({ breadcrumb, title, description }) => {
  // You might not need to use location, as it depends on your application structure
  // If you do, you can use it directly from props or pass it as a prop from a parent component
  // const location = useLocation();

  return (
    <>
      {/* <!-- BEGIN DETAIL MAIN BLOCK --> */}
      <div className='detail-block detail-block_margin bread'>
        <div className='wrapper'>
          <div className='detail-block__content'>
            <h1>{title}</h1>

            {breadcrumb && (
              <ul className='bread-crumbs'>
                {breadcrumb?.map(({ path, label }, i) => (
                  <li key={i} className='bread-crumbs__item'>
                    {/* In React Router, use <Link> instead of <a> */}
                    <Link to={path} className='bread-crumbs__link'>
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            )}

            {/* IF NEED DESCRIPTION */}
            {description && <span className='error-descr'>{description}</span>}
          </div>
        </div>
      </div>
      {/* <!-- DETAIL MAIN BLOCK EOF   --> */}
    </>
  );
};
