import { useState, useEffect } from "react";
import axios from "axios";
import { Layout } from "../layout/Layout";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AuthUser from "../components/utils/AuthUser";
import { Seo } from '../components/shared/Seo';
const Login = () => {
    const navigate = useNavigate();
    const { http, setToken } = AuthUser();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [prevousPage, setPrevousPage] = useState("");
    const loader = (
        <div
            style={{
                position: "fixed",
                display: "flex",
                height: "100vh",
                alignItems: "center",
                justifyContent: "center",
                width: "100vw",
                zIndex: 999,
                top: 0,
                backgroundColor: "#0000009c",
            }}
            className="loading overlay"
        >
            <img style={{ width: "100px" }} src="/assets/img/loading.gif" alt="Loading" />
        </div>
    );

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const redirection = params.get("redirection");
        setPrevousPage(redirection);
    }, []);

    function resetPassword() {
        setLoading(true);

        if (email === "") {
            alert('Email field is required in order to reset your password.');
            setLoading(false);
            return false;
        }

        http.post('/forget-password', { email: email })
            .then(response => {
                setLoading(false);
                const data = response.data;

                if (data.success) {
                    navigate('/resetthankyou');
                }
            }).catch(error => {
                setLoading(false);
                if (error.response) {
                    const status = error.response.status;
                    const errorMessage = error.response.data.error;

                    if (status === 500) {
                        alert('Internal Server Error. Please try again later.');
                    } else {
                        alert(errorMessage);
                    }
                } else if (error.request) {
                    alert('No response received. Please check your internet connection.');
                } else {
                    alert('An error occurred while processing your request.');
                }
            });
    }

    function loginFunction() {
        setLoading(true);

        if (email === "") {
            alert('Email field cannot be empty');
            setLoading(false);
            return false;
        }

        if (password === "") {
            alert('Password field cannot be empty');
            setLoading(false);
            return false;
        }

        http.post('/login', { email: email, password: password })
            .then(response => {
                const data = response.data;
                let targetPage = prevousPage == null ? 'dashboard' : prevousPage;

                setLoading(true);
                setToken(response.data.user, response.data.access_token, targetPage);
                navigate(`/${targetPage}`);

                console.log(`/${targetPage}`);
                
            }).catch(error => {

                setLoading(false);
                if (error.response && error.response.status === 401) {
                    if (error.response.data.error === "Unauthorized") {
                        alert('Invalid Credentials');
                    } else {
                        alert(error.response.data.error);
                    }
                } else if (error.response && error.response.status === 500) {
                    alert('Server encountered an error. Please try again later.');
                } else {
                    alert('An error occurred while processing your request.');
                }
            });
    }

    return (
        <Layout>
            <Seo />
            {loading ? loader : ''}
            <div className="login" id="login-form">
                <div className="wrapper">
                    <div className="login-form js-img">
                        <div className="row">
                            <div className="col">
                            </div>
                            <div className="col" id='login-form-col'>
                                <h2 className="mb-3">Sign In </h2>
                                <p className="welcome-line">Welcome Back. Sign In Below.</p>

                                <div className="box-field">
                                    <input type="email" className="form-control" placeholder="Enter your email or customer number"
                                        onChange={e => setEmail(e.target.value)}
                                        id="email" />
                                </div>

                                <div className="box-field">
                                    <input type="password" className="form-control" placeholder="Enter password"
                                        onChange={e => setPassword(e.target.value)}
                                        id="pwd" />
                                </div>
                                <Link to="#" className="reset-btn" onClick={resetPassword}>Reset Password</Link>
                                <button type="button" onClick={loginFunction} className="btn btn-primary mt-4 login-btn">SIGN IN</button>
                                <div className="error"></div>
                                <div className='login-form__bottom'>
                                    <p className="new-customer"><strong>Are you a New Customer ?</strong></p>
                                    <p>
                                        <Link to='/register' className="register-link">Click Here</Link> to register your account.
                                    </p>
                                    <p className="login-note"><strong>By creating an account you will be able to:</strong></p>
                                    <ul className="login-list">
                                        <li>Get full access to our catalog</li>
                                        <li>Shop and place orders</li>
                                        <li>Update and save your info for faster checkout</li>
                                        <li>View your order history</li>
                                        <li>Track your orders and deliveries</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Login;
