import React from "react";
import Carousel from "react-multi-carousel";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-multi-carousel/lib/styles.css";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const Discover = () => {
  return (
    <div className="discover-sec">
      <div className="discover-top">
        <h2>DISCOVER</h2>
      </div>

      <div className="discover-main">
        <div className="all-category" id="all-category-1">
          <div className="container-shop">
            <Tabs>
              <TabList>
                <Tab>NEW ARRIVALS</Tab>
                <Tab>SPECIALS</Tab>
                <Tab>BEST SELLERS</Tab>
                <Tab>LAST CHANCE</Tab>
              </TabList>

              <TabPanel>
                <Carousel
                  responsive={responsive}
                  showDots={true}
                  draggable={false}
                  autoPlay={true}
                  autoPlaySpeed={1200}
                  itemClass="carousel-item-padding-40-px"
                >
                    <div className="makeup-slide">
                      <Link to="/make-up/matte-eyeshadow-palette" as="/make-up/matte-eyeshadow-palette">
                          <img src="assets/img/palletes-shadaow.png" alt="" />
                      </Link>
                      <Link to="/make-up/matte-eyeshadow-palette" as="/make-up/matte-eyeshadow-palette">
                          <h5>MATTE EYESHADOW PALETTES</h5>
                      </Link>
                    </div>

                    <div className="makeup-slide">
                      <Link to="/make-up/mini-eyeshadow-palette" as="/make-up/mini-eyeshadow-palette">
                          <img src="assets/img/mini-pallete.png" alt="" />
                      </Link>
                      <Link to="/make-up/mini-eyeshadow-palette" as="/make-up/mini-eyeshadow-palette">
                          <h5>MINI EYESHADOW PALETTES</h5>
                      </Link>
                    </div>

                    <div className="makeup-slide">
                      <Link to="/make-up/glossy-mascara" as="/make-up/glossy-mascara">
                          <img src="assets/img/glossy-masac.png" alt="" />
                      </Link>
                      <Link to="/make-up/glossy-mascara" as="/make-up/glossy-mascara">
                          <h5>GLOSSY MASACARA</h5>
                      </Link>
                    </div>

                    <div className="makeup-slide">
                      <Link to="/skincare/micellar-water" as="/skincare/micellar-water">
                          <img src="assets/img/micellar-wat.png" alt="" />
                      </Link>
                      <Link to="/skincare/micellar-water" as="/skincare/micellar-water">
                          <h5>MICELLAR WATER</h5>
                      </Link>
                    </div>

                    <div className="makeup-slide">
                      <Link to="/make-up/matte-eyeshadow-palette" as="/make-up/matte-eyeshadow-palette">
                          <img src="assets/img/palletes-shadaow.png" alt="" />
                      </Link>
                      <Link to="/make-up/matte-eyeshadow-palette" as="/make-up/matte-eyeshadow-palette">
                          <h5>MATTE EYESHADOW PALETTES</h5>

                      </Link>
                    </div>
                </Carousel>
              </TabPanel>

              {/* SPECIALS */}
              <TabPanel>
                <Carousel
                  responsive={responsive}
                  showDots={true}
                  draggable={false}
                  autoPlay={true}
                  autoPlaySpeed={1200}
                  itemClass="carousel-item-padding-40-px"
                >
                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/makeup-1.png" alt="" />
                      <h5>BLUSH</h5>
                    </div>
                  </div>

                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/eyeshadow-1.png" alt="" />
                      <h5>EYESHADOWS</h5>
                    </div>
                  </div>

                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/foundations-1.png" alt="" />
                      <h5>FOUNDATIONS</h5>
                    </div>
                  </div>

                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/lipsticks-cat-1.png" alt="" />
                      <h5>LIPSTICKS</h5>
                    </div>
                  </div>

                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/bronzers-1.png" alt="" />
                      <h5>Bronzers</h5>
                    </div>
                  </div>
                </Carousel>
              </TabPanel>

              {/* BEST SELLERS */}
              <TabPanel>
                <Carousel
                  responsive={responsive}
                  showDots={true}
                  draggable={false}
                  autoPlay={true}
                  autoPlaySpeed={1200}
                  itemClass="carousel-item-padding-40-px"
                >
                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/makeup-1.png" alt="" />
                      <h5>BLUSH</h5>
                    </div>
                  </div>

                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/eyeshadow-1.png" alt="" />
                      <h5>EYESHADOWS</h5>
                    </div>
                  </div>

                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/foundations-1.png" alt="" />
                      <h5>FOUNDATIONS</h5>
                    </div>
                  </div>

                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/lipsticks-cat-1.png" alt="" />
                      <h5>LIPSTICKS</h5>
                    </div>
                  </div>

                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/bronzers-1.png" alt="" />
                      <h5>Bronzers</h5>
                    </div>
                  </div>
                </Carousel>
              </TabPanel>

              {/* LAST CHANCE */}
              <TabPanel>
                <Carousel
                  responsive={responsive}
                  showDots={true}
                  draggable={false}
                  autoPlay={true}
                  autoPlaySpeed={1200}
                  itemClass="carousel-item-padding-40-px"
                >
                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/makeup-1.png" alt="" />
                      <h5>BLUSH</h5>
                    </div>
                  </div>

                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/eyeshadow-1.png" alt="" />
                      <h5>EYESHADOWS</h5>
                    </div>
                  </div>

                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/foundations-1.png" alt="" />
                      <h5>FOUNDATIONS</h5>
                    </div>
                  </div>

                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/lipsticks-cat-1.png" alt="" />
                      <h5>LIPSTICKS</h5>
                    </div>
                  </div>

                  <div>
                    <div className="makeup-slide">
                      <img src="assets/img/bronzers-1.png" alt="" />
                      <h5>Bronzers</h5>
                    </div>
                  </div>
                </Carousel>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};
