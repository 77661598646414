import React, { useEffect } from "react";
import $ from "jquery";

export const Overview = () => {
  useEffect(() => {
    $("#col1").on("click", function () {
      $(".facility-overview").css(
        "background-image",
        "url(/assets/img/facility/1.jpg)"
      );
    });

    $("#col2").on("click", function () {
      $(".facility-overview").css(
        "background-image",
        "url(/assets/img/facility/4.jpg)"
      );
    });

    $("#col3").on("click", function () {
      $(".facility-overview").css(
        "background-image",
        "url(/assets/img/facility/5.jpg)"
      );
    });

    $("#col4").on("click", function () {
      $(".facility-overview").css(
        "background-image",
        "url(/assets/img/facility/gallery-img-8.png)"
      );
    });

    $("#col5").on("click", function () {
      $(".facility-overview").css(
        "background-image",
        "url(/assets/img/facility/3.png)"
      );
    });

    $("#col6").on("click", function () {
      $(".facility-overview").css(
        "background-image",
        "url(/assets/img/facility/7.jpg)"
      );
    });
  }, []);

  return (
    <>
      <div className="facility-overview">
        <div className="container">
          <div className="fac-content">
            <p>
              Strategically located in San Francisco Bay Area, our 65,000 square
              ft manufacturing plant, houses our Research & Development
              Laboratory, QA/QC Development Laboratory, Regulatory Department,
              Corporate Offices, Art and Marketing Department, and our main
              Product Showroom.
            </p>
          </div>
        </div>
      </div>
      <div className="facility-gallery">
        <div className="container">
          <div className="row">
            <div className="col" id="col1">
              <img src="assets/img/facility/1.jpg" alt="" />
            </div>
            <div className="col" id="col2">
              <img src="assets/img/facility/4.jpg" alt="" />
            </div>
            <div className="col" id="col3">
              <img src="assets/img/facility/5.jpg" alt="" />
            </div>
            <div className="col" id="col4">
              <img src="assets/img/facility/gallery-img-8.png" alt="" />
            </div>
            <div className="col" id="col5">
              <img src="assets/img/facility/3.png" alt="" />
            </div>
            <div className="col" id="col6">
              <img src="assets/img/facility/7.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
