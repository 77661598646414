import React from "react";
import { Link } from "react-router-dom";

export const Bread = ({ breadcrumb, title, description }) => {
  // You might need to replace the logic for determining the current path
  const currentPath = window.location.pathname;

  return (
    <>
      {/* <!-- BEGIN DETAIL MAIN BLOCK --> */}
      <div
        className="detail-block detail-block_margin categories-bread"
        style={bg}
      >
        <div className="wrapper">
          <div className="detail-block__content">
            <h1>SKINCARE</h1>

            {breadcrumb && (
              <ul className="bread-crumbs">
                {breadcrumb?.map(({ path, label }, i) => {
                  return (
                    <React.Fragment key={i}>
                      {path === currentPath ? (
                        <li className="bread-crumbs__item">{label}</li>
                      ) : (
                        <li className="bread-crumbs__item">
                          <a href={path} className="bread-crumbs__link">
                            {label}
                          </a>
                        </li>
                      )}
                    </React.Fragment>
                  );
                })}
              </ul>
            )}

            {/* IF NEED DESCRIPTION */}
            {description && <span className="error-descr">{description}</span>}
          </div>
        </div>
      </div>
      {/* <!-- DETAIL MAIN BLOCK EOF   --> */}
    </>
  );
};

const bg = {
  backgroundImage: "url(/assets/img/skincare-banner.png)",
  padding: "180px 0 180px 0",
  textAlign: "left",
};
